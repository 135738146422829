import React from "react";
import { connect } from "react-redux";

import SelectedGraph from "../SelectedGraph";
import { getSelectedClientCountByMonth } from "actions/dashboardActions";

const ClaimsMonthlyView = ({
  selectedClient,
  getSelectedClientCountByMonth,
  currentClientClaimsByMonth,
  loadingDashboardClaimsByMonth,
}) => {
  React.useEffect(() => {
    if (!selectedClient || !selectedClient.code) return;
    getSelectedClientCountByMonth(selectedClient.code, "claims");
  }, [getSelectedClientCountByMonth, selectedClient]);

  const initialMonthData = {
    currentMonthCount: 0,
    previousMonthCount: 0,
    countsByMonth: {
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
    },
  };

  const [claimsCountByMonth, setClaimsCountByMonth] =
    React.useState(initialMonthData);

  React.useEffect(() => {
    if (!currentClientClaimsByMonth) return;

    const { currentMonthCount, previousMonthCount, countsByMonth } =
      currentClientClaimsByMonth;

    const monthNameValuePairs = Object.entries(countsByMonth).map(
      ([name, count]) => ({
        name,
        count,
      })
    );

    const currentData = {
      currentMonthCount: currentMonthCount ? currentMonthCount : 0,
      previousMonthCount: previousMonthCount ? previousMonthCount : 0,
      countsByMonth:
        monthNameValuePairs && monthNameValuePairs.length
          ? monthNameValuePairs
          : claimsCountByMonth.countsByMonth,
    };

    setClaimsCountByMonth(currentData);
  }, [currentClientClaimsByMonth]);

  return (
    <SelectedGraph
      loading={loadingDashboardClaimsByMonth}
      data={claimsCountByMonth.countsByMonth}
      filterLabel="Month"
      currentCount={claimsCountByMonth.currentMonthCount}
      lastCount={claimsCountByMonth.previousMonthCount}
      legend="# Claims"
    />
  );
};

const mapStateToProps = (state) => ({
  currentClientClaimsByMonth: state.dashboard.currentClientClaimsByMonth,
  loadingDashboardClaimsByMonth: state.dashboard.loadingDashboardClaimsByMonth,
});

export default connect(mapStateToProps, {
  getSelectedClientCountByMonth,
})(ClaimsMonthlyView);
