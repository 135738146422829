import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { BiPlusMedical, BiTrash } from "react-icons/bi";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";
import Filters from "./Filters";
import InsuranceModal from "./InsuranceModal";

import {
  getList,
  resetComponentStore,
  deleteRecord,
  changeInsuranceStatusByID,
} from "actions/insuranceActions";
import { getPartnerById } from "actions/partnerActions";

import { isAdmin } from "utils/helper";

const InsuranceList = ({
  getList,
  List: { data, count },
  loadingList,
  resetComponentStore,
  sortingParams,
  loggedInUser,
  deleteRecord,
  getPartnerById,
  changeInsuranceStatusByID,
}) => {
  const { partner_id } = useParams();
  const [onlyOnce, setOnce] = React.useState(true);
  const [onlyOnce2, setOnce2] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);
  const [selectedID, setSelectedID] = React.useState(null);
  const [partner, setPartner] = React.useState(null);
  const [buttonLoading, setButtonLoading] = React.useState({});

  const { page, limit } = sortingParams;

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };
  const [params, setParams] = React.useState(initialSortingParams);

  const onClickHandler = (row_id) => {
    setSelectedID(row_id);
    setModal(true);
  };

  const onChangeStatus = (insurance_id, status, index) => {
    buttonLoading[index] = true;
    setButtonLoading({ ...buttonLoading });

    changeInsuranceStatusByID(partner_id, insurance_id, status).then(() => {
      buttonLoading[index] = false;
      setButtonLoading({ ...buttonLoading });
    });
  };

  const actions = (
    <div className="page-actions">
      <Button
        color="primary"
        size="sm"
        onClick={(e) => {
          setModal(true);
        }}
      >
        <BiPlusMedical /> Add New Insurance
      </Button>
    </div>
  );

  const columns = [
    {
      name: "Insurance ID",
      selector: (row) => `${row.InternalInsCoID}`,
      sortable: false,
      width: "10%",
    },
    {
      name: "Insurance Name",
      selector: (row) => `${row.InternalInsCoName}`,
      sortable: true,
      sortField: "InternalInsCoName",
      width: "15%",
    },
    {
      name: "Partner Ins ID",
      selector: (row) => `${row.PartnerInsCoID}`,
      sortable: false,
      width: "10%",
    },
    {
      name: "Partner Ins Name",
      selector: (row) => `${row.PartnerInsCoName}`,
      sortable: false,
      width: "15%",
    },
    {
      name: "Partner Plan ID",
      selector: (row) => `${row.PartnerPlanID}`,
      sortable: false,
      width: "10%",
    },
    {
      name: "Partner Plan Name",
      selector: (row) => `${row.PartnerPlanName}`,
      sortable: false,
      width: "15%",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row, i) => (
        <div>
          <Form>
            <Form.Check
              className="fancy-checkbox-switch"
              type="switch"
              id="custom-switch"
              onChange={(e) =>
                onChangeStatus(
                  row._id,
                  row.status === 1 ? 2 : 1,
                  `button-${row.InternalInsCoID}-${i}`
                )
              }
              checked={row.status === 1 ? true : false}
              disabled={
                buttonLoading[`button-${row.InternalInsCoID}-${i}`] === true
                  ? true
                  : false
              }
            />
          </Form>
        </div>
      ),
      sortable: true,
      sortField: "status",
      width: "10%",
    },
    {
      name: "Actions",
      width: "calc(15% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Button
            variant="primary"
            size="sm"
            onClick={(e) => onClickHandler(row._id)}
          >
            <FaRegEye />
          </Button>

          {loggedInUser && isAdmin(loggedInUser) ? (
            <Button
              className="ml-1"
              size="sm"
              title="Delete"
              type="button"
              variant="danger"
              onClick={(e) => {
                if (
                  window.confirm(
                    `Are you sure you want to delete: ${row.InternalInsCoName}?`
                  )
                ) {
                  deleteRecord(row.partner, row._id);
                }
              }}
            >
              <BiTrash />
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  React.useEffect(() => {
    if (onlyOnce2) {
      setOnce2(false);
    }

    getPartnerById(partner_id).then((response) => {
      // partner
      setPartner(response);
    });
  }, [getPartnerById, partner_id]);

  React.useEffect(() => {
    if (!partner_id) return;

    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getList(params, partner_id);
  }, [getList, params, resetComponentStore, partner_id]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              InternalInsCoID: {
                value: searchText,
                type: "String",
              },
              InternalInsCoName: {
                value: searchText,
                type: "String",
              },
              PartnerInsCoID: {
                value: searchText,
                type: "String",
              },
              PartnerInsCoName: {
                value: searchText,
                type: "String",
              },
              PartnerPlanID: {
                value: searchText,
                type: "String",
              },
              PartnerPlanName: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setParams(params);
  };

  const onFilterChange = (newParams) => {
    setParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      {partner ? (
        <InsuranceModal
          modal={modal}
          setModal={setModal}
          toggle={toggle}
          partner={partner}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
        />
      ) : null}

      <AppBreadcrumb
        pageTitle={`${partner ? partner.name : ""} Insurances`}
        crumbs={[
          {
            name: `Partners`,
            path: `/admin/partners`,
          },
          { name: `${partner ? partner.name : ""} Insurances` },
        ]}
      />
      <Card>
        <Card.Body>
          <div className="table-filter-section">
            <Row>
              {actions}
              <Col md="4">
                <Filters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterName="Search"
                  filterParams={params}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={params}
            setParams={setParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

InsuranceList.propTypes = {
  getList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  List: state.insurance.List,
  loadingList: state.insurance.loadingList,
  sortingParams: state.insurance.sortingParams,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getList,
  resetComponentStore,
  deleteRecord,
  getPartnerById,
  changeInsuranceStatusByID,
})(InsuranceList);
