import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import qs from "qs";

// Main Window.
let browser = null;
// child window.
let popup = null;
// interval
let timer = null;

const WindowOpener = ({
  url,
  title,
  name,
  opts,
  bridge,
  getURLToOpen,
  disabled,
  onClickCallback,
  children,
  reloadOnClose,
  onWindowPopupClose,
}) => {
  // This function is what the name says.
  // it checks whether the popup still open or not
  function watcher() {
    // if popup is null then let's clean the intervals.
    if (popup === null) {
      clearInterval(timer);
      timer = null;
      // if popup is not null and it is not closed, then let's set the focus on it... maybe...
    } else if (popup !== null && !popup.closed) {
      popup.focus();
      // if popup is closed, then let's clean errthing.
    } else if (popup !== null && popup.closed) {
      clearInterval(timer);
      browser.focus();
      // the onCloseEventHandler it notifies that the child has been closed.
      browser.onClose("child was closed");
      timer = null;
      popup = null;
      reloadOnClose && window.location.reload();
    }
  }

  // The properties of the component are the following
  // `url`: URI in which the new window will open in.
  // `name`: name of the popup.
  // `bridge`: this will be the function that we will use to communicate parent and son
  // `opts`: options that the window has. if you want to know all the options goto
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/open

  React.useEffect(() => {
    // browser is set to current window
    browser = window.self;

    // each time we send a message will use the `onSuccess`
    browser.onSuccess = (res) => {
      console.log("browser.onSuccess", res);

      if (typeof onWindowPopupClose === "function") {
        onWindowPopupClose(res);
      }
      // bridge(null, res);
    };

    // // each time we failed we will use the `onError`
    browser.onError = (error) => {
      console.log("browser.onerror", error);
      // bridge(error);
    };

    // // Tells when a child window is open
    browser.onOpen = (message) => {
      console.log("browser.open", message);
      // bridge(null, message);
    };

    // // Tells when a child window is close
    browser.onClose = (message) => {
      console.log("window closed", message);
      // bridge(null, message);
    };
  }, []);

  const onClickHandler = async () => {
    if (!url) {
      getURLToOpen().then((urlToOpen) => {
        console.log({ urlToOpen });
        if (urlToOpen) {
          launchPopup(
            urlToOpen
            // `${process.env.REACT_APP_MEMBER_PANEL_URL}/scheduler-app-launcher?x-access-token=${urlToOpen}`
          );
        }
      });
    } else {
      let newURL = url;
      if (bridge) {
        newURL += `?${qs.stringify(bridge)}`;
      }
      launchPopup(newURL);
    }

    if (typeof onClickCallback === "function") {
      onClickCallback();
    }
    return;
  };

  const launchPopup = (url) => {
    if (!url) return;

    console.log("On click handler", { url });
    // if there is  already a child open, let's set focus on it
    if (popup && !popup.closed) {
      popup.focus();
      return;
    }

    console.log("before open", url);
    // we open a new window.
    console.log({ url, name, opts });
    popup = browser.open(url, name, opts);

    setTimeout(() => {
      // The opener object is created once and only if a window has a parent
      popup.opener.onOpen("child was opened");
    }, 100);

    if (timer === null) {
      // each two seconds we check if the popup still open or not
      timer = setInterval(watcher, 2000);
    }

    return;
  };

  return (
    <span title={title} onClick={onClickHandler} disabled={disabled}>
      {children}
    </span>
  );
};

WindowOpener.propTypes = {
  url: PropTypes.string.isRequired,
  bridge: PropTypes.func.isRequired,
  name: PropTypes.string,
  opts: PropTypes.string,
};

WindowOpener.defaultProps = {
  name: "Schedule App",
  opts: `dependent=${1}, alwaysOnTop=${1}, alwaysRaised=${1}, alwaysRaised=${1}, width=${30000}, height=${30000}`,
};

export default WindowOpener;
