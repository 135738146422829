import React from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { capitalizeFirst, dividePascalCaseString } from "utils/helper";
import { validateForm } from "utils/validation";

import {
  getClientPatientDetailsById,
  updatePatientData,
  updateResponsiblePartyData,
  updateInsuranceData,
  updatePatientOtherData,
  resetComponentStore,
  removePatientErrors,
  create,
  loadPatientToTebra,
  getClientPatientsList,
} from "actions/patientActions";
import { setErrors } from "actions/clientWorkspaceActions";

import Spinner from "views/Spinner";
import PatientData from "./PatientDataTab";
import ResponsiblePartyData from "./ResponsiblePartyDataTab";
import InsuranceData from "./InsuranceDataTab";
import OtherDataTab from "../Claims/OtherDataTab";
import { otherDataValues } from "constants/index";

const getOtherDataField = (OtherData) => {
  return OtherData.map((each) => {
    const devidedTitleWords = capitalizeFirst(dividePascalCaseString(each[0]));
    const otherDataObj = otherDataValues.find(
      (eachData) => eachData.label === each[1]
    );

    return {
      title: devidedTitleWords,
      id: each[0],
      type: each[1],
      value: each[2],
      inputType: otherDataObj ? otherDataObj.value : "text",
    };
  });
};

const ClientPatientModal = ({
  modal,
  setModal,
  toggle,
  selectedPatientID,
  setSelectedPatientID,
  getClientPatientDetailsById,
  currentClientPatient,
  loadingPatient,
  clientCode,
  updatePatientData,
  updateResponsiblePartyData,
  updateInsuranceData,
  updatePatientOtherData,
  loadingOnSubmit,
  loggedInUser,
  setErrors,
  removePatientErrors,
  create,
  loadPatientToTebra,
  getClientPatientsList,
  insurancesListAll,
}) => {
  const reset = () => {
    setModal(false);
    setSelectedPatientID(null);
    setDisabled(false);
    setActiveTab("patientData");
    setIsRequiredResponsiblePartyData(true);
  };

  const initialPatientData = {
    patientId: "",
    patientFirstName: "",
    patientLastName: "",
    patientDOB: "",
    patientGender: "",
    patientRace: "",
    patientEthnicity: "",
    address1: "",
    address2: "",
    state: "",
    city: "",
    zip: "",
    country: "USA",
    patientPhoneNumber: "",
    patientEmail: "",
    trace: "",
  };

  const initialResponsiblePartyData = {
    responsibleFirstName: "",
    responsibleLastName: "",
    responsibleDOB: "",
    responsibleEmail: "",
    responsiblePhoneNo: "",
    responsibleRelationship: "",
  };

  const navigate = useNavigate();
  const [isDisabled, setDisabled] = React.useState(false);
  const toggleEdit = (e) => {
    e.preventDefault();
    setDisabled(!isDisabled);
  };

  const [patientData, setPatientData] = React.useState(initialPatientData);
  const [responsiblePartyData, setResponsiblePartyData] = React.useState(
    initialResponsiblePartyData
  );
  const [insuranceData, setInsuranceData] = React.useState([]);
  const [otherData, setOtherData] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState("patientData");
  const [isRequiredResponsiblePartyData, setIsRequiredResponsiblePartyData] =
    React.useState(true);

  React.useEffect(() => {
    setPatientData(initialPatientData);
    setResponsiblePartyData(initialResponsiblePartyData);
    setInsuranceData([]);
    setOtherData([]);
    removePatientErrors();

    if (!clientCode || !selectedPatientID) return;
    setDisabled(true);
    getClientPatientDetailsById(selectedPatientID, clientCode);
  }, [clientCode, selectedPatientID, modal]);

  const checkIsSelfSubscriberRelationship = (
    patientDOB = "",
    insuranceData = ""
  ) => {
    let isRequired = true;

    if (patientDOB) {
      const patientAge = moment().diff(patientDOB, "years");
      if (patientAge <= 17) {
        return (isRequired = true);
      }
    }

    if (insuranceData && insuranceData.length) {
      insuranceData.forEach((obj) => {
        const relation = obj.subscriberRelationship.toLowerCase();

        if (relation === "self") {
          return (isRequired = false);
        }
      });
    }

    return isRequired;
  };

  React.useEffect(() => {
    if (!currentClientPatient) return;

    const {
      patientId,
      patientFirstName,
      patientLastName,
      patientDOB,
      patientGender,
      patientRace,
      patientEthnicity,
      address1,
      address2,
      state,
      city,
      zip,
      country,
      patientPhoneNumber,
      patientEmail,
      responsibleFirstName,
      responsibleLastName,
      responsibleDOB,
      responsibleEmail,
      responsiblePhoneNo,
      responsibleRelationship,
      trace,
    } = currentClientPatient;

    // Prepare Patient Data
    const patientData = {
      patientId,
      patientFirstName,
      patientLastName,
      patientDOB: patientDOB ? moment(patientDOB).format("YYYY-MM-DD") : "",
      patientGender,
      patientRace,
      patientEthnicity,
      address1,
      address2,
      state,
      city,
      zip,
      country,
      patientPhoneNumber,
      patientEmail: patientEmail ? patientEmail : undefined,
      trace,
    };
    setPatientData((form) => ({ ...form, ...patientData }));

    // Prepare Responsible Party Data
    const responsiblePartyData = {
      responsibleFirstName: responsibleFirstName ? responsibleFirstName : "",
      responsibleLastName: responsibleLastName ? responsibleLastName : "",
      responsibleDOB: responsibleDOB
        ? moment(responsibleDOB).format("YYYY-MM-DD")
        : "",
      responsibleEmail: responsibleEmail ? responsibleEmail : undefined,
      responsiblePhoneNo: responsiblePhoneNo ? responsiblePhoneNo : "",
      responsibleRelationship: responsibleRelationship
        ? responsibleRelationship
        : "",
    };
    setResponsiblePartyData((form) => ({ ...form, ...responsiblePartyData }));

    // Prepare Insurance Data
    const insuranceData = currentClientPatient.insurance;
    const insuranceDataCopy = insuranceData.length
      ? insuranceData.map((data, i) => {
          let data2 = {
            ...data,
            insuranceCompanyId: data.insuranceCompanyId
              ? Number(data.insuranceCompanyId)
              : null,
          };
          if (data2.subscriberDob) {
            data2 = {
              ...data2,
              subscriberDob: moment(data2.responsibleDOB).format("YYYY-MM-DD"),
            };
          }
          if (!data.subscriberEmail) {
            data2 = {
              ...data2,
              subscriberEmail: undefined,
            };
          }

          return data2;
        })
      : [];
    setInsuranceData(insuranceDataCopy);

    const isSelf = checkIsSelfSubscriberRelationship(patientDOB, insuranceData);
    setIsRequiredResponsiblePartyData(isSelf);

    // Prepare Other Data
    const patientOtherData = currentClientPatient.otherData
      ? currentClientPatient.otherData
      : [];
    const otherDataFormalized = patientOtherData.length
      ? getOtherDataField(patientOtherData)
      : [];
    setOtherData(otherDataFormalized);
  }, [currentClientPatient]);

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  const savePatientData = (e) => {
    e.preventDefault();
    updatePatientData(
      patientData,
      clientCode,
      selectedPatientID,
      navigate
    ).then((res) => {
      if (res.status) setDisabled(true);
    });
  };

  const saveResponsiblePartyData = (e) => {
    e.preventDefault();

    if (isRequiredResponsiblePartyData) {
      let validationRules = [];

      if (!responsiblePartyData.responsibleFirstName) {
        validationRules.push({
          param: "responsibleFirstName",
          msg: "Responsible first name is required.",
        });
      }

      if (!responsiblePartyData.responsibleLastName) {
        validationRules.push({
          param: "responsibleLastName",
          msg: "Responsible last name is required.",
        });
      }

      if (
        !responsiblePartyData.responsibleRelationship ||
        !["SELF", "SPOUSE", "CHILD", "OTHER"].includes(
          responsiblePartyData.responsibleRelationship.toUpperCase()
        )
      ) {
        validationRules.push({
          param: "responsibleRelationship",
          msg: "Invalid responsible relationship value. Please choose from SELF, SPOUSE, CHILD, OTHER.",
        });
      }

      if (!responsiblePartyData.responsibleDOB) {
        validationRules.push({
          param: "responsibleDOB",
          msg: "Responsible DOB is required.",
        });
      }

      if (!responsiblePartyData.responsiblePhoneNo) {
        validationRules.push({
          param: "responsiblePhoneNo",
          msg: "Please provide responsible phone number.",
        });
      }
      const errors = validateForm(responsiblePartyData, validationRules);
      if (errors.length) {
        setErrors(errors);
        return;
      }
    }

    updateResponsiblePartyData(
      responsiblePartyData,
      clientCode,
      selectedPatientID,
      navigate
    ).then((res) => {
      if (res.status) setDisabled(true);
    });
  };

  const saveInsuranceData = (e) => {
    e.preventDefault();
    updateInsuranceData(
      insuranceData,
      clientCode,
      selectedPatientID,
      navigate
    ).then((res) => {
      if (res.status) setDisabled(true);
    });
  };

  const saveOtherData = (e) => {
    e.preventDefault();

    const submitData = [];

    for (let each of otherData) {
      let data = [each.id, each.type.toUpperCase(), each.value];
      submitData.push(data);
    }

    updatePatientOtherData(
      submitData,
      clientCode,
      selectedPatientID,
      navigate
    ).then((res) => {
      if (res.status) setDisabled(true);
    });
  };

  const createPatient = (e) => {
    e.preventDefault();
    removePatientErrors();

    const cpOtherData = [];

    for (let each of otherData) {
      let data = [each.id, each.type.toUpperCase(), each.value];
      cpOtherData.push(data);
    }

    const formData = {
      ...patientData,
      ...responsiblePartyData,
      insurance: insuranceData,
      otherData: cpOtherData,
    };

    const submitData = {};
    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    create(submitData, clientCode).then((res) => {
      if (res.status) {
        setModal(false);
        getClientPatientsList({}, clientCode);
      }
    });
  };

  const onSubmitOtherData = (e) => {
    e.preventDefault();
    if (selectedPatientID) {
      saveOtherData(e);
    } else {
      createPatient(e);
    }
  };

  const onClickCancel = (e) => {
    e.preventDefault();

    setDisabled(true);
    resetComponentStore();
    setIsRequiredResponsiblePartyData(true);
  };

  return (
    <Modal
      show={modal}
      onHide={reset}
      size="xl"
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
    >
      <Modal.Header toggle={toggle} closeButton></Modal.Header>
      <Modal.Body>
        {loadingPatient && selectedPatientID ? (
          <Spinner />
        ) : (
          <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
            <Tab eventKey="patientData" title="Patient">
              <PatientData
                currentClientPatient={currentClientPatient}
                patientData={patientData}
                setPatientData={setPatientData}
                loadingOnSubmit={loadingOnSubmit}
                isDisabled={isDisabled}
                savePatientData={savePatientData}
                toggleEdit={toggleEdit}
                onClickCancel={onClickCancel}
                loggedInUser={loggedInUser}
                selectedPatientID={selectedPatientID}
                handleTabSelect={handleTabSelect}
                setErrors={setErrors}
                clientCode={clientCode}
                loadPatientToTebra={loadPatientToTebra}
                setIsRequiredResponsiblePartyData={
                  setIsRequiredResponsiblePartyData
                }
                removePatientErrors={removePatientErrors}
              />
            </Tab>

            <Tab eventKey="insuranceData" title="Insurance Data">
              <InsuranceData
                insuranceData={insuranceData}
                setInsuranceData={setInsuranceData}
                saveInsuranceData={saveInsuranceData}
                loadingOnSubmit={loadingOnSubmit}
                isDisabled={isDisabled}
                toggleEdit={toggleEdit}
                onClickCancel={onClickCancel}
                loggedInUser={loggedInUser}
                selectedPatientID={selectedPatientID}
                handleTabSelect={handleTabSelect}
                setErrors={setErrors}
                patientDOB={patientData.patientDOB}
                setIsRequiredResponsiblePartyData={
                  setIsRequiredResponsiblePartyData
                }
                checkIsSelfSubscriberRelationship={
                  checkIsSelfSubscriberRelationship
                }
                insurancesListAll={insurancesListAll}
                removePatientErrors={removePatientErrors}
                clientCode={clientCode}
              />
            </Tab>

            <Tab eventKey="responsibleParty" title="Responsible Party">
              <ResponsiblePartyData
                responsiblePartyData={responsiblePartyData}
                saveResponsiblePartyData={saveResponsiblePartyData}
                loadingOnSubmit={loadingOnSubmit}
                isDisabled={isDisabled}
                setResponsiblePartyData={setResponsiblePartyData}
                toggleEdit={toggleEdit}
                onClickCancel={onClickCancel}
                loggedInUser={loggedInUser}
                selectedPatientID={selectedPatientID}
                handleTabSelect={handleTabSelect}
                setErrors={setErrors}
                isRequiredResponsiblePartyData={isRequiredResponsiblePartyData}
                removePatientErrors={removePatientErrors}
              />
            </Tab>

            <Tab eventKey="otherData" title="Other Data">
              <OtherDataTab
                otherData={otherData}
                setOtherData={setOtherData}
                onSubmitOtherData={onSubmitOtherData}
                loadingOnSubmit={loadingOnSubmit}
                isDisabled={isDisabled}
                toggleEdit={toggleEdit}
                onClickCancel={onClickCancel}
                loggedInUser={loggedInUser}
                clientCode={clientCode}
              />
            </Tab>
          </Tabs>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currentClientPatient: state.patient.currentPatient,
  loadingPatient: state.patient.loadingPatient,
  loadingOnSubmit: state.patient.loadingOnSubmit,
  loggedInUser: state.auth.user,
  insurancesListAll: state.insurance.ListAll,
});

export default connect(mapStateToProps, {
  getClientPatientDetailsById,
  updatePatientData,
  updateResponsiblePartyData,
  updateInsuranceData,
  updatePatientOtherData,
  setErrors,
  removePatientErrors,
  create,
  loadPatientToTebra,
  getClientPatientsList,
})(ClientPatientModal);
