import React from "react";
import moment from "moment";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { FaRegEye } from "react-icons/fa";
import { BiPlusMedical, BiTrash } from "react-icons/bi";

import PiDataTable from "views/DataTable/PiDataTable";
import Filters from "./Filters";
import CPTCodeModal from "./CPTCodeModal";

import {
  getCPTCodesList,
  resetComponentStore,
  deleteCPTCode,
} from "actions/cpt_codeActions";

import { isAdmin } from "utils/helper";
import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";

const CPTCodesList = ({
  getCPTCodesList,
  CPTCodesList: { data, count },
  loadingList,
  resetComponentStore,
  sortingParams,
  loggedInUser,
  deleteCPTCode,
}) => {
  const [onlyOnce, setOnce] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [selectedCPTCodeID, setSelectedCPTCodeID] = React.useState(null);
  const [searchDefaultValue, setSearchDefaultValue] = React.useState("");
  const [onlyOnce2, setOnce2] = React.useState(true);
  const toggle = () => setModal(!modal);

  const { page, limit } = sortingParams;

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [CPTCodeParams, setCPTCodeParams] =
    React.useState(initialSortingParams);

  const onClickHandler = (row_id) => {
    setSelectedCPTCodeID(row_id);
    setModal(true);
  };

  const actions = (
    <div className="page-actions">
      <Button
        color="primary"
        size="sm"
        onClick={(e) => {
          setModal(true);
        }}
      >
        <BiPlusMedical /> Create New CPT Code
      </Button>
    </div>
  );

  const columns = [
    {
      name: "CPT Code",
      selector: (row) => `${row.cpt_code}`,
      sortable: true,
      sortField: "cpt_code",
      width: "35%",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      width: "50%",
    },
    {
      name: "Actions",
      width: "calc(15% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Button
            variant="primary"
            size="sm"
            onClick={(e) => onClickHandler(row._id)}
          >
            <FaRegEye />
          </Button>

          {loggedInUser && isAdmin(loggedInUser) ? (
            <Button
              className="ml-1"
              size="sm"
              title="Delete"
              type="button"
              variant="danger"
              onClick={(e) => {
                if (
                  window.confirm(
                    `Are you sure you want to delete: ${row.cpt_code}?`
                  )
                ) {
                  deleteCPTCode(row._id);
                }
              }}
            >
              <BiTrash />
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getCPTCodesList(CPTCodeParams);
  }, [getCPTCodesList, CPTCodeParams, resetComponentStore]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              cpt_code: {
                value: searchText,
                type: "String",
              },
              description: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setCPTCodeParams(params);
  };

  const onFilterChange = (newParams) => {
    setCPTCodeParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <CPTCodeModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        selectedCPTCodeID={selectedCPTCodeID}
        setSelectedCPTCodeID={setSelectedCPTCodeID}
      />

      <AppBreadcrumb pageTitle="CPT Codes" crumbs={[{ name: "CPT Codes" }]} />
      <Card>
        <Card.Body>
          <div className="table-filter-section">
            <Row>
              {actions}
              <Col md="4">
                <Filters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterName="Search"
                  filterParams={CPTCodeParams}
                  onFilterChange={onFilterChange}
                  searchDefaultValue={searchDefaultValue}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={CPTCodeParams}
            setParams={setCPTCodeParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

CPTCodesList.propTypes = {
  getCPTCodesList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  CPTCodesList: state.cpt_code.CPTCodesList,
  loadingList: state.cpt_code.loadingList,
  sortingParams: state.cpt_code.sortingParams,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getCPTCodesList,
  resetComponentStore,
  deleteCPTCode,
})(CPTCodesList);
