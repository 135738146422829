import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { GoPrimitiveDot } from "react-icons/go";

import { getSelectedClientCountForWeek } from "actions/dashboardActions";

const PatientsWeeklyView = ({
  selectedClient,
  getSelectedClientCountForWeek,
  currentClientPatientsForWeek,
  loadingDashboardPatientsForWeek,
}) => {
  React.useEffect(() => {
    if (!selectedClient || !selectedClient.code) return;
    getSelectedClientCountForWeek(selectedClient.code, "patients");
  }, [getSelectedClientCountForWeek, selectedClient]);

  const initialData = {
    currentWeekCount: 0,
    previousWeekCount: 0,
    weekDayCountData: [
      { name: "Sun", cw: 0, pw: 0 },
      { name: "Mon", cw: 0, pw: 0 },
      { name: "Tue", cw: 0, pw: 0 },
      { name: "Wed", cw: 0, pw: 0 },
      { name: "Thu", cw: 0, pw: 0 },
      { name: "Fri", cw: 0, pw: 0 },
      { name: "Sat", cw: 0, pw: 0 },
    ],
  };
  const [patientsForWeekCount, setPatientsForWeekCount] =
    React.useState(initialData);

  React.useEffect(() => {
    if (!currentClientPatientsForWeek) return;

    const { currentWeekCount, previousWeekCount, weekDayCountData } =
      currentClientPatientsForWeek;

    const weeklyNameValuePairs = Object.entries(weekDayCountData).map(
      ([name, value]) => ({
        name,
        cw: value.cw,
        pw: value.pw,
      })
    );

    const currentData = {
      currentWeekCount: currentWeekCount ? currentWeekCount : 0,
      previousWeekCount: previousWeekCount ? previousWeekCount : 0,
      weekDayCountData:
        weeklyNameValuePairs && weeklyNameValuePairs.length
          ? weeklyNameValuePairs
          : patientsForWeekCount.weekDayCountData,
    };

    setPatientsForWeekCount(currentData);
  }, [currentClientPatientsForWeek]);

  return (
    <div className="crm-card">
      <h5 className="d-inline-block">Patients this vs last week</h5>

      <div className="chart-content-bg">
        <Row className="d-flex mb-3 text-center">
          <Col md="6">
            <p className="text-muted mb-0 mt-3">This Week</p>

            {loadingDashboardPatientsForWeek ? (
              <span>Loading...</span>
            ) : (
              <h2 className="fw-normal mb-3">
                <GoPrimitiveDot color={"#3688fc"} size={"25px"} />
                <span>{patientsForWeekCount.currentWeekCount}</span>
              </h2>
            )}
          </Col>
          <Col md="6">
            <p className="text-muted mb-0 mt-3">Last Week</p>

            {loadingDashboardPatientsForWeek ? (
              <span>Loading...</span>
            ) : (
              <h2 className="fw-normal mb-3">
                <GoPrimitiveDot color={"#f9bc0d"} size={"25px"} />
                <span>{patientsForWeekCount.previousWeekCount}</span>
              </h2>
            )}
          </Col>
        </Row>
      </div>

      <div className="text-center line-graph">
        <ResponsiveContainer width="100%" height="100%">
          {loadingDashboardPatientsForWeek ? (
            <span className=" mb-3">Loading...</span>
          ) : (
            <LineChart
              width={500}
              height={300}
              data={patientsForWeekCount.weekDayCountData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
              <YAxis type="number" allowDecimals={false} />
              <Tooltip cursor={{ fill: "transparent" }} />
              <Legend verticalAlign="top" height={36} />
              <Line
                type="monotone"
                dataKey="cw"
                stroke="#3688fc"
                activeDot={{ r: 6 }}
                name="Current Week"
                strokeWidth={3}
              />
              <Line
                type="monotone"
                dataKey="pw"
                stroke="#f9bc0d"
                activeDot={{ r: 6 }}
                name="Previous Week"
                strokeWidth={3}
              />
            </LineChart>
          )}
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentClientPatientsForWeek: state.dashboard.currentClientPatientsForWeek,
  loadingDashboardPatientsForWeek:
    state.dashboard.loadingDashboardPatientsForWeek,
});

export default connect(mapStateToProps, {
  getSelectedClientCountForWeek,
})(PatientsWeeklyView);
