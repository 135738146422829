import React from "react";
import { Row, Col, Form } from "react-bootstrap";

import { isValidUrl } from "utils/helper";

const OtherDataURLs = ({ onChange, title, value, isDisabled, i }) => {
  const [URLs, setURLs] = React.useState([]);
  React.useEffect(() => {
    const URLs = value.split(",");
    const trimmed = URLs.map((e) => e.trim());
    setURLs(trimmed);
  }, [value]);

  return (
    <Form.Group className="form-group">
      <Form.Label>{title}</Form.Label>

      {isDisabled ? (
        <Row>
          {URLs.map((url) => (
            <Col md="12">
              {isValidUrl(url) ? (
                <a href={url} target="_blank">
                  {url}
                </a>
              ) : (
                url
              )}
            </Col>
          ))}
        </Row>
      ) : (
        <>
          <Form.Control
            as="textarea"
            name="value"
            value={value}
            onChange={(e) => onChange(e, i)}
          />
          <small className="float-end">
            Note: Put multiple URLs with Comma (,) separated.
          </small>
        </>
      )}
    </Form.Group>
  );
};

export default OtherDataURLs;
