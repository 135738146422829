import React, { useState } from "react";
import { Form, Col } from "react-bootstrap";

const ClientFilters = (props) => {
  const { searchDelay = 750 } = props;

  let delayTimer;

  const handleSearch = (e) => {
    clearTimeout(delayTimer);
    const text = e.target.value;
    delayTimer = setTimeout(() => {
      props.onSearch("search", text);
    }, searchDelay);
  };

  return (
    <>
      {props.onSearch ? (
        <Form.Group>
          <Form.Label htmlFor="search">Search</Form.Label>
          <Form.Control
            type="text"
            placeholder="Search by Name, Code"
            title="Search by Name, Code"
            // name="search"
            // id="search"
            onChange={(e) => handleSearch(e)}
          />
        </Form.Group>
      ) : null}
    </>
  );
};

export default ClientFilters;
