import React from "react";
import { Tabs, Tab, Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import logo from "../../../assets/images/logo-dark.png";

import {
  resetComponentStore,
  removePatientErrors,
  create,
} from "actions/patientActions";
import { getListWoPagination } from "actions/insuranceActions";

import { setErrors } from "actions/clientWorkspaceActions";
import Alert from "Notifications/Alert";

import Spinner from "views/Spinner";
import PatientData from "views/Admin/Clients/Workspace/Patients/PatientDataTab";
import ResponsiblePartyData from "views/Admin/Clients/Workspace/Patients/ResponsiblePartyDataTab";
import InsuranceData from "views/Admin/Clients/Workspace/Patients/InsuranceDataTab";
import OtherDataTab from "views/Admin/Clients/Workspace/Claims/OtherDataTab";

const PatientRegistration = ({
  loadingOnSubmit,
  loggedInUser,
  setErrors,
  removePatientErrors,
  create,
  insurancesListAll,
  getListWoPagination,
}) => {
  const { client_code } = useParams();
  const clientCode = client_code;

  const initialPatientData = {
    patientId: "",
    patientFirstName: "",
    patientLastName: "",
    patientDOB: "",
    patientGender: "",
    patientRace: "",
    patientEthnicity: "",
    address1: "",
    address2: "",
    state: "",
    city: "",
    zip: "",
    country: "USA",
    patientPhoneNumber: "",
    patientEmail: "",
    trace: "",
  };

  const initialResponsiblePartyData = {
    responsibleFirstName: "",
    responsibleLastName: "",
    responsibleDOB: "",
    responsibleEmail: "",
    responsiblePhoneNo: "",
    responsibleRelationship: "",
  };

  const navigate = useNavigate();
  const [isDisabled, setDisabled] = React.useState(false);
  const toggleEdit = (e) => {
    e.preventDefault();
    setDisabled(!isDisabled);
  };
  const [formUpdated, setFormUpdated] = React.useState(false);
  const [popupClosingMessage, setPopupClosingMessage] = React.useState(false);
  const [invalidAccess, setInvalidAccess] = React.useState(false);

  const [patientData, setPatientData] = React.useState(initialPatientData);
  const [responsiblePartyData, setResponsiblePartyData] = React.useState(
    initialResponsiblePartyData
  );
  const [insuranceData, setInsuranceData] = React.useState([]);
  const [otherData, setOtherData] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState("patientData");
  const [isRequiredResponsiblePartyData, setIsRequiredResponsiblePartyData] =
    React.useState(true);

  React.useEffect(() => {
    setPatientData(initialPatientData);
    setResponsiblePartyData(initialResponsiblePartyData);
    setInsuranceData([]);
    setOtherData([]);
    removePatientErrors();
    getListWoPagination().then((res) => {
      if (res && res.status === 401) {
        setInvalidAccess(true);
      }
    });
  }, [clientCode]);

  const checkIsSelfSubscriberRelationship = (
    patientDOB = "",
    insuranceData = ""
  ) => {
    let isRequired = true;

    if (patientDOB) {
      const patientAge = moment().diff(patientDOB, "years");
      if (patientAge <= 17) {
        return (isRequired = true);
      }
    }

    if (insuranceData && insuranceData.length) {
      insuranceData.forEach((obj) => {
        const relation = obj.subscriberRelationship.toLowerCase();

        if (relation === "self") {
          return (isRequired = false);
        }
      });
    }

    return isRequired;
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  const createPatient = (e) => {
    e.preventDefault();
    removePatientErrors();

    const cpOtherData = [];

    for (let each of otherData) {
      let data = [each.id, each.type.toUpperCase(), each.value];
      cpOtherData.push(data);
    }

    const formData = {
      ...patientData,
      ...responsiblePartyData,
      insurance: insuranceData,
      otherData: cpOtherData,
    };

    const submitData = {};
    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    create(submitData, clientCode).then((res) => {
      if (res && res.status) {
        setFormUpdated(true);
        if (window.opener) {
          const patientId =
            res.response && res.response ? res.response.insertedId : null;
          window.opener.onSuccess({ patientId });
          setPopupClosingMessage(true);
          setTimeout(() => {
            window.close();
          }, 3000);
        }
      }
    });
  };

  const onSubmitOtherData = (e) => {
    e.preventDefault();
    createPatient(e);
  };

  const onClickCancel = (e) => {
    e.preventDefault();

    setDisabled(true);
    resetComponentStore();
    setIsRequiredResponsiblePartyData(true);
  };

  return formUpdated || invalidAccess ? (
    <div className="text-center p-5">
      <Alert />
    </div>
  ) : (
    <Container className="add-patient-box" fluid>
      {loadingOnSubmit || !loggedInUser ? (
        <Spinner />
      ) : (
        <>
          <Row>
            <Col className="text-center">
              <Link to="/">
                <span>
                  <img src={logo} alt="" height="40" />
                </span>
              </Link>
              <h3>Patient Registration Form</h3>
            </Col>
          </Row>
          <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
            <Tab eventKey="patientData" title="Patient">
              <PatientData
                patientData={patientData}
                setPatientData={setPatientData}
                loadingOnSubmit={loadingOnSubmit}
                isDisabled={isDisabled}
                toggleEdit={toggleEdit}
                onClickCancel={onClickCancel}
                loggedInUser={loggedInUser}
                handleTabSelect={handleTabSelect}
                setErrors={setErrors}
                clientCode={clientCode}
                setIsRequiredResponsiblePartyData={
                  setIsRequiredResponsiblePartyData
                }
                removePatientErrors={removePatientErrors}
              />
            </Tab>

            <Tab eventKey="insuranceData" title="Insurance Data">
              <InsuranceData
                insuranceData={insuranceData}
                setInsuranceData={setInsuranceData}
                loadingOnSubmit={loadingOnSubmit}
                isDisabled={isDisabled}
                toggleEdit={toggleEdit}
                onClickCancel={onClickCancel}
                loggedInUser={loggedInUser}
                handleTabSelect={handleTabSelect}
                setErrors={setErrors}
                patientDOB={patientData.patientDOB}
                setIsRequiredResponsiblePartyData={
                  setIsRequiredResponsiblePartyData
                }
                checkIsSelfSubscriberRelationship={
                  checkIsSelfSubscriberRelationship
                }
                insurancesListAll={insurancesListAll}
                removePatientErrors={removePatientErrors}
              />
            </Tab>

            <Tab eventKey="responsibleParty" title="Responsible Party">
              <ResponsiblePartyData
                responsiblePartyData={responsiblePartyData}
                loadingOnSubmit={loadingOnSubmit}
                isDisabled={isDisabled}
                setResponsiblePartyData={setResponsiblePartyData}
                toggleEdit={toggleEdit}
                onClickCancel={onClickCancel}
                loggedInUser={loggedInUser}
                handleTabSelect={handleTabSelect}
                setErrors={setErrors}
                isRequiredResponsiblePartyData={isRequiredResponsiblePartyData}
                removePatientErrors={removePatientErrors}
              />
            </Tab>

            <Tab eventKey="otherData" title="Other Data">
              <OtherDataTab
                otherData={otherData}
                setOtherData={setOtherData}
                onSubmitOtherData={onSubmitOtherData}
                loadingOnSubmit={loadingOnSubmit}
                isDisabled={isDisabled}
                toggleEdit={toggleEdit}
                onClickCancel={onClickCancel}
                loggedInUser={loggedInUser}
              />
            </Tab>
          </Tabs>
        </>
      )}

      {popupClosingMessage ? (
        <p
          style={{
            color: "green",
            marginTop: "50px",
            fontSize: "24px",
            textAlign: "center",
          }}
        >
          <span>
            Closing popup automatically in 3 second... If not closed in 3
            seconds, please close it manually.
          </span>
        </p>
      ) : null}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  loadingOnSubmit: state.patient.loadingOnSubmit,
  loggedInUser: state.auth.user,
  insurancesListAll: state.insurance.ListAll,
});

export default connect(mapStateToProps, {
  setErrors,
  removePatientErrors,
  create,
  getListWoPagination,
})(PatientRegistration);
