import React from "react";
import { connect } from "react-redux";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import CRMOverReview from "./CRMOverReview";
import CampaignRevenue from "./CampaignRevenue";
import TopUsers from "./TopUsers";
import ClaimsCount from "./Claims/ClaimsCount";
import ClaimsOverview from "./Claims/ClaimsOverview";
import PatientsCount from "./Patients/PatientsCount";
import PatientsOverview from "./Patients/PatientsOverview";
import SubmissionStatusOverview from "./SubmissionStatusOverview";

import { getDashboardClientsList } from "actions/dashboardActions";

const AdminDashboard = ({ clientsList, getDashboardClientsList }) => {
  React.useEffect(() => {
    getDashboardClientsList();
  }, [getDashboardClientsList]);

  const [selectedClient, setSelectedClient] = React.useState("");
  React.useEffect(() => {
    if (!clientsList) return;
    if (!clientsList.data.length) return;
    setSelectedClient(clientsList.data[0]);
  }, [clientsList]);

  return (
    <React.Fragment>
      {/* Used pageTitle : Dashboard for show client's list as dropdown in Breadcrumb */}
      <AppBreadcrumb
        pageTitle="Dashboard"
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
        clientsList={clientsList}
      />
      <ClaimsCount selectedClient={selectedClient} />
      <ClaimsOverview selectedClient={selectedClient} />
      <PatientsCount selectedClient={selectedClient} />
      <PatientsOverview selectedClient={selectedClient} />
      <SubmissionStatusOverview selectedClient={selectedClient} />
      {/* <br />
      <CRMOverReview />
      <CampaignRevenue />
      <TopUsers /> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  clientsList: state.dashboard.clientsList,
});

export default connect(mapStateToProps, { getDashboardClientsList })(
  AdminDashboard
);
