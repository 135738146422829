import Dashboard from "views/Admin/Dashboard";
import NotFoundInner from "views/404Inner";

import UsersList from "views/Admin/Users/UsersList";
import CreateUser from "views/Admin/Users/AddUser/CreateUser";
import ViewUser from "views/Admin/Users/ViewUser";

import ClientsList from "views/Admin/Clients/ClientsList";
import AddClient from "views/Admin/Clients/AddClient";
import EditClient from "views/Admin/Clients/EditClient";
import ClientWorkspace from "views/Admin/Clients/Workspace";

import AppTokensList from "views/Admin/AppTokens/AppTokensList";
import CreateAppToken from "views/Admin/AppTokens/CreateAppToken";

import AdminProfile from "views/Admin/Profile/AdminProfile";
import EditProfile from "views/Admin/Profile/EditProfile";

import PartnersList from "views/Admin/Partners/PartnersList";
import InsuranceList from "views/Admin/InsuranceMappings/InsuranceList";

import LogsList from "views/Admin/Logs/LogsList";
import ICDCodesList from "views/Admin/ICDCodes/ICDCodesList";
import CPTCodesList from "views/Admin/CPT_Codes/CPTCodesList";

const AdminRoutes = [
  {
    path: "/dashboard",
    name: "Admin Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/users",
    name: "Users List",
    element: <UsersList />,
  },
  {
    path: "/users/create",
    name: "Add User",
    element: <CreateUser />,
  },
  {
    path: "/users/:user_id/*",
    name: "View User",
    element: <ViewUser />,
  },
  {
    path: "/clients",
    name: "Clients List",
    element: <ClientsList />,
  },
  {
    path: "/clients/create",
    name: "Add Client",
    element: <AddClient />,
  },
  {
    path: "/clients/:client_id/edit",
    name: "Edit Client",
    element: <EditClient />,
  },
  {
    path: "/clients/:client_id/workspace/*",
    name: "Client Workspace",
    element: <ClientWorkspace />,
  },
  {
    path: "/app-tokens",
    name: "App Tokens",
    element: <AppTokensList />,
  },
  {
    path: "/app-tokens/create",
    name: "Add App Token",
    element: <CreateAppToken />,
  },
  {
    path: "/profile",
    name: "Admin Profile",
    element: <AdminProfile />,
  },
  {
    path: "/profile/edit",
    name: "Edit Profile",
    element: <EditProfile />,
  },
  {
    path: "/partners",
    name: "Partners List",
    element: <PartnersList />,
  },
  {
    path: "/partners/:partner_id/insurances",
    name: "Insurance List",
    element: <InsuranceList />,
  },
  {
    path: "/logs",
    name: "Logs",
    element: <LogsList />,
  },
  {
    path: "/icd-codes",
    name: "ICD Codes List",
    element: <ICDCodesList />,
  },
  {
    path: "/cpt-codes",
    name: "CPT Codes List",
    element: <CPTCodesList />,
  },
  {
    path: "/*",
    element: <NotFoundInner />,
  },
];

export default AdminRoutes;
