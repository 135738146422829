import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { FaRegEye } from "react-icons/fa";
import { BiPlusMedical, BiTrash } from "react-icons/bi";

import { isEditor, isAdmin } from "utils/helper";

import {
  getClientServicesList,
  resetComponentStore,
  deleteRecord,
} from "actions/clientServicesActions";

import PiDataTable from "views/DataTable/PiDataTable";
import ClientServiceModal from "./ClientServiceModal";
import ClientFilters from "./Filters";

const ClientServicesList = ({
  List: { data = [], count = 0 },
  getClientServicesList,
  loadingList,
  resetComponentStore,
  sortingParams,
  clientCode,
  loggedInUser,
  deleteRecord,
}) => {
  const [onlyOnce, setOnce] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [selectedServiceID, setSelectedServiceID] = React.useState(null);
  const toggle = () => setModal(!modal);

  const { page, limit } = sortingParams;

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [serviceParams, setServiceParams] =
    React.useState(initialSortingParams);

  const onClickHandler = (row_id) => {
    setSelectedServiceID(row_id);
    setModal(true);
  };

  const columns = [
    {
      name: "Service Code",
      selector: (row) => row.service_code,
      sortable: true,
      sortField: "service_code",
      width: "15%",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      sortField: "description",
      width: "20%",
      wrap: true,
    },
    {
      name: "CPT Code",
      selector: (row) => row.service_CPT,
      sortable: true,
      sortField: "service_CPT",
      width: "15%",
    },
    {
      name: "Bill Type",
      selector: (row) => row.billType,
      sortable: true,
      sortField: "bill_type",
      width: "15%",
    },
    {
      name: "Fee(USD)",
      selector: (row) => row.fee,
      sortable: true,
      sortField: "fee",
      width: "15%",
      wrap: true,
    },
    {
      name: "Actions",
      width: "calc(20% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Button
            variant="primary"
            size="sm"
            onClick={(e) => onClickHandler(row._id)}
          >
            <FaRegEye />
          </Button>

          {loggedInUser && isAdmin(loggedInUser) ? (
            <Button
              className="ml-1"
              size="sm"
              title="Delete"
              type="button"
              variant="danger"
              onClick={(e) => {
                if (
                  window.confirm(
                    `Are you sure you want to delete: ${row.patientName}?`
                  )
                ) {
                  deleteRecord(clientCode, row._id);
                }
              }}
            >
              <BiTrash />
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }
    if (!clientCode) return;
    getClientServicesList(serviceParams, clientCode);
  }, [getClientServicesList, serviceParams, resetComponentStore]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };
    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              "serviceInfo.service_code": {
                value: searchText,
                type: "String",
              },
              "serviceInfo.service_CPT": {
                value: searchText,
                type: "String",
              },
              "serviceInfo.description": {
                value: searchText,
                type: "String",
              },
              billType: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setServiceParams(params);
  };

  const onFilterChange = (newParams, optionValue) => {
    setServiceParams((params) => ({ ...params, ...newParams }));
  };

  const actions = (
    <div className="page-actions">
      {isEditor(loggedInUser) ? (
        <Button
          color="primary"
          size="sm"
          onClick={(e) => {
            setModal(true);
          }}
        >
          <BiPlusMedical /> Create New Service
        </Button>
      ) : null}
    </div>
  );

  return (
    <React.Fragment>
      <ClientServiceModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        selectedID={selectedServiceID}
        setSelectedID={setSelectedServiceID}
        clientCode={clientCode}
      />
      <Card>
        <Card.Body>
          <Row className="table-filter-section">
            {actions}
            <Col md="4">
              <ClientFilters
                type="text"
                onSearch={handleTableChange}
                filterType="String"
                filterName="Search"
                filterParams={serviceParams}
                onFilterChange={onFilterChange}
              />
            </Col>
          </Row>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={serviceParams}
            setParams={setServiceParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

ClientServicesList.propTypes = {
  getClientServicesList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  List: state.client_service.List,
  loadingList: state.client_service.loadingList,
  sortingParams: state.client_service.sortingParams,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getClientServicesList,
  resetComponentStore,
  deleteRecord,
})(ClientServicesList);
