import React from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";

import { removePageErrors, save, setErrors } from "actions/cpt_codeActions";

import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";

const CPTCodeModal = ({
  modal,
  setModal,
  toggle,
  selectedCPTCodeID,
  setSelectedCPTCodeID,
  loadingRecord,
  setErrors,
  removePageErrors,
  save,
  CPTCodesList,
}) => {
  const initialData = {
    cpt_code: "",
    description: "",
  };
  const [formData, setFormData] = React.useState(initialData);
  const [currentCPTCode, setCurrentCPTCode] = React.useState(null);

  const { cpt_code, description } = formData;

  React.useEffect(() => {
    setFormData(initialData);

    removePageErrors();

    if (!selectedCPTCodeID) return;

    const currentRow = CPTCodesList.data.find(
      (row) => row._id === selectedCPTCodeID
    );
    setCurrentCPTCode((old) => ({ ...old, ...currentRow }));
  }, [selectedCPTCodeID, modal]);

  React.useEffect(() => {
    if (!currentCPTCode) return;

    const { cpt_code, description } = currentCPTCode;

    const data = {
      cpt_code,

      description,
    };
    setFormData((form) => ({ ...form, ...data }));
  }, [currentCPTCode]);

  const onChange = (e) => {
    if (!e.target) return;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    removePageErrors();

    let validationRules = [
      {
        param: "cpt_code",
        msg: "The CPT Code is required",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};
    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    save(submitData, selectedCPTCodeID).then((res) => {
      if (res.status) {
        setModal(false);
      }
    });
  };

  const reset = () => {
    setModal(false);
    setSelectedCPTCodeID(null);
    setFormData(initialData);
  };

  return (
    <Modal
      show={modal}
      onHide={reset}
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
    >
      <Modal.Header toggle={toggle} closeButton>
        <h4>{selectedCPTCodeID ? "Edit" : "Create new"} CPT Code</h4>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={(e) => onSubmitHandler(e)} autoComplete="off">
          <Row>
            <Col xs="12">
              <Form.Group className="form-group">
                <Form.Label>
                  CPT Code <span className="red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="cpt_code"
                  value={cpt_code}
                  onChange={(e) => onChange(e)}
                />

                <Errors current_key="cpt_code" key="cpt_code" />
              </Form.Group>
            </Col>

            <Col xs="12">
              <Form.Group className="form-group">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  value={description}
                  maxLength="300"
                  onChange={(e) => onChange(e)}
                />

                <Errors current_key="description" key="description" />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="float-end">
                <Button
                  className="m-2"
                  type="submit"
                  size="sm"
                  variant="primary"
                  disabled={loadingRecord}
                >
                  {loadingRecord ? "Saving..." : "Submit"}
                </Button>
                <Button
                  className="ml-2"
                  type="reset"
                  size="sm"
                  variant="danger"
                  onClick={reset}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currentCPTCode: state.cpt_code.currentRecord,
  loadingRecord: state.cpt_code.loadingRecord,
  CPTCodesList: state.cpt_code.CPTCodesList,
});

export default connect(mapStateToProps, {
  setErrors,
  removePageErrors,
  save,
})(CPTCodeModal);
