import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import Errors from "Notifications/Errors";

import { saveUserClientAccess, removeUserErrors } from "actions/user";
import { getClientsWoPagination } from "actions/client";
import Spinner from "views/Spinner";

const ClientAccess = ({
  saveUserClientAccess,
  currentUser,
  removeUserErrors,
  getClientsWoPagination,
}) => {
  const { user_id } = useParams();

  const initialFormData = {
    userClientsAccess: [],
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [submitting, setSubmitting] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  const loadUserFormData = (currentUser) => {
    const { userClientsAccess = [] } = currentUser;

    setFormData((formData) => ({ ...formData, userClientsAccess }));
  };

  React.useEffect(() => {
    if (!currentUser) return;

    loadUserFormData(currentUser);
  }, [currentUser]);

  const { userClientsAccess } = formData;

  const [globalClientsList, setGlobalClientsList] = React.useState([]);
  const [loadingGlobalClients, setLoadingGlobalClients] = React.useState(true);
  React.useEffect(() => {
    getClientsWoPagination().then((response) => {
      setGlobalClientsList(response);
      setLoadingGlobalClients(false);
    });
  }, [getClientsWoPagination]);

  const onChangeCheck = (e) => {
    if (!e.target) {
      return;
    }

    let newArray = [];
    if (e.target.checked) {
      newArray = [...userClientsAccess, e.target.value];
    } else {
      newArray = userClientsAccess.filter((each) => each !== e.target.value);
    }

    setFormData({
      ...formData,
      userClientsAccess: newArray,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeUserErrors();

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    // console.log("Update Client Access Submit data", submitData);
    setSubmitting(true);
    saveUserClientAccess(submitData, user_id).then((res) => {
      setSubmitting(false);
      toggleEdit();
    });
  };

  const onClickCancel = (e) => {
    e.preventDefault();
    loadUserFormData(currentUser);
    toggleEdit();
  };

  return (
    <Card className="card-body">
      <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
        <div className="card-heading mb-3">
          <h4 className="header-title">Setup client's access</h4>
          <Button
            variant="link"
            size="sm"
            className="float-end"
            onClick={toggleEdit}
          >
            {isDisabled ? (
              <span>
                <MdEdit title="Click to Edit" size={20} />
              </span>
            ) : (
              <span>
                <FaRegEye title="View mode" size={20} />
              </span>
            )}
          </Button>
        </div>

        {loadingGlobalClients ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <Form.Group className="form-group">
              <Row>
                {globalClientsList.map((client, k) => (
                  <Col xs="12" sm="6" key={k}>
                    <div className="form-check mb-2">
                      <Form.Check
                        id={client.code}
                        name="userClientAccess"
                        value={client.code}
                        checked={userClientsAccess.includes(client.code)}
                        onChange={(e) => !isDisabled && onChangeCheck(e)}
                      />
                      <Form.Label htmlFor={client.code}>
                        {client.name}
                      </Form.Label>
                    </div>
                  </Col>
                ))}
              </Row>

              <Errors current_key="userClientAccess" key="userClientAccess" />
            </Form.Group>

            <div className="float-end">
              <Button
                className="m-2"
                type="submit"
                variant="primary"
                disabled={submitting || isDisabled}
              >
                {submitting ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {` Loading... `}
                  </>
                ) : (
                  <>Save</>
                )}
              </Button>
              <Button
                className="ml-2"
                type="reset"
                variant="danger"
                onClick={onClickCancel}
                disabled={submitting || isDisabled}
              >
                Cancel
              </Button>
            </div>
          </React.Fragment>
        )}
      </Form>
    </Card>
  );
};

ClientAccess.propTypes = {
  saveUserClientAccess: PropTypes.func.isRequired,
  getClientsWoPagination: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, {
  saveUserClientAccess,
  removeUserErrors,
  getClientsWoPagination,
})(ClientAccess);
