import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BiPlusMedical, BiTrash, BiLogInCircle } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";

import {
  getClientsList,
  deleteClient,
  resetComponentStore,
} from "actions/client";

import { isAdmin } from "utils/helper";

import ClientFilters from "./ClientFilters";

const ClientsList = ({
  clientsList: { data, count },
  getClientsList,
  deleteClient,
  loadingClientList,
  resetComponentStore,
  sortingParams,
  loggedInUser,
}) => {
  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);

  const [onlyOnce, setOnce] = React.useState(true);
  const actions = (
    <div className="page-actions">
      {isAdmin(loggedInUser) ? (
        <Link to="/admin/clients/create" title="create">
          <Button size="sm">
            <BiPlusMedical /> Create New Client
          </Button>
        </Link>
      ) : null}
    </div>
  );

  const columns = [
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
      sortField: "code",
      width: "20%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "name",
      width: "20%",
    },
    {
      name: "Comments",
      selector: (row) => row.comments,
      sortable: true,
      sortField: "comments",
      width: "20%",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <div>
          {row.status === 1 ? <span>Active</span> : <span>In Active</span>}
        </div>
      ),
      sortable: true,
      sortField: "status",
      width: "20%",
    },
    {
      name: "Actions",
      width: "calc(20% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Link
            to={`/admin/clients/${row._id}/workspace/patients`}
            title="Access workspace"
          >
            <Button variant="primary" size="sm">
              <BiLogInCircle />
            </Button>
          </Link>

          {loggedInUser && isAdmin(loggedInUser) ? (
            <Link to={`/admin/clients/${row._id}/edit`} title="View/Edit">
              <Button variant="primary" size="sm" className="ml-1">
                <BsFillPencilFill />
              </Button>
            </Link>
          ) : null}

          {loggedInUser && isAdmin(loggedInUser) ? (
            <Button
              className="ml-1"
              size="sm"
              title="Delete"
              type="button"
              variant="danger"
              onClick={(e) => {
                if (
                  window.confirm(
                    `Are you sure you want to delete: ${row.name}?`
                  )
                ) {
                  deleteClient(row._id);
                }
              }}
            >
              <BiTrash />
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }
    getClientsList(clientParams);
  }, [getClientsList, clientParams, resetComponentStore]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              name: {
                value: searchText,
                type: "String",
              },
              code: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setClientParams(params);
  };

  const onFilterChange = (newParams) => {
    setClientParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="Clients" crumbs={[{ name: "Clients" }]} />
      <Card>
        <Card.Body>
          {actions}

          <div className="table-filter-section">
            <Row>
              <Col md="2">
                <ClientFilters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterParams={clientParams}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingClientList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

ClientsList.propTypes = {
  getClientsList: PropTypes.func.isRequired,
  deleteClient: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  clientsList: state.client.clientsList,
  loadingClientList: state.client.loadingClientList,
  sortingParams: state.client.sortingParams,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getClientsList,
  deleteClient,
  resetComponentStore,
})(ClientsList);
