import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";

import Errors from "Notifications/Errors";
import {
  clientStatus,
  PatientRaceGlobal,
  PatientEthnicityGlobal,
  GenderGlobal,
} from "constants/index";
import { isEditor, capitalizeInput } from "utils/helper";
import { validateForm } from "utils/validation";

const PatientDataTab = ({
  currentClientPatient,
  patientData,
  setPatientData,
  loadingOnSubmit,
  isDisabled,
  toggleEdit,
  onClickCancel,
  savePatientData,
  loggedInUser,
  selectedPatientID,
  handleTabSelect,
  setErrors,
  clientCode,
  loadPatientToTebra,
  removePatientErrors,
}) => {
  const {
    patientId,
    patientFirstName,
    patientLastName,
    patientDOB,
    patientGender,
    patientRace,
    patientEthnicity,
    address1,
    address2,
    state,
    city,
    zip,
    country,
    patientPhoneNumber,
    patientEmail,
    trace,
  } = patientData;

  const [selectedStatus, setSelectedStatus] = React.useState(null);
  const [statusOptions, setStatusOptions] = React.useState([]);
  const [loadingTebra, setLoadingTebra] = React.useState(false);

  React.useEffect(() => {
    const options = clientStatus.filter((each) => each.value != "DONE");
    setStatusOptions(options);

    if (!trace || !trace.bsys) return;

    const filtered = clientStatus.find(
      (each) => each.value === trace.bsys.status
    );
    if (!filtered) return;

    setSelectedStatus(filtered);
  }, [patientData]);

  const onChange = (e) => {
    if (!e.target) return;
    setPatientData({ ...patientData, [e.target.name]: e.target.value });
  };

  const handleSelect2 = (field) => (selectedOption) => {
    setPatientData({ ...patientData, [field]: selectedOption.value });
  };

  const handleSelect = (selectedOption) => {
    setSelectedStatus(selectedOption);
    setPatientData({
      ...patientData,
      trace: {
        ...trace,
        bsys: {
          ...trace.bsys,
          status: selectedOption.value,
        },
      },
    });
  };

  const onClickNext = (e) => {
    e.preventDefault();

    removePatientErrors();

    let validationRules = [
      {
        param: "patientId",
        msg: "Patient Id is required.",
      },
      {
        param: "patientFirstName",
        msg: "Patient first name is required.",
      },
      {
        param: "patientLastName",
        msg: "Patient last name is required.",
      },
      {
        param: "patientDOB",
        msg: "Patient DOB is required.",
      },
      {
        param: "patientGender",
        msg: "Patient Gender should have any of these values SELF, SPOUSE, CHILD, OTHER.",
      },
      {
        param: "address1",
        msg: "Patient address is required.",
      },
      {
        param: "city",
        msg: "City is required.",
      },
      {
        param: "state",
        msg: "State is required.",
      },
      {
        param: "zip",
        msg: "Please provide zipcode",
      },
      {
        param: "patientPhoneNumber",
        msg: "Patient phone number is required.",
      },
    ];

    const errors = validateForm(patientData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }
    handleTabSelect("insuranceData");
  };

  const onClickLoadTebra = () => {
    if (
      !window.confirm("Are you sure you want to load this record to Tebra?")
    ) {
      return;
    }

    console.log("On Click load to Tebra.");

    setLoadingTebra(true);
    loadPatientToTebra(selectedPatientID, clientCode).then(() => {
      setLoadingTebra(false);
    });
  };

  return (
    <Form onSubmit={(e) => savePatientData(e)}>
      <Row>
        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Patient Id</Form.Label>
            <Form.Control
              type="text"
              name="patientId"
              value={patientId ? patientId : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="patientId" key="patientId" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Patient First Name</Form.Label>
            <Form.Control
              type="text"
              name="patientFirstName"
              value={patientFirstName ? patientFirstName : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="patientFirstName" key="patientFirstName" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Patient Last Name</Form.Label>
            <Form.Control
              type="text"
              name="patientLastName"
              value={patientLastName ? patientLastName : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="patientLastName" key="patientLastName" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Patient DOB</Form.Label>
            <Form.Control
              type="date"
              name="patientDOB"
              value={patientDOB ? patientDOB : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="patientDOB" key="patientDOB" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Patient Gender</Form.Label>

            <Select
              id="patientGender"
              name="patientGender"
              options={GenderGlobal}
              value={GenderGlobal.find((r) => r.value === patientGender)}
              onChange={handleSelect2("patientGender")}
              isDisabled={isDisabled}
            />

            <Errors current_key="patientGender" key="patientGender" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Patient Race</Form.Label>
            <Select
              id="patientRace"
              name="patientRace"
              options={[
                {
                  label: "Select Race",
                  value: "",
                },
                ...PatientRaceGlobal,
              ]}
              value={PatientRaceGlobal.find((r) => r.value === patientRace)}
              onChange={handleSelect2("patientRace")}
              isDisabled={isDisabled}
            />

            <Errors current_key="patientRace" key="patientRace" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Patient Ethnicity</Form.Label>
            <Select
              id="patientEthnicity"
              name="patientEthnicity"
              options={[
                {
                  label: "Select Ethnicity",
                  value: "",
                },
                ...PatientEthnicityGlobal,
              ]}
              value={PatientEthnicityGlobal.find(
                (r) => r.value === patientEthnicity
              )}
              onChange={handleSelect2("patientEthnicity")}
              isDisabled={isDisabled}
            />

            <Errors current_key="patientEthnicity" key="patientEthnicity" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Address 1</Form.Label>
            <Form.Control
              type="text"
              name="address1"
              value={address1 ? address1 : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="address1" key="address1" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Address 2</Form.Label>
            <Form.Control
              type="text"
              name="address2"
              value={address2 ? address2 : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="address2" key="address2" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={city ? city : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="city" key="city" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              name="state"
              value={state ? state : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="state" key="state" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              type="text"
              name="zip"
              value={zip ? zip : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="zip" key="zip" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              name="country"
              value={country ? country : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
              maxLength="3"
              onInput={capitalizeInput}
            />

            <Errors current_key="country" key="country" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Patient Phone Number</Form.Label>
            <Form.Control
              type="text"
              name="patientPhoneNumber"
              value={patientPhoneNumber ? patientPhoneNumber : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="patientPhoneNumber" key="patientPhoneNumber" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Patient Email</Form.Label>
            <Form.Control
              type="text"
              name="patientEmail"
              value={patientEmail ? patientEmail : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="patientEmail" key="patientEmail" />
          </Form.Group>
        </Col>

        {trace && trace.bsys && trace.bsys.status != "DONE" ? (
          <Col xxl="2" lg="3" md="4" xs="6">
            <Form.Group className="form-group">
              <Form.Label>Status</Form.Label>
              <Select
                id="status"
                name="status"
                options={statusOptions}
                value={selectedStatus}
                onChange={handleSelect}
                isDisabled={isDisabled}
              />

              <Errors current_key="status" key="status" />
            </Form.Group>
          </Col>
        ) : null}
      </Row>

      {isEditor(loggedInUser) ? (
        <div className="float-end">
          {selectedPatientID ? (
            <React.Fragment>
              {currentClientPatient &&
              currentClientPatient["trace"]["bsys"] &&
              currentClientPatient["trace"]["bsys"]["status"] === "READY" ? (
                <Button
                  className="me-2 btn-outline-dark"
                  type="button"
                  onClick={onClickLoadTebra}
                  disabled={loadingTebra}
                >
                  {loadingTebra ? "Loading..." : "Load to Tebra"}
                </Button>
              ) : null}

              {!isDisabled ? (
                <React.Fragment>
                  <Button
                    className="m-2"
                    type="submit"
                    variant="primary"
                    disabled={loadingOnSubmit || isDisabled}
                  >
                    {loadingOnSubmit ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {` Loading... `}
                      </>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                  <Button
                    className="ml-2"
                    type="reset"
                    variant="danger"
                    onClick={onClickCancel}
                    disabled={loadingOnSubmit || isDisabled}
                  >
                    Cancel
                  </Button>
                </React.Fragment>
              ) : (
                <Button
                  className="btn btn-outline-dark"
                  size="sm"
                  onClick={toggleEdit}
                >
                  Click to Edit
                </Button>
              )}
            </React.Fragment>
          ) : (
            <Button
              className="ms-2"
              type="button"
              variant="primary"
              onClick={(e) => onClickNext(e)}
            >
              Next
            </Button>
          )}
        </div>
      ) : null}
    </Form>
  );
};

export default PatientDataTab;
