import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import { FaRegEye } from "react-icons/fa";
import { BiPlusMedical, BiTrash } from "react-icons/bi";

import { getBI_PAStatusByValue, isEditor, isAdmin } from "utils/helper";

import {
  getBI_PAList,
  resetComponentStore,
  deleteBI_PA,
} from "actions/bi_paActions";

import PiDataTable from "views/DataTable/PiDataTable";
import BenefitsInvestigationModal from "./BenefitsInvestigationModal";
import BI_PAFilters from "./BI_PAFilters";

const BenefitsInvestigationsList = ({
  BI_PAList: { data = [], count = 0 },
  getBI_PAList,
  loadingBI_PAList,
  resetComponentStore,
  sortingParams,
  clientCode,
  loggedInUser,
  deleteBI_PA,
}) => {
  const [onlyOnce, setOnce] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [selectedBI_PA_ID, setSelectedBI_PA_ID] = React.useState(null);
  const toggle = () => setModal(!modal);

  const { page, limit } = sortingParams;

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [BI_PAParams, setBI_PAParams] = React.useState(initialSortingParams);

  const onClickHandler = (row_id) => {
    setSelectedBI_PA_ID(row_id);
    setModal(true);
  };

  const columns = [
    {
      name: "Case Number",
      selector: (row) => row.caseNumber,
      sortable: true,
      sortField: "caseNumber",
      width: "10%",
    },
    {
      name: "Patient ID",
      selector: (row) => row.patientId,
      sortable: true,
      sortField: "patientId",
      width: "10%",
    },
    {
      name: "Patient Name",
      selector: (row) => row.patientName,
      sortable: true,
      sortField: "patientName",
      width: "15%",
    },
    {
      name: "Date of Birth",
      selector: (row) =>
        row.patientDOB ? moment(row.patientDOB).format("MM/DD/YYYY") : "",
      sortable: true,
      sortField: "patientDOB",
      width: "10%",
    },
    {
      name: "Insurance Company",
      selector: (row) => row.insuranceCompanyName,
      sortable: true,
      sortField: "insuranceCompanyName",
      width: "20%",
      wrap: true,
    },
    {
      name: "Date Posted",
      selector: (row) =>
        row.createdAt ? moment(row.createdAt).format("MM/DD/YYYY") : "",
      sortable: true,
      sortField: "createdAt",
      width: "10%",
    },
    {
      name: "Status",
      selector: (row) => getBI_PAStatusByValue(row.status)?.label,
      width: "10%",
    },
    {
      name: "Actions",
      width: "calc(15% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Button
            variant="primary"
            size="sm"
            onClick={(e) => onClickHandler(row._id)}
          >
            <FaRegEye />
          </Button>

          {loggedInUser && isAdmin(loggedInUser) ? (
            <Button
              className="ml-1"
              size="sm"
              title="Delete"
              type="button"
              variant="danger"
              onClick={(e) => {
                if (
                  window.confirm(
                    `Are you sure you want to delete: ${row.patientName}?`
                  )
                ) {
                  deleteBI_PA(clientCode, row._id);
                }
              }}
            >
              <BiTrash />
            </Button>
          ) : null}
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }
    if (!clientCode) return;
    getBI_PAList(BI_PAParams, clientCode);
  }, [getBI_PAList, BI_PAParams, resetComponentStore]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };
    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              caseNumber: {
                value: searchText,
                type: "String",
              },
              patientId: {
                value: searchText,
                type: "String",
              },
              patientName: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setBI_PAParams(params);
  };

  const onFilterChange = (newParams, optionValue) => {
    setBI_PAParams((params) => ({ ...params, ...newParams }));
  };

  const actions = (
    <div className="page-actions">
      {isEditor(loggedInUser) ? (
        <Button
          color="primary"
          size="sm"
          onClick={(e) => {
            setModal(true);
          }}
        >
          <BiPlusMedical /> Create New BI/PA
        </Button>
      ) : null}
    </div>
  );

  return (
    <React.Fragment>
      <BenefitsInvestigationModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        selectedBI_PA_ID={selectedBI_PA_ID}
        setSelectedBI_PA_ID={setSelectedBI_PA_ID}
        clientCode={clientCode}
      />
      <Card>
        <Card.Body>
          <Row className="table-filter-section">
            {actions}
            <Col md="4">
              <BI_PAFilters
                type="text"
                onSearch={handleTableChange}
                filterType="String"
                filterName="Search"
                filterParams={BI_PAParams}
                onFilterChange={onFilterChange}
              />
            </Col>
          </Row>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={BI_PAParams}
            setParams={setBI_PAParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingBI_PAList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

BenefitsInvestigationsList.propTypes = {
  getBI_PAList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  BI_PAList: state.bi_pa.BI_PAList,
  loadingBI_PAList: state.bi_pa.loadingBI_PAList,
  sortingParams: state.bi_pa.sortingParams,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getBI_PAList,
  resetComponentStore,
  deleteBI_PA,
})(BenefitsInvestigationsList);
