import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { FaRegEye } from "react-icons/fa";
import moment from "moment";

import PiDataTable from "views/DataTable/PiDataTable";

import {
  getClaimNotificationsList,
  resetComponentStore,
} from "actions/clientWorkspaceActions";

import ClaimNotificationFilters from "./ClaimNotificationFilters";
import NotificationModal from "./ViewNotificationModal";

const ClaimNotificationsList = ({
  claimNotificationsList: { data, count },
  getClaimNotificationsList,
  loadingClaimNotificationList,
  resetComponentStore,
  sortingParams,
  clientCode,
}) => {
  const [onlyOnce, setOnce] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [setectedNotificationID, setSetectedNotificationID] =
    React.useState(null);
  const toggle = () => setModal(!modal);

  const { page, limit } = sortingParams;

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "postedAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [claimNotificationParams, setClaimNotificationParams] =
    React.useState(initialSortingParams);

  const onClickHandler = (row_id) => {
    setSetectedNotificationID(row_id);
    setModal(true);
  };

  const columns = [
    {
      name: "Date Posted",
      selector: (row) => moment(row.postedAt).format("MM/DD/YYYY"),
      sortable: true,
      sortField: "postedAt",
      width: "15%",
    },
    {
      name: "Endpoint",
      selector: (row) => row.trace.operation,
      sortable: false,
      sortField: "endpoint",
      width: "15%",
    },
    {
      name: "ErrorCode",
      selector: (row) => row.trace.client.errCode,
      sortable: false,
      sortField: "errorCode",
      width: "10%",
    },
    {
      name: "Message",
      selector: (row) => row.trace.client.errMsg,
      sortable: false,
      sortField: "errorMsg",
      width: "40%",
      wrap: true,
    },
    {
      name: "Actions",
      width: "calc(20% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Button
            variant="primary"
            size="sm"
            onClick={(e) => onClickHandler(row._id)}
          >
            <FaRegEye />
          </Button>
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (!clientCode) return;
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }
    getClaimNotificationsList(claimNotificationParams, clientCode);
  }, [
    getClaimNotificationsList,
    claimNotificationParams,
    resetComponentStore,
    clientCode,
  ]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              "trace.client.errMsg": {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setClaimNotificationParams(params);
  };

  const onFilterChange = (newParams) => {
    setClaimNotificationParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <NotificationModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        setectedNotificationID={setectedNotificationID}
        setSetectedNotificationID={setSetectedNotificationID}
      />

      <Card>
        <Card.Body>
          <div className="table-filter-section">
            <Row>
              <Col md="2">
                <ClaimNotificationFilters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterName="Search"
                  filterParams={claimNotificationParams}
                  onFilterChange={onFilterChange}
                />
              </Col>

              <Col md="2">
                <ClaimNotificationFilters
                  filter="createdAt"
                  type="date-range-picker"
                  filterType="Date"
                  filterName="Date Posted"
                  filterParams={claimNotificationParams}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={claimNotificationParams}
            setParams={setClaimNotificationParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingClaimNotificationList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

ClaimNotificationsList.propTypes = {
  getClaimNotificationsList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  claimNotificationsList: state.clientWorkspace.claimNotificationsList,
  loadingClaimNotificationList:
    state.clientWorkspace.loadingClaimNotificationsList,
  sortingParams: state.clientWorkspace.claimNotificationParams,
});

export default connect(mapStateToProps, {
  getClaimNotificationsList,
  resetComponentStore,
})(ClaimNotificationsList);
