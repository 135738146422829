import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { BiPlusMedical } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import Errors from "Notifications/Errors";

import Spinner from "views/Spinner";
import {
  isEditor,
  getPatientFullAddress,
  allowNumericOnly,
} from "utils/helper";
import { RelationshipGlobal, BIPAStatusGlobal } from "constants/index";

import {
  createBI_PA,
  updateBI_PAByID,
  getBI_PADetailsByID,
  removeBI_PAErrors,
  getBI_PAList,
  resetComponentStore,
  getInsuranceList,
  getClientCPTCodesList,
  getClientPatientsList,
} from "actions/bi_paActions";
import { setErrors } from "actions/clientWorkspaceActions";

import WindowOpener from "views/WindowOpener";
import { validateForm } from "utils/validation";

const BenefitsInvestigationModal = ({
  modal,
  setModal,
  toggle,
  selectedBI_PA_ID,
  setSelectedBI_PA_ID,
  getBI_PADetailsByID,
  currentBI_PA,
  loadingBI_PA,
  clientCode,
  loadingOnSubmit,
  loggedInUser,
  setErrors,
  createBI_PA,
  updateBI_PAByID,
  removeBI_PAErrors,
  getBI_PAList,
  resetComponentStore,
  insurancesList,
  getInsuranceList,
  CPT_CodesList,
  getClientCPTCodesList,
  getClientPatientsList,
  patientsList,
}) => {
  const reset = () => {
    setModal(false);
    setSelectedBI_PA_ID(null);
    setSelectedPatient(null);
    setSelectedInsurance(null);
    setDisabled(false);
    setFormData(initialFormData);
  };

  const initialFormData = {
    caseNumber: "",
    dateReceived: "",
    statusChangedAt: "",
    status: "Entered",
    patientName: "",
    patientDOB: "",
    patientAddress: "",
    insuranceCode: "",
    insuranceCompanyName: "",
    memberID: "",
    groupNumber: "",
    subscriberRelationship: "",
    subscriberName: "",
    subscriberDOB: "",
    service: "",
    DEXCode: "",
    CPT_Codes: [
      {
        CPT_Code: "",
        price: "",
        description: "",
      },
    ],
    npi: "",
    physician: "",
    covered: false,
    deductible: "",
    inNetwork: false,
    outOfPocketMax: "",
    inNetworkCoins: "",
    priorAuthRequired: false,
    authConfirmation: "",
    OONCoins: "",
    OONDeductible: "",
    OONCoinsAfterDeductible: "",
    OONOutOfPocketMax: "",
    deductibleMet: "",
    deductibleBalance: "",
    financialAssistance: false,
    patientResponsibility: "",
    finAssAmount: "",
    recommendation: "",
  };

  const [isDisabled, setDisabled] = React.useState(false);
  const toggleEdit = (e) => {
    e.preventDefault();
    setDisabled(!isDisabled);
  };

  const [formData, setFormData] = React.useState(initialFormData);
  const [selectedInsurance, setSelectedInsurance] = React.useState(null);
  const [selectedPatient, setSelectedPatient] = React.useState(null);
  const [patientSearchKeyword, setPatientSearchKeyword] = React.useState("");
  const handleEmployeeSearch = (newValue) => {
    if (!newValue) return;
    setPatientSearchKeyword(newValue);
  };

  const handleSelect = (field) => (selectedOption) => {
    switch (field) {
      case "insuranceCode":
        setSelectedInsurance(selectedOption);
        setFormData({
          ...formData,
          [field]: selectedOption.value,
          insuranceCompanyName: selectedOption.companyName,
        });
        break;
      case "patientName":
        setSelectedPatient(selectedOption);
        setFormData({
          ...formData,
          patientId: selectedOption.value,
          patientName: selectedOption.patientName,
          patientDOB: moment(selectedOption.patientDOB).format("MM/DD/YYYY"),
          patientAddress: selectedOption.patientAddress,
          insuranceCode: selectedOption.insuranceCode,
          insuranceCompanyName: selectedOption.insuranceCompanyName,
          memberID: selectedOption.memberID,
          groupNumber: selectedOption.groupNumber,
          subscriberRelationship: selectedOption.subscriberRelationship,
          subscriberName: selectedOption.subscriberName,
          subscriberDOB: selectedOption.subscriberDOB
            ? moment(selectedOption.subscriberDOB).format("MM/DD/YYYY")
            : "",
        });

        setSelectedInsurance({
          value: selectedOption.insuranceCode,
          label: `(${selectedOption.insuranceCode}) ${selectedOption.insuranceCompanyName}`,
          companyName: selectedOption.insuranceCompanyName,
        });
        break;
      case "status":
        setFormData({
          ...formData,
          [field]: selectedOption.value,
        });
        break;
      default:
        setFormData({
          ...formData,
          [field]: selectedOption.value,
        });
        break;
    }
  };

  const handleSelect2 = (field, index) => (selectedOption) => {
    const CPT_CodesCopy = CPT_Codes.map((data, i) => {
      const data2 = { ...data };
      if (i === index) {
        data2[field] = selectedOption.value;

        const filtered = CPT_CodesList.find(
          (cpt) => cpt.cpt_code === selectedOption.value
        );

        data2["price"] = filtered.fee;
        data2["description"] = filtered.description;
      }

      return data2;
    });

    setFormData({
      ...formData,
      CPT_Codes: CPT_CodesCopy,
    });
  };

  const {
    caseNumber,
    dateReceived,
    statusChangedAt,
    status,
    patientDOB,
    patientAddress,
    insuranceCompanyName,
    memberID,
    groupNumber,
    subscriberRelationship,
    subscriberName,
    subscriberDOB,
    service,
    DEXCode,
    CPT_Codes,
    npi,
    physician,
    covered,
    deductible,
    outOfPocketMax,
    inNetwork,
    inNetworkCoins,
    priorAuthRequired,
    authConfirmation,
    OONCoins,
    OONDeductible,
    OONCoinsAfterDeductible,
    OONOutOfPocketMax,
    deductibleMet,
    deductibleBalance,
    financialAssistance,
    patientResponsibility,
    finAssAmount,
    recommendation,
  } = formData;

  React.useEffect(() => {
    setFormData(initialFormData);
    removeBI_PAErrors();

    if (!selectedBI_PA_ID || !clientCode) return;

    setDisabled(true);
    getBI_PADetailsByID(selectedBI_PA_ID, clientCode);
  }, [selectedBI_PA_ID]);

  React.useEffect(() => {
    if (!modal) return;

    getInsuranceList(clientCode);
    getClientCPTCodesList(clientCode);
    getClientPatientsList(clientCode);
  }, [modal]);

  React.useEffect(() => {
    if (!currentBI_PA || !modal) return;

    const {
      caseNumber,
      dateReceived,
      statusChangedAt,
      status,
      patientId,
      patientName,
      patientDOB,
      patientAddress,
      insuranceCode,
      insuranceCompanyName,
      memberID,
      groupNumber,
      subscriberRelationship,
      subscriberName,
      subscriberDOB,
      service,
      DEXCode,
      CPT_Codes,
      npi,
      physician,
      covered,
      deductible,
      outOfPocketMax,
      inNetwork,
      inNetworkCoins,
      priorAuthRequired,
      authConfirmation,
      OONCoins,
      OONDeductible,
      OONCoinsAfterDeductible,
      OONOutOfPocketMax,
      deductibleMet,
      deductibleBalance,
      financialAssistance,
      patientResponsibility,
      finAssAmount,
      recommendation,
    } = currentBI_PA;

    const formData = {
      caseNumber,
      dateReceived: moment(dateReceived).format("MM/DD/YYYY"),
      status,
      statusChangedAt: statusChangedAt
        ? moment(statusChangedAt).format("YYYY-MM-DD hh:mm a")
        : "",
      patientName,
      patientDOB: moment(patientDOB).format("MM/DD/YYYY"),
      patientAddress,
      insuranceCode,
      insuranceCompanyName,
      memberID,
      groupNumber,
      subscriberRelationship,
      subscriberName,
      subscriberDOB: subscriberDOB
        ? moment(subscriberDOB).format("MM/DD/YYYY")
        : "",
      service,
      CPT_Codes,
      DEXCode,
      npi,
      physician,
      covered,
      deductible,
      outOfPocketMax,
      inNetwork,
      inNetworkCoins,
      priorAuthRequired,
      authConfirmation,
      OONCoins,
      OONDeductible,
      OONCoinsAfterDeductible,
      OONOutOfPocketMax,
      deductibleMet,
      deductibleBalance,
      financialAssistance,
      patientResponsibility,
      finAssAmount,
      recommendation,
    };
    setFormData((form) => ({ ...form, ...formData }));

    if (patientId) {
      setSelectedPatient({
        value: patientId,
        label: `(${patientId}) ${patientName}`,
      });
    }

    if (insuranceCode) {
      setSelectedInsurance({
        value: insuranceCode,
        label: insuranceCode,
      });
    }
  }, [currentBI_PA, selectedBI_PA_ID]);

  const onChange = (e) => {
    if (!e.target) return;

    switch (e.target.name) {
      case "covered":
      case "inNetwork":
      case "priorAuthRequired":
      case "financialAssistance":
        setFormData({
          ...formData,
          [e.target.name]: e.target.value === "1" ? true : false,
        });
        break;

      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
        break;
    }
  };

  const onChange2 = (e, index) => {
    if (!e.target) return;
    const CPT_CodesDatCopy = CPT_Codes.map((data, i) => {
      const data2 = { ...data };
      if (i === index) {
        data2[e.target.name] = e.target.value;
      }

      return data2;
    });

    setFormData({
      ...formData,
      CPT_Codes: CPT_CodesDatCopy,
    });
  };

  const onSelectDate = (e, date_picker, field_name) => {
    const { startDate } = date_picker;
    setFormData((form) => ({
      ...form,
      [field_name]: moment(startDate).format("MM/DD/YYYY"),
    }));
  };

  const onClickCancel = (e) => {
    e.preventDefault();
    reset();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeBI_PAErrors();

    let validationRules = [
      {
        param: "dateReceived",
        msg: "Date Received is required.",
      },
      {
        param: "status",
        msg: "Status is required.",
      },
      {
        param: "patientName",
        msg: "Patient is required.",
      },
      {
        param: "insuranceCode",
        msg: "Insurance is required.",
      },
      {
        param: "subscriberRelationship",
        msg: "Subscriber relationship is required.",
      },
      {
        param: "memberID",
        msg: "Member ID is required.",
      },
      {
        param: "service",
        msg: "Service is required.",
      },
      {
        param: "npi",
        msg: "NPI is required.",
      },
    ];

    if (subscriberRelationship && subscriberRelationship !== "SELF") {
      validationRules = [
        ...validationRules,
        {
          param: "subscriberName",
          msg: "Subscriber Name is required.",
        },
        {
          param: "subscriberDOB",
          msg: "Subscriber DOB is required.",
        },
      ];
    }

    let errors = validateForm(formData, validationRules);
    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};
    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    if (selectedBI_PA_ID) {
      updateBI_PAByID(submitData, clientCode, selectedBI_PA_ID).then((res) => {
        if (res.status) {
          reset();
        }
      });
    } else {
      createBI_PA(submitData, clientCode).then((res) => {
        if (res.status) {
          reset();
          getBI_PAList({}, clientCode);
        }
      });
    }
  };

  const onClickRemove = (index) => {
    setFormData({
      ...formData,
      CPT_Codes: CPT_Codes.filter((each, i) => i !== index),
    });
  };
  const onClickHadle = () => {
    setFormData({
      ...formData,
      CPT_Codes: [
        ...CPT_Codes,
        {
          CPT_Code: "",
          price: "",
          description: "",
        },
      ],
    });
  };

  const getSelctedCPT_Code = (code) => {
    const filtered = CPT_CodesList.find((b) => b.cpt_code === code);

    if (!filtered) return null;

    return {
      value: filtered.cpt_code,
      label: filtered.cpt_code,
    };
  };

  const AdminURL = window.location.origin;
  const registrationURL = `${AdminURL}/patient-registration-form/${clientCode}`;

  const onWindowPopupClose = (popupResponse) => {
    // reload patients list

    getClientPatientsList(clientCode).then((res) => {
      if (popupResponse && popupResponse.patientId) {
        let selectedPat = res.response.find(
          (item) => item._id === popupResponse.patientId
        );

        if (!selectedPat) return;
        setSelectedPatient({
          label: `(${selectedPat.patientId}) ${selectedPat.patientFirstName} ${selectedPat.patientLastName}`,
          value: selectedPat.patientId,
        });
        setFormData((form) => ({
          ...form,
          patientId: selectedPat.patientId,
          patientName: `${selectedPat.patientFirstName} ${selectedPat.patientLastName}`,
          patientDOB: moment(selectedPat.patientDOB).format("MM/DD/YYYY"),
          patientAddress: getPatientFullAddress(selectedPat),
          insuranceCode: selectedPat.insuranceCode,
          insuranceCompanyName: selectedPat.insuranceCompanyName,
          memberID: selectedPat.memberID,
          groupNumber: selectedPat.groupNumber,
          subscriberRelationship: selectedPat.subscriberRelationship,
          subscriberName: selectedPat.subscriberName,
          subscriberDOB: selectedPat.subscriberDOB
            ? moment(selectedPat.subscriberDOB).format("MM/DD/YYYY")
            : "",
        }));
      }
    });
  };

  return (
    <Modal
      show={modal}
      onHide={reset}
      size="xl"
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
    >
      {loadingBI_PA && selectedBI_PA_ID ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Modal.Header toggle={toggle} closeButton>
            <h4>{selectedBI_PA_ID ? "Edit" : "Create"} BI/PA</h4>

            {selectedBI_PA_ID ? (
              <Button variant="link" size="sm" onClick={toggleEdit}>
                {isDisabled ? (
                  <span>
                    <MdEdit title="Click to Edit" size={20} />
                  </span>
                ) : (
                  <span>
                    <FaRegEye title="View mode" size={20} />
                  </span>
                )}
              </Button>
            ) : null}
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={(e) => onSubmit(e)}>
              <Row>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Case Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="caseNumber"
                      maxLength="20"
                      value={selectedBI_PA_ID ? caseNumber : "Auto Assigned"}
                      onChange={(e) => onChange(e)}
                      disabled
                    />

                    <Errors current_key="caseNumber" key="caseNumber" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Date Received</Form.Label>

                    {isDisabled ? (
                      <Form.Control
                        type="text"
                        value={dateReceived ? dateReceived : "Not available"}
                        disabled={isDisabled}
                      />
                    ) : (
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          showDropdowns: true,
                          autoUpdateInput: false,
                        }}
                        onApply={(e, picker) =>
                          onSelectDate(e, picker, "dateReceived")
                        }
                      >
                        <input
                          className="form-control py-2 bg-white border-right-0 border-custom"
                          type="text"
                          name="dateReceived"
                          readOnly
                          defaultValue=""
                          value={dateReceived}
                          placeholder="Select Date"
                          disabled={isDisabled}
                        />
                      </DateRangePicker>
                    )}

                    <Errors current_key="dateReceived" key="dateReceived" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Status</Form.Label>
                    <Select
                      id="status"
                      name="status"
                      options={BIPAStatusGlobal}
                      value={BIPAStatusGlobal.find((b) => b.value === status)}
                      onChange={handleSelect("status")}
                      isDisabled={isDisabled}
                    />

                    <Errors current_key="status" key="status" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  {selectedBI_PA_ID ? (
                    <Form.Group className="form-group">
                      <Form.Label>Status Changed At</Form.Label>
                      <Form.Control
                        type="text"
                        name="statusChangedAt"
                        value={statusChangedAt}
                        disabled
                      />
                    </Form.Group>
                  ) : null}
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group ">
                    <Form.Label className="d-flex justify-content-between">
                      Patient Name
                      <span className="text-primary">
                        {!isDisabled ? (
                          <WindowOpener
                            title="It will open the patient registration form in a new window."
                            url={registrationURL}
                            reloadOnClose={false}
                            bridge={{
                              patientName: patientSearchKeyword,
                            }}
                            onWindowPopupClose={onWindowPopupClose}
                          >
                            Create
                          </WindowOpener>
                        ) : null}
                      </span>
                    </Form.Label>

                    <Select
                      id="patientName"
                      name="patientName"
                      options={patientsList.map((patient) => {
                        const ptInsurance =
                          patient.insurance && patient.insurance.length
                            ? patient.insurance[0]
                            : {};

                        return {
                          value: patient.patientId,
                          label: `(${patient.patientId}) ${patient?.patientFirstName} ${patient?.patientLastName}`,
                          patientName: `${patient?.patientFirstName} ${patient?.patientLastName}`,
                          patientDOB: patient.patientDOB,
                          patientAddress: getPatientFullAddress(patient),
                          insuranceCode: ptInsurance.insuranceCompanyId,
                          insuranceCompanyName:
                            ptInsurance.insuranceCompanyName,
                          memberID: ptInsurance.subscriberMemberNumber,
                          groupNumber: ptInsurance.subscriberGroupNumber,
                          subscriberRelationship:
                            ptInsurance.subscriberRelationship,
                          subscriberName: `${
                            ptInsurance.subscriberFirstName ?? ""
                          } ${ptInsurance.subscriberLastName ?? ""}`,
                          subscriberDOB: ptInsurance.subscriberDob,
                        };
                      })}
                      value={selectedPatient}
                      onChange={handleSelect("patientName")}
                      onInputChange={handleEmployeeSearch}
                      isDisabled={isDisabled}
                    />

                    <Errors current_key="patientName" key="patientName" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Patient DOB</Form.Label>

                    {isDisabled ? (
                      <Form.Control
                        type="text"
                        value={patientDOB ? patientDOB : "Not available"}
                        disabled={isDisabled}
                      />
                    ) : (
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          showDropdowns: true,
                          autoUpdateInput: false,
                        }}
                        onApply={(e, picker) =>
                          onSelectDate(e, picker, "patientDOB")
                        }
                      >
                        <input
                          className="form-control py-2 bg-white border-right-0 border-custom"
                          type="text"
                          name="patientDOB"
                          readOnly
                          defaultValue=""
                          value={patientDOB}
                          placeholder="Select Date"
                        />
                      </DateRangePicker>
                    )}

                    <Errors current_key="patientDOB" key="patientDOB" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="patientAddress"
                      maxLength="100"
                      value={patientAddress}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />

                    <Errors current_key="patientAddress" key="patientAddress" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Insurance Code</Form.Label>
                    <Select
                      id="insuranceCode"
                      name="insuranceCode"
                      options={insurancesList.map((insurance) => ({
                        value: insurance.InternalInsCoID,
                        label: `(${insurance.InternalInsCoID}) ${insurance.InternalInsCoName}`,
                        companyName: insurance.InternalInsCoName,
                      }))}
                      value={selectedInsurance}
                      onChange={handleSelect("insuranceCode")}
                      isDisabled={isDisabled}
                    />

                    <Errors current_key="insuranceCode" key="insuranceCode" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Insurance Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="insuranceCompanyName"
                      value={insuranceCompanyName}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />

                    <Errors
                      current_key="insuranceCompanyName"
                      key="insuranceCompanyName"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Member ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="memberID"
                      maxLength="20"
                      value={memberID}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />
                    <Errors current_key="memberID" key="memberID" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Group Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="groupNumber"
                      maxLength="20"
                      value={groupNumber}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />

                    <Errors current_key="groupNumber" key="groupNumber" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Subscriber Relationship</Form.Label>
                    <Select
                      id="subscriberRelationship"
                      name="subscriberRelationship"
                      options={RelationshipGlobal}
                      value={RelationshipGlobal.find(
                        (b) => b.value === subscriberRelationship
                      )}
                      onChange={handleSelect("subscriberRelationship")}
                      isDisabled={isDisabled}
                    />

                    <Errors
                      current_key="subscriberRelationship"
                      key="subscriberRelationship"
                    />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Subscriber Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="subscriberName"
                      maxLength="80"
                      value={subscriberName}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />

                    <Errors current_key="subscriberName" key="subscriberName" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Subscriber DOB</Form.Label>

                    {isDisabled ? (
                      <Form.Control
                        type="text"
                        value={subscriberDOB ? subscriberDOB : "Not available"}
                        disabled={isDisabled}
                      />
                    ) : (
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          showDropdowns: true,
                          autoUpdateInput: false,
                        }}
                        onApply={(e, picker) =>
                          onSelectDate(e, picker, "subscriberDOB")
                        }
                      >
                        <input
                          className="form-control py-2 bg-white border-right-0 border-custom"
                          type="text"
                          name="subscriberDOB"
                          readOnly
                          defaultValue=""
                          value={subscriberDOB}
                          placeholder="Select Date"
                        />
                      </DateRangePicker>
                    )}

                    <Errors current_key="subscriberDOB" key="subscriberDOB" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Service</Form.Label>
                    <Form.Control
                      type="text"
                      name="service"
                      maxLength="80"
                      value={service}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />

                    <Errors current_key="service" key="service" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>DEX Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="DEXCode"
                      maxLength="50"
                      value={DEXCode}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />

                    <Errors current_key="DEXCode" key="DEXCode" />
                  </Form.Group>
                </Col>
              </Row>

              <Col sm="12">
                <h5>CPT Code(s)</h5>
              </Col>

              <Row>
                <Col md="12">
                  {CPT_Codes.map((eachCode, i) => (
                    <Row>
                      <Col lg="3" md="4" xs="6">
                        <Form.Group className="form-group">
                          <Form.Label>CPT Code</Form.Label>
                          <Select
                            id="CPT_Code"
                            name="CPT_Code"
                            options={CPT_CodesList.map((code) => ({
                              value: code.cpt_code,
                              label: code.cpt_code,
                              description: code.description,
                              fee: code.feee,
                            }))}
                            value={getSelctedCPT_Code(eachCode.CPT_Code)}
                            onChange={handleSelect2("CPT_Code", i)}
                            isDisabled={isDisabled}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg="3" md="4" xs="6">
                        <Form.Group className="form-group">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            type="text"
                            name="description"
                            maxLength="100"
                            value={eachCode.description}
                            onChange={(e) => onChange2(e, i)}
                            disabled={isDisabled}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg="3" md="4" xs="6">
                        <Form.Group className="form-group">
                          <Form.Label>Price</Form.Label>
                          <Form.Control
                            type="text"
                            name="price"
                            value={eachCode.price}
                            onChange={(e) => onChange2(e, i)}
                            onKeyPress={allowNumericOnly}
                            disabled={isDisabled}
                          />
                        </Form.Group>
                      </Col>

                      <Col lg="3" md="4" xs="6" className="align-self-center">
                        {CPT_Codes.length > 1 ? (
                          <Button
                            className="c-red mt-4"
                            variant="link"
                            size="sm"
                            onClick={(e) => onClickRemove(i)}
                            disabled={isDisabled}
                          >
                            Remove
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col md="12" className="mb-4">
                  <Button
                    className="p-1"
                    variant="link"
                    size="sm"
                    onClick={(e) => onClickHadle()}
                    disabled={isDisabled}
                  >
                    <BiPlusMedical /> Add New
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>NPI</Form.Label>
                    <Form.Control
                      type="text"
                      name="npi"
                      maxLength="10"
                      value={npi}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />

                    <Errors current_key="npi" key="npi" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Physician</Form.Label>
                    <Form.Control
                      type="text"
                      name="physician"
                      maxLength="80"
                      value={physician}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />

                    <Errors current_key="physician" key="physician" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <h5>In Network Benefits</h5>
                </Col>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Covered</Form.Label>
                    <Row>
                      <Col sm="3">
                        <Form.Check
                          type="radio"
                          name="covered"
                          label="Yes"
                          onChange={(e) => onChange(e)}
                          value="1"
                          checked={covered}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="radio"
                          name="covered"
                          label="No"
                          onChange={(e) => onChange(e)}
                          value="0"
                          checked={!covered}
                        />
                      </Col>
                    </Row>
                    <Errors current_key="covered" key="covered" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>In Network Deductible</Form.Label>
                    <Form.Control
                      type="text"
                      name="deductible"
                      maxLength="15"
                      value={deductible}
                      onChange={(e) => onChange(e)}
                      onKeyPress={allowNumericOnly}
                      disabled={isDisabled}
                    />

                    <Errors current_key="deductible" key="deductible" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>In Network Out of Pocket Max</Form.Label>
                    <Form.Control
                      type="text"
                      name="outOfPocketMax"
                      value={outOfPocketMax}
                      onChange={(e) => onChange(e)}
                      onKeyPress={allowNumericOnly}
                      disabled={isDisabled}
                    />

                    <Errors current_key="outOfPocketMax" key="outOfPocketMax" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>In Network</Form.Label>
                    <Row>
                      <Col sm="3">
                        <Form.Check
                          type="radio"
                          id="inNetwork_Yes"
                          name="inNetwork"
                          label="Yes"
                          onChange={(e) => onChange(e)}
                          value="1"
                          checked={inNetwork}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="radio"
                          id="inNetwork_No"
                          name="inNetwork"
                          label="No"
                          onChange={(e) => onChange(e)}
                          value="0"
                          checked={!inNetwork}
                        />
                      </Col>
                    </Row>
                    <Errors current_key="inNetwork" key="inNetwork" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>In Network Coinsurance (%)</Form.Label>
                    <Form.Control
                      type="text"
                      name="inNetworkCoins"
                      value={inNetworkCoins}
                      onChange={(e) => onChange(e)}
                      onKeyPress={allowNumericOnly}
                      disabled={isDisabled || !inNetwork}
                    />

                    <Errors current_key="inNetworkCoins" key="inNetworkCoins" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Prior Auth Required</Form.Label>
                    <Row>
                      <Col sm="3">
                        <Form.Check
                          type="radio"
                          name="priorAuthRequired"
                          label="Yes"
                          onChange={(e) => onChange(e)}
                          value="1"
                          checked={priorAuthRequired}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="radio"
                          name="priorAuthRequired"
                          label="No"
                          onChange={(e) => onChange(e)}
                          value="0"
                          checked={!priorAuthRequired}
                        />
                      </Col>
                    </Row>
                    <Errors
                      current_key="priorAuthRequired"
                      key="priorAuthRequired"
                    />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Prior Auth Confirmation</Form.Label>
                    <Form.Control
                      type="text"
                      name="authConfirmation"
                      maxLength="40"
                      value={authConfirmation}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />

                    <Errors
                      current_key="authConfirmation"
                      key="authConfirmation"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Deductible Met</Form.Label>
                    <Form.Control
                      type="text"
                      name="deductibleMet"
                      value={deductibleMet}
                      onChange={(e) => onChange(e)}
                      onKeyPress={allowNumericOnly}
                      disabled={isDisabled}
                    />

                    <Errors current_key="deductibleMet" key="deductibleMet" />
                  </Form.Group>
                </Col>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Deductible Balance</Form.Label>
                    <Form.Control
                      type="text"
                      name="deductibleBalance"
                      value={deductibleBalance}
                      onChange={(e) => onChange(e)}
                      onKeyPress={allowNumericOnly}
                      disabled={isDisabled}
                    />

                    <Errors
                      current_key="deductibleBalance"
                      key="deductibleBalance"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <h5>Out of Network Benefits</h5>
                </Col>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>OON Coinsurance (%)</Form.Label>
                    <Form.Control
                      type="text"
                      name="OONCoins"
                      value={OONCoins}
                      onChange={(e) => onChange(e)}
                      onKeyPress={allowNumericOnly}
                      disabled={isDisabled || inNetwork}
                    />

                    <Errors current_key="OONCoins" key="OONCoins" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>OON Deductible</Form.Label>
                    <Form.Control
                      type="text"
                      name="OONDeductible"
                      value={OONDeductible}
                      onChange={(e) => onChange(e)}
                      onKeyPress={allowNumericOnly}
                      disabled={isDisabled || inNetwork}
                    />

                    <Errors current_key="OONDeductible" key="OONDeductible" />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>OON Coinsurance After Deductible</Form.Label>
                    <Form.Control
                      type="text"
                      name="OONCoinsAfterDeductible"
                      value={OONCoinsAfterDeductible}
                      onChange={(e) => onChange(e)}
                      onKeyPress={allowNumericOnly}
                      disabled={isDisabled || inNetwork}
                    />

                    <Errors
                      current_key="OONCoinsAfterDeductible"
                      key="OONCoinsAfterDeductible"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>OON Out of Pocket Max</Form.Label>
                    <Form.Control
                      type="text"
                      name="OONOutOfPocketMax"
                      value={OONOutOfPocketMax}
                      onChange={(e) => onChange(e)}
                      onKeyPress={allowNumericOnly}
                      disabled={isDisabled || inNetwork}
                    />

                    <Errors
                      current_key="OONOutOfPocketMax"
                      key="OONOutOfPocketMax"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <h5>Results</h5>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Patient Responsibility</Form.Label>
                    <Form.Control
                      type="text"
                      name="patientResponsibility"
                      value={patientResponsibility}
                      onChange={(e) => onChange(e)}
                      onKeyPress={allowNumericOnly}
                      disabled={isDisabled}
                    />

                    <Errors
                      current_key="patientResponsibility"
                      key="patientResponsibility"
                    />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Financial Assistance</Form.Label>
                    <Row>
                      <Col sm="3">
                        <Form.Check
                          type="radio"
                          name="financialAssistance"
                          label="Yes"
                          onChange={(e) => onChange(e)}
                          value="1"
                          checked={financialAssistance}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="radio"
                          name="financialAssistance"
                          label="No"
                          onChange={(e) => onChange(e)}
                          value="0"
                          checked={!financialAssistance}
                        />
                      </Col>
                    </Row>
                    <Errors
                      current_key="financialAssistance"
                      key="financialAssistance"
                    />
                  </Form.Group>
                </Col>

                <Col lg="3" md="4" xs="6">
                  <Form.Group className="form-group">
                    <Form.Label>Financial Assistance Amount</Form.Label>
                    <Form.Control
                      type="text"
                      name="finAssAmount"
                      value={finAssAmount}
                      onChange={(e) => onChange(e)}
                      onKeyPress={allowNumericOnly}
                      disabled={isDisabled}
                    />

                    <Errors current_key="finAssAmount" key="finAssAmount" />
                  </Form.Group>
                </Col>

                <Col xs="9">
                  <Form.Group className="form-group">
                    <Form.Label>Recommendation</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="recommendation"
                      maxLength="1000"
                      value={recommendation}
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />

                    <Errors current_key="recommendation" key="recommendation" />
                  </Form.Group>
                </Col>
              </Row>

              {isEditor(loggedInUser) ? (
                <div className="float-end">
                  {selectedBI_PA_ID ? (
                    <React.Fragment>
                      {!isDisabled ? (
                        <>
                          <Button
                            className="m-2"
                            type="submit"
                            variant="primary"
                            disabled={loadingOnSubmit || isDisabled}
                          >
                            {loadingOnSubmit ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  aria-hidden="true"
                                ></span>
                                {` Loading... `}
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                          <Button
                            className="ml-2"
                            type="reset"
                            variant="danger"
                            onClick={toggleEdit}
                            disabled={loadingOnSubmit || isDisabled}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <Button
                          className="btn btn-outline-dark"
                          size="sm"
                          onClick={toggleEdit}
                        >
                          Click to Edit
                        </Button>
                      )}
                    </React.Fragment>
                  ) : (
                    <>
                      <Button
                        className="m-2"
                        type="submit"
                        variant="primary"
                        disabled={loadingOnSubmit || isDisabled}
                      >
                        {loadingOnSubmit ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            {` Loading... `}
                          </>
                        ) : (
                          <>Create</>
                        )}
                      </Button>
                      <Button
                        className="ml-2"
                        type="reset"
                        variant="danger"
                        onClick={onClickCancel}
                        disabled={loadingOnSubmit || isDisabled}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </div>
              ) : null}
            </Form>
          </Modal.Body>
        </React.Fragment>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currentBI_PA: state.bi_pa.currentBI_PA,
  loadingBI_PA: state.bi_pa.loadingBI_PA,
  loadingOnSubmit: state.bi_pa.loadingOnSubmit,
  insurancesList: state.bi_pa.insurancesList,
  CPT_CodesList: state.bi_pa.CPT_CodesList,
  patientsList: state.bi_pa.patientsList,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getBI_PADetailsByID,
  setErrors,
  createBI_PA,
  updateBI_PAByID,
  removeBI_PAErrors,
  getBI_PAList,
  resetComponentStore,
  getInsuranceList,
  getClientCPTCodesList,
  getClientPatientsList,
})(BenefitsInvestigationModal);
