import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Row, Modal, Form } from "react-bootstrap";
import { JsonView, darkStyles } from "react-json-view-lite";

import Spinner from "views/Spinner";

import { getLogById } from "actions/LogActions";

const ViewLogModal = ({
  modal,
  setModal,
  toggle,
  setectedLogId,
  setSetectedLogId,
  loadingLog,
  currentLog,
  getLogById,
}) => {
  const reset = () => {
    setModal(false);
    setSetectedLogId(null);
  };

  React.useEffect(() => {
    if (!setectedLogId) return;
    getLogById(setectedLogId);
  }, [setectedLogId]);

  const initialData = {};
  const [logData, setLogData] = React.useState(initialData);

  React.useEffect(() => {
    if (!currentLog) return;

    // const { trace, postedData } = currentLog;
    setLogData(currentLog);
  }, [currentLog]);

  return (
    <React.Fragment>
      <Modal
        show={modal}
        onHide={reset}
        size="xl"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
      >
        <Modal.Header toggle={toggle} closeButton>
          <h4>Log Details</h4>
        </Modal.Header>
        <Modal.Body>
          {loadingLog ? (
            <Spinner />
          ) : (
            <Form>
              <Row>
                <Col md="12">
                  <JsonView
                    data={logData}
                    shouldInitiallyExpand={() => true}
                    style={darkStyles}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

ViewLogModal.propTypes = {
  getLogById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loadingLog: state.log.loadingLog,
  currentLog: state.log.currentLog,
});

export default connect(mapStateToProps, {
  getLogById,
})(ViewLogModal);
