import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { FaRegEye } from "react-icons/fa";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";

import LogFilters from "./LogFilters";
import ViewLogModal from "./ViewLogModal";

import { getLogsList, resetComponentStore } from "actions/LogActions";

import { isAdmin } from "utils/helper";

const LogsList = ({
  logList: { data, count },
  getLogsList,
  loadingLogList,
  resetComponentStore,
  sortingParams,
  loggedInUser,
}) => {
  const [onlyOnce, setOnce] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [setectedLogId, setSetectedLogId] = React.useState(null);
  const toggle = () => setModal(!modal);

  const onClickHandler = (row_id) => {
    setSetectedLogId(row_id);
    setModal(true);
  };

  const { page, limit } = sortingParams;

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [logParams, setLogParams] = React.useState(initialSortingParams);

  const columns = [
    {
      name: "Time",
      cell: (row) => {
        const time = moment(row.createdAt).utc().format("MM/DD/YYYY HH:mm:ss");

        return <React.Fragment>{time} UTC</React.Fragment>;
      },
      sortable: true,
      sortField: "time",
      width: "15%",
    },
    {
      name: "Request URL",
      selector: (row) => row.originalUrl,
      sortable: false,
      width: "15%",
    },
    {
      name: "Status code returned",
      selector: (row) => row.statusCode,
      sortable: false,
      width: "15%",
    },
    {
      name: "Returned message",
      cell: (row) => {
        const response =
          typeof row.response === "object"
            ? typeof row.response.message === "string"
              ? JSON.stringify(row.response.message).replace(/"/g, "")
              : JSON.stringify(row.response.message)
            : row.response.message;

        return (
          <React.Fragment>
            <div className="mt-2 mb-2">{response}</div>
          </React.Fragment>
        );
      },
      sortable: false,
      width: "25%",
      wrap: true,
    },
    {
      name: "Client",
      selector: (row) => (row.client ? row.client.code : ""),
      sortable: false,
      width: "15%",
    },
    {
      name: "Actions",
      width: "calc(15%)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Button
            variant="primary"
            size="sm"
            onClick={(e) => onClickHandler(row._id)}
          >
            <FaRegEye />
          </Button>
        </div>
      ),
    },
  ];

  const navigate = useNavigate();
  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    if (!loggedInUser) return;

    if (!isAdmin(loggedInUser)) {
      navigate("/admin/dashboard");
      return;
    }

    getLogsList(logParams);
  }, [getLogsList, logParams, resetComponentStore, loggedInUser]);

  const onFilterChange = (newParams) => {
    setLogParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="Logs" crumbs={[{ name: "Logs" }]} />

      <ViewLogModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        setectedLogId={setectedLogId}
        setSetectedLogId={setSetectedLogId}
      />

      <Card>
        <Card.Body>
          <div className="table-filter-section">
            <Row>
              <Col md="2">
                <LogFilters
                  filter="createdAt"
                  type="date-range-picker"
                  filterType="Date"
                  filterName="Time"
                  filterParams={logParams}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={logParams}
            setParams={setLogParams}
            pagination
            responsive
            striped={true}
            // selectableRows
            progressPending={loadingLogList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

LogsList.propTypes = { getLogsList: PropTypes.func.isRequired };

const mapStateToProps = (state) => ({
  logList: state.log.logList,
  loadingLogList: state.log.loadingLogList,
  sortingParams: state.log.sortingParams,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getLogsList,
  resetComponentStore,
})(LogsList);
