import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { FaRegEye } from "react-icons/fa";
import { BiPlusMedical, BiTrash } from "react-icons/bi";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";
import Filters from "./Filters";
import ICDCodeModal from "./ICDCodeModal";

import {
  getICDCodeList,
  resetComponentStore,
  deleteRecord,
} from "actions/icd_codeActions";

import { isAdmin } from "utils/helper";

const ICDCodesList = ({
  getICDCodeList,
  List: { data, count },
  loadingList,
  resetComponentStore,
  sortingParams,
  loggedInUser,
  deleteRecord,
}) => {
  const [onlyOnce, setOnce] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);
  const toggle = () => setModal(!modal);

  const { page, limit } = sortingParams;

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [params, setParams] = React.useState(initialSortingParams);

  const onClickHandler = (row_id) => {
    setSelectedID(row_id);
    setModal(true);
  };

  const actions = (
    <div className="page-actions">
      <Button
        color="primary"
        size="sm"
        onClick={(e) => {
          setModal(true);
        }}
      >
        <BiPlusMedical /> Create New ICD Code
      </Button>
    </div>
  );

  const columns = [
    {
      name: "ICD Code",
      selector: (row) => row.icd10_code,
      width: "40%",
    },
    {
      name: "Name",
      selector: (row) => `${row.name}`,
      sortable: true,
      sortField: "name",
      width: "45%",
    },
    {
      name: "Actions",
      width: "calc(15% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          {loggedInUser && isAdmin(loggedInUser) ? (
            <React.Fragment>
              <Button
                variant="primary"
                size="sm"
                onClick={(e) => onClickHandler(row._id)}
              >
                <FaRegEye />
              </Button>

              <Button
                className="ml-1"
                size="sm"
                title="Delete"
                type="button"
                variant="danger"
                onClick={(e) => {
                  if (
                    window.confirm(
                      `Are you sure you want to delete: ${row.name}?`
                    )
                  ) {
                    deleteRecord(row._id);
                  }
                }}
              >
                <BiTrash />
              </Button>
            </React.Fragment>
          ) : null}
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    getICDCodeList(params);
  }, [getICDCodeList, params, resetComponentStore]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              icd10_code: {
                value: searchText,
                type: "String",
              },
              name: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setParams(params);
  };

  const onFilterChange = (newParams) => {
    setParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <ICDCodeModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        selectedID={selectedID}
        setSelectedID={setSelectedID}
      />

      <AppBreadcrumb pageTitle="ICD Codes" crumbs={[{ name: "ICD Codes" }]} />
      <Card>
        <Card.Body>
          <div className="table-filter-section">
            <Row>
              {actions}
              <Col md="4">
                <Filters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterName="Search"
                  filterParams={params}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={params}
            setParams={setParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

ICDCodesList.propTypes = {
  getICDCodeList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  List: state.icd_code.List,
  loadingList: state.icd_code.loadingList,
  sortingParams: state.icd_code.sortingParams,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getICDCodeList,
  resetComponentStore,
  deleteRecord,
})(ICDCodesList);
