import { combineReducers } from "redux";

import errors from "./errors";
import signup from "./signupReducer";
import alert from "./alert";
import auth from "./auth";
import user from "./user";
import client from "./client";
import clientWorkspace from "./clientWorkspace";
import appToken from "./appTokenReducer";
import profile from "./profileReducer";
import dashboard from "./dashboardReducer";
import patient from "./patient";
import claim from "./claim";
import cpt_code from "./cpt_codeReducer";
import partner from "./partnerReducer";
import insurance from "./insuranceReducer";
import log from "./logReducer";
import bi_pa from "./bi_paReducer";
import icd_code from "./icd_codeReducer";
import client_service from "./clientServiceReducer";

const rootReducer = combineReducers({
  errors,
  signup,
  alert,
  auth,
  user,
  client,
  appToken,
  profile,
  clientWorkspace,
  dashboard,
  patient,
  claim,
  cpt_code,
  partner,
  insurance,
  log,
  bi_pa,
  icd_code,
  client_service,
});

export default rootReducer;
