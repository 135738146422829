import React from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import Spinner from "views/Spinner";
import { capitalizeFirst, dividePascalCaseString } from "utils/helper";
import { otherDataValues } from "constants/index";

import {
  createClaim,
  getClaimDetailsById,
  updateClaimEncounterData,
  updateClaimProcedureData,
  updateClaimInsuranceData,
  updateClaimOtherData,
  removeClaimErrors,
  getClaimsList,
  loadEncounterToTebra,
} from "actions/claimActions";
import { setErrors } from "actions/clientWorkspaceActions";
import EncounterDataTab from "./EncounterDataTab";
import ProcedurDataTab from "./ProcedureDataTab";
import InsuranceDataTab from "./InsuranceDataTab";
import OtherDataTab from "./OtherDataTab";

const getOtherDataField = (OtherData) => {
  return OtherData.map((each) => {
    const devidedTitleWords = capitalizeFirst(dividePascalCaseString(each[0]));
    const otherDataObj = otherDataValues.find(
      (eachData) => eachData.label === each[1]
    );
    return {
      title: devidedTitleWords,
      id: each[0],
      type: each[1],
      value: each[2],
      inputType: otherDataObj ? otherDataObj.value : "text",
    };
  });
};

const ClientClaimModal = ({
  modal,
  setModal,
  toggle,
  selectedClaimID,
  setSelectedClaimID,
  getClaimDetailsById,
  currentClientClaim,
  loadingClaim,
  clientCode,
  loadingOnSubmit,
  updateClaimEncounterData,
  updateClaimProcedureData,
  updateClaimInsuranceData,
  updateClaimOtherData,
  loggedInUser,
  setErrors,
  createClaim,
  removeClaimErrors,
  getClaimsList,
  loadEncounterToTebra,
  patientsListAll,
  CPTCodesListAll,
  insurancesListAll,
}) => {
  const reset = () => {
    setModal(false);
    setSelectedClaimID(null);
    setDisabled(false);
    setActiveTab("encounterData");
  };

  const initianEncounterData = {
    orderNumber: "",
    createdAt: "",
    patientId: "",
    patientName: "",
    dateOfService: "",
    billType: "",
    totalAmount: "",
    currency: "",
    dateOfReport: "",
    referrerNPI: "",
    accessionNumber: "",
    testResult: "",
    order: "",
    trace: "",
  };

  const initialInsuranceData = {
    insuranceCompanyId: "",
  };

  const navigate = useNavigate();

  const [isDisabled, setDisabled] = React.useState(false);
  const toggleEdit = (e) => {
    e.preventDefault();
    setDisabled(!isDisabled);
  };

  const [encounterData, setEncounterData] =
    React.useState(initianEncounterData);
  const [procedureData, setProcedureData] = React.useState([]);
  const [insuranceData, setInsuranceData] =
    React.useState(initialInsuranceData);
  const [otherData, setOtherData] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState("encounterData");

  React.useEffect(() => {
    setEncounterData(initianEncounterData);
    setInsuranceData(initialInsuranceData);
    setProcedureData([]);
    setOtherData([]);
    removeClaimErrors();
    if (!selectedClaimID || !clientCode) return;
    setDisabled(true);
    getClaimDetailsById(selectedClaimID, clientCode);
  }, [selectedClaimID, modal]);

  React.useEffect(() => {
    if (!currentClientClaim) return;

    const {
      orderNumber,
      createdAt,
      patientId,
      patientName,
      dateOfService,
      billType,
      totalAmount,
      currency,
      dateOfReport,
      referrerNPI,
      accessionNumber,
      testResult,
      order,
      trace,
    } = currentClientClaim;

    // Prepare Encounter Data
    const encounterData = {
      orderNumber,
      createdAt: createdAt ? moment(createdAt).format("YYYY-MM-DD") : "",
      patientId,
      patientName,
      dateOfService: dateOfService
        ? moment(dateOfService).format("YYYY-MM-DD")
        : "",
      billType,
      totalAmount,
      currency,
      dateOfReport: dateOfReport
        ? moment(dateOfReport).format("YYYY-MM-DD")
        : "",
      referrerNPI,
      accessionNumber,
      testResult,
      order,
      trace,
    };
    setEncounterData((form) => ({ ...form, ...encounterData }));

    // Prepare Procedure Data
    const procedure = currentClientClaim.items ? currentClientClaim.items : [];
    const procedureDataCopy = procedure.length
      ? procedure.map((data, i) => {
          let data2 = { ...data };
          if (data2.icd10Code) {
            data2 = {
              ...data2,
              icd10Code: data2.icd10Code.join(","),
            };
          }

          return data2;
        })
      : [];
    setProcedureData(procedureDataCopy);

    // Prepare Insurance Data
    const { insuranceCompanyId } = currentClientClaim;
    const insuranceData = {
      insuranceCompanyId:
        insuranceCompanyId && insuranceCompanyId.length
          ? insuranceCompanyId.join(",")
          : "",
    };
    setInsuranceData(insuranceData);
    // Prepare Other Data
    const claimOtherData = currentClientClaim.otherData
      ? currentClientClaim.otherData
      : [];
    const otherDataFormalized = claimOtherData.length
      ? getOtherDataField(claimOtherData)
      : [];

    setOtherData(otherDataFormalized);
  }, [currentClientClaim]);

  const saveEncounterData = (e) => {
    e.preventDefault();
    updateClaimEncounterData(
      encounterData,
      clientCode,
      selectedClaimID,
      navigate
    ).then((res) => {
      if (res.status) setDisabled(true);
    });
  };

  const onSubmitProcedureData = (e) => {
    e.preventDefault();
    const submitData = procedureData;

    for (let i in procedureData) {
      if (procedureData[i].icd10Code) {
        submitData[i].icd10Code = Array.isArray(procedureData[i].icd10Code)
          ? procedureData[i].icd10Code
          : procedureData[i].icd10Code.split(",").map((item) => item.trim());
      }
    }
    updateClaimProcedureData(
      submitData,
      clientCode,
      selectedClaimID,
      navigate
    ).then((res) => {
      if (res.status) setDisabled(true);
    });
  };

  const onSubmitInsuranceData = (e) => {
    e.preventDefault();
    const { insuranceCompanyId } = insuranceData;

    const submitData = {
      insuranceCompanyId: insuranceCompanyId
        ? insuranceCompanyId.split(",")
        : [],
    };
    updateClaimInsuranceData(
      submitData,
      clientCode,
      selectedClaimID,
      navigate
    ).then((res) => {
      if (res.status) setDisabled(true);
    });
  };

  const saveOtherData = (e) => {
    e.preventDefault();

    const submitData = [];

    for (let each of otherData) {
      let data = [each.id, each.type.toUpperCase(), each.value];
      submitData.push(data);
    }

    updateClaimOtherData(
      submitData,
      clientCode,
      selectedClaimID,
      navigate
    ).then((res) => {
      if (res.status) setDisabled(true);
    });
  };

  const onClickCancel = (e) => {
    e.preventDefault();
    setDisabled(true);
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  const onSubmitClaim = () => {
    const { insuranceCompanyId } = insuranceData;
    const otherDataCp = [];

    for (let each of otherData) {
      let data = [each.id, each.type.toUpperCase(), each.value];
      otherDataCp.push(data);
    }

    const formData = {
      ...encounterData,
      insuranceCompanyId: insuranceCompanyId
        ? insuranceCompanyId.split(",")
        : [],
      items: procedureData,
      otherData: otherDataCp,
    };

    for (let i in procedureData) {
      if (procedureData[i].icd10Code) {
        formData.items[i].icd10Code = Array.isArray(procedureData[i].icd10Code)
          ? procedureData[i].icd10Code
          : procedureData[i].icd10Code.split(",").map((item) => item.trim());
      }
    }
    const submitData = {};
    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }
    createClaim(submitData, clientCode).then((res) => {
      if (res.status) {
        setModal(false);
        getClaimsList({}, clientCode);
      }
    });
  };

  const onSubmitOtherData = (e) => {
    e.preventDefault();
    if (selectedClaimID) {
      saveOtherData(e);
    } else {
      onSubmitClaim(e);
    }
  };
  return (
    <Modal
      show={modal}
      onHide={reset}
      size="xl"
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
    >
      <Modal.Header toggle={toggle} closeButton></Modal.Header>
      <Modal.Body>
        {loadingClaim && selectedClaimID ? (
          <Spinner />
        ) : (
          <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
            <Tab eventKey="encounterData" title="Encounter">
              <EncounterDataTab
                currentClientClaim={currentClientClaim}
                encounterData={encounterData}
                setEncounterData={setEncounterData}
                loadingOnSubmit={loadingOnSubmit}
                isDisabled={isDisabled}
                saveEncounterData={saveEncounterData}
                toggleEdit={toggleEdit}
                onClickCancel={onClickCancel}
                loggedInUser={loggedInUser}
                selectedClaimID={selectedClaimID}
                handleTabSelect={handleTabSelect}
                setErrors={setErrors}
                clientCode={clientCode}
                loadEncounterToTebra={loadEncounterToTebra}
                patientsListAll={patientsListAll}
              />
            </Tab>

            <Tab eventKey="procedureData" title="Procedure/Diagnosis">
              <ProcedurDataTab
                procedureData={procedureData}
                setProcedureData={setProcedureData}
                onSubmitProcedureData={onSubmitProcedureData}
                loadingOnSubmit={loadingOnSubmit}
                isDisabled={isDisabled}
                toggleEdit={toggleEdit}
                onClickCancel={onClickCancel}
                loggedInUser={loggedInUser}
                selectedClaimID={selectedClaimID}
                handleTabSelect={handleTabSelect}
                setErrors={setErrors}
                CPTCodesListAll={CPTCodesListAll}
              />
            </Tab>

            <Tab eventKey="insuranceData" title="Insurance">
              <InsuranceDataTab
                insuranceData={insuranceData}
                setInsuranceData={setInsuranceData}
                onSubmitInsuranceData={onSubmitInsuranceData}
                loadingOnSubmit={loadingOnSubmit}
                isDisabled={isDisabled}
                toggleEdit={toggleEdit}
                onClickCancel={onClickCancel}
                loggedInUser={loggedInUser}
                selectedClaimID={selectedClaimID}
                handleTabSelect={handleTabSelect}
                setErrors={setErrors}
                insurancesListAll={insurancesListAll}
              />
            </Tab>

            <Tab eventKey="otherData" title="Other Data">
              <OtherDataTab
                otherData={otherData}
                setOtherData={setOtherData}
                onSubmitOtherData={onSubmitOtherData}
                loadingOnSubmit={loadingOnSubmit}
                isDisabled={isDisabled}
                toggleEdit={toggleEdit}
                onClickCancel={onClickCancel}
                loggedInUser={loggedInUser}
                clientCode={clientCode}
              />
            </Tab>
          </Tabs>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currentClientClaim: state.claim.currentClaim,
  loadingClaim: state.claim.loadingClaim,
  loadingOnSubmit: state.claim.loadingOnSubmit,
  loggedInUser: state.auth.user,
  patientsListAll: state.patient.patientsListAll,
  CPTCodesListAll: state.cpt_code.CPTCodesListAll,
  insurancesListAll: state.insurance.ListAll,
});

export default connect(mapStateToProps, {
  getClaimDetailsById,
  updateClaimEncounterData,
  updateClaimProcedureData,
  updateClaimInsuranceData,
  updateClaimOtherData,
  setErrors,
  createClaim,
  removeClaimErrors,
  getClaimsList,
  loadEncounterToTebra,
})(ClientClaimModal);
