import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientsList: {
    page: 1,
    data: [],
    count: 0,
  },
  loadingClientList: true,
  loadingDashboardClientClaimsCount: true,
  currentClientClaimsCount: {
    todayPostDataCount: 0,
    lastDayPostDataCount: 0,
    thisWeekPostDataCount: 0,
    lastWeekPostDataCount: 0,
    thisMonthPostDataCount: 0,
    lastMonthPostDataCount: 0,
    thisQuarterPostDataCount: 0,
    lastQuarterPostDataCount: 0,
    thisYearPostDataCount: 0,
    lastYearPostDataCount: 0,
  },
  loadingDashboardClientPatientsCount: true,
  currentClientPatientsCount: {
    todayPostDataCount: 0,
    lastDayPostDataCount: 0,
    thisWeekPostDataCount: 0,
    lastWeekPostDataCount: 0,
    thisMonthPostDataCount: 0,
    lastMonthPostDataCount: 0,
    thisQuarterPostDataCount: 0,
    lastQuarterPostDataCount: 0,
    thisYearPostDataCount: 0,
    lastYearPostDataCount: 0,
  },
  loadingDashboardClaimsForWeek: true,
  currentClientClaimsForWeek: {
    currentWeekCount: 0,
    previousWeekCount: 0,
    weekDayCountData: {},
  },
  loadingDashboardPatientsForWeek: true,
  currentClientPatientsForWeek: {
    currentWeekCount: 0,
    previousWeekCount: 0,
    weekDayCountData: {},
  },
  loadingDashboardClaimsByMonth: true,
  currentClientClaimsByMonth: {
    currentMonthCount: 0,
    previousMonthCount: 0,
    countsByMonth: {},
  },
  loadingDashboardPatientsByMonth: true,
  currentClientPatientsByMonth: {
    currentMonthCount: 0,
    previousMonthCount: 0,
    countsByMonth: {},
  },
  loadingDashboardClaimsByYear: true,
  currentClientClaimsByYear: {
    currentYearCount: 0,
    previousYearCount: 0,
    countsByYear: {},
  },
  loadingDashboardPatientsByYear: true,
  currentClientPatientsByYear: {
    currentYearCount: 0,
    previousYearCount: 0,
    countsByYear: {},
  },
  loadingClaimsStatusByWeek: true,
  currentClientClaimsStatusByWeek: {},
  loadingPatientsStatusByWeek: true,
  currentClientPatientsStatusByWeek: {},
  loadingClaimsStatusByMonth: true,
  currentClientClaimsStatusByMonth: {},
  loadingPatientsStatusByMonth: true,
  currentClientPatientsStatusByMonth: {},
  loadingClaimsStatusByQuarter: true,
  currentClientClaimsStatusByQuarter: {},
  loadingPatientsStatusByQuarter: true,
  currentClientPatientsStatusByQuarter: {},
  loadingClaimsStatusByYear: true,
  dashboardClaimsStatusByYear: {},
  loadingPatientsStatusByYear: true,
  dashboardPatientsStatusByYear: {},
  loadingDashboardClaimsByQuarter: true,
  currentClientClaimsByQuarter: {
    currentQuarterCount: 0,
    previousQuarterCount: 0,
    countsByQuarter: {},
  },
  loadingDashboardPatientsByQuarter: true,
  currentClientPatientsByQuarter: {
    currentQuarterCount: 0,
    previousQuarterCount: 0,
    countsByQuarter: {},
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    loadingDashboardClientsList(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    dashboardClientListUpdated(state, action) {
      return {
        ...state,
        clientsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingClientList: false,
      };
    },
    dashboardError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingClientList: false,
      };
    },
    loadingDashboardClientClaimsCount(state) {
      return {
        ...state,
        loadingDashboardClientClaimsCount: true,
      };
    },
    dashboardClientClaimsCount(state, action) {
      return {
        ...state,
        currentClientClaimsCount: action.payload,
        loadingDashboardClientClaimsCount: false,
      };
    },
    loadingDashboardClientPatientsCount(state) {
      return {
        ...state,
        loadingDashboardClientPatientsCount: true,
      };
    },
    dashboardClientPatientsCount(state, action) {
      return {
        ...state,
        currentClientPatientsCount: action.payload,
        loadingDashboardClientPatientsCount: false,
      };
    },
    loadingDashboardClaimsForWeek(state) {
      return {
        ...state,
        loadingDashboardClaimsForWeek: true,
      };
    },
    dashboardClaimsForWeek(state, action) {
      return {
        ...state,
        currentClientClaimsForWeek: action.payload,
        loadingDashboardClaimsForWeek: false,
      };
    },
    loadingDashboardPatientsForWeek(state) {
      return {
        ...state,
        loadingDashboardPatientsForWeek: true,
      };
    },
    dashboardPatientsForWeek(state, action) {
      return {
        ...state,
        currentClientPatientsForWeek: action.payload,
        loadingDashboardPatientsForWeek: false,
      };
    },
    loadingDashboardClaimsByMonth(state) {
      return {
        ...state,
        loadingDashboardClaimsByMonth: true,
      };
    },
    dashboardClaimsByMonth(state, action) {
      return {
        ...state,
        currentClientClaimsByMonth: action.payload,
        loadingDashboardClaimsByMonth: false,
      };
    },
    loadingDashboardPatientsByMonth(state) {
      return {
        ...state,
        loadingDashboardPatientsByMonth: true,
      };
    },
    dashboardPatientsByMonth(state, action) {
      return {
        ...state,
        currentClientPatientsByMonth: action.payload,
        loadingDashboardPatientsByMonth: false,
      };
    },
    loadingDashboardClaimsByYear(state) {
      return {
        ...state,
        loadingDashboardClaimsByYear: true,
      };
    },
    dashboardClaimsByYear(state, action) {
      return {
        ...state,
        currentClientClaimsByYear: action.payload,
        loadingDashboardClaimsByYear: false,
      };
    },
    loadingDashboardPatientsByYear(state) {
      return {
        ...state,
        loadingDashboardPatientsByYear: true,
      };
    },
    dashboardPatientsByYear(state, action) {
      return {
        ...state,
        currentClientPatientsByYear: action.payload,
        loadingDashboardPatientsByYear: false,
      };
    },
    loadingClaimsStatusByWeek(state) {
      return {
        ...state,
        loadingClaimsStatusByWeek: true,
      };
    },
    dashboardClaimsStatusByWeek(state, action) {
      return {
        ...state,
        currentClientClaimsStatusByWeek: action.payload,
        loadingClaimsStatusByWeek: false,
      };
    },
    loadingPatientsStatusByWeek(state) {
      return {
        ...state,
        loadingPatientsStatusByWeek: true,
      };
    },
    dashboardPatientsStatusByWeek(state, action) {
      return {
        ...state,
        currentClientPatientsStatusByWeek: action.payload,
        loadingPatientsStatusByWeek: false,
      };
    },
    loadingClaimsStatusByMonth(state) {
      return {
        ...state,
        loadingClaimsStatusByMonth: true,
      };
    },
    dashboardClaimsStatusByMonth(state, action) {
      return {
        ...state,
        currentClientClaimsStatusByMonth: action.payload,
        loadingClaimsStatusByMonth: false,
      };
    },
    loadingPatientsStatusByMonth(state) {
      return {
        ...state,
        loadingPatientsStatusByMonth: true,
      };
    },
    dashboardPatientsStatusByMonth(state, action) {
      return {
        ...state,
        currentClientPatientsStatusByMonth: action.payload,
        loadingPatientsStatusByMonth: false,
      };
    },
    loadingClaimsStatusByQuarter(state) {
      return {
        ...state,
        loadingClaimsStatusByQuarter: true,
      };
    },
    dashboardClaimsStatusByQuarter(state, action) {
      return {
        ...state,
        currentClientClaimsStatusByQuarter: action.payload,
        loadingClaimsStatusByQuarter: false,
      };
    },
    loadingPatientsStatusByQuarter(state) {
      return {
        ...state,
        loadingPatientsStatusByQuarter: true,
      };
    },
    dashboardPatientsStatusByQuarter(state, action) {
      return {
        ...state,
        currentClientPatientsStatusByQuarter: action.payload,
        loadingPatientsStatusByQuarter: false,
      };
    },
    loadingClaimsStatusByYear(state) {
      return {
        ...state,
        loadingClaimsStatusByYear: true,
      };
    },
    dashboardClaimsStatusByYear(state, action) {
      return {
        ...state,
        currentClientClaimsStatusByYear: action.payload,
        loadingClaimsStatusByYear: false,
      };
    },
    loadingPatientsStatusByYear(state) {
      return {
        ...state,
        loadingPatientsStatusByYear: true,
      };
    },
    dashboardPatientsStatusByYear(state, action) {
      return {
        ...state,
        currentClientPatientsStatusByYear: action.payload,
        loadingPatientsStatusByYear: false,
      };
    },
    loadingDashboardClaimsByQuarter(state) {
      return {
        ...state,
        loadingDashboardClaimsByQuarter: true,
      };
    },
    dashboardClaimsByQuarter(state, action) {
      return {
        ...state,
        currentClientClaimsByQuarter: action.payload,
        loadingDashboardClaimsByQuarter: false,
      };
    },
    loadingDashboardPatientsByQuarter(state) {
      return {
        ...state,
        loadingDashboardPatientsByQuarter: true,
      };
    },
    dashboardPatientsByQuarter(state, action) {
      return {
        ...state,
        currentClientPatientsByQuarter: action.payload,
        loadingDashboardPatientsByQuarter: false,
      };
    },
  },
});

export const {
  loadingDashboardClientsList,
  dashboardClientListUpdated,
  dashboardError,
  loadingDashboardClientClaimsCount,
  dashboardClientClaimsCount,
  loadingDashboardClientPatientsCount,
  dashboardClientPatientsCount,
  loadingDashboardClaimsForWeek,
  dashboardClaimsForWeek,
  loadingDashboardPatientsForWeek,
  dashboardPatientsForWeek,
  loadingDashboardClaimsByMonth,
  dashboardClaimsByMonth,
  loadingDashboardPatientsByMonth,
  dashboardPatientsByMonth,
  loadingDashboardClaimsByYear,
  dashboardClaimsByYear,
  loadingDashboardPatientsByYear,
  dashboardPatientsByYear,
  loadingClaimsStatusByWeek,
  dashboardClaimsStatusByWeek,
  loadingPatientsStatusByWeek,
  dashboardPatientsStatusByWeek,
  loadingClaimsStatusByMonth,
  dashboardClaimsStatusByMonth,
  loadingPatientsStatusByMonth,
  dashboardPatientsStatusByMonth,
  loadingClaimsStatusByQuarter,
  dashboardClaimsStatusByQuarter,
  loadingPatientsStatusByQuarter,
  dashboardPatientsStatusByQuarter,
  loadingClaimsStatusByYear,
  dashboardClaimsStatusByYear,
  loadingPatientsStatusByYear,
  dashboardPatientsStatusByYear,
  loadingDashboardClaimsByQuarter,
  dashboardClaimsByQuarter,
  loadingDashboardPatientsByQuarter,
  dashboardPatientsByQuarter,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
