import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  CPTCodesList: {
    page: 1,
    data: [],
    count: 0,
  },
  loadingList: true,
  loadingRecord: false,
  currentRecord: null,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
  CPTCodesListAll: [],
};

const slice = createSlice({
  name: "CPT_Codes",
  initialState: initialState,
  reducers: {
    resetStore(state) {
      return {
        ...initialState,
      };
    },
    CPT_CodeError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingRecord: false,
        loadingList: false,
      };
    },
    recordDeleted(state, action) {
      const currentCount = state.CPTCodesList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.CPTCodesList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        CPTCodesList: {
          data: state.CPTCodesList.data.filter(
            (cpt_code) => cpt_code._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingList: false,
      };
    },
    listUpdated(state, action) {
      return {
        ...state,
        CPTCodesList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        // loadingRecord: true,
        loadingList: false,
      };
    },
    searchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingList: false,
      };
    },
    loadingOnSubmit(state) {
      return {
        ...state,
        loadingRecord: true,
      };
    },
    loadingList(state) {
      return {
        ...state,
        loadingList: true,
      };
    },
    listUpdatedAll(state, action) {
      return {
        ...state,
        CPTCodesListAll: action.payload,
      };
    },
    recordCreated(state, action) {
      const currentCount = state.CPTCodesList.count;
      return {
        ...state,
        CPTCodesList: {
          ...state.CPTCodesList,
          data: [action.payload, ...state.CPTCodesList.data],
          count: currentCount + 1,
        },
        sortingParams: initialState.sortingParams,
        loadingRecord: false,
      };
    },
    recordUpdated(state, action) {
      return {
        ...state,
        CPTCodesList: {
          ...state.CPTCodesList,
          data: state.CPTCodesList.data.map((cpt_code) =>
            cpt_code._id === action.payload._id ? action.payload : cpt_code
          ),
        },
        loadingRecord: false,
      };
    },
  },
});

export const {
  loadingList,
  searchParametersUpdate,
  listUpdated,
  loadingOnSubmit,
  CPT_CodeError,
  recordDeleted,
  resetStore,
  listUpdatedAll,
  recordCreated,
  recordUpdated,
} = slice.actions;
export default slice.reducer;
