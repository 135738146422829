import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";

import Errors from "Notifications/Errors";
import { isEditor } from "utils/helper";
import { validateForm } from "utils/validation";
import { RelationshipGlobal } from "constants/index";

const ResponsiblePartyDataTab = ({
  responsiblePartyData,
  setResponsiblePartyData,
  saveResponsiblePartyData,
  loadingOnSubmit,
  isDisabled,
  toggleEdit,
  onClickCancel,
  loggedInUser,
  selectedPatientID,
  handleTabSelect,
  setErrors,
  isRequiredResponsiblePartyData,
  removePatientErrors,
}) => {
  const {
    responsibleFirstName,
    responsibleLastName,
    responsibleDOB,
    responsibleEmail,
    responsiblePhoneNo,
    responsibleRelationship,
  } = responsiblePartyData;

  const onChange = (e) => {
    if (!e.target) return;
    setResponsiblePartyData({
      ...responsiblePartyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect2 = (field) => (selectedOption) => {
    setResponsiblePartyData({
      ...responsiblePartyData,
      [field]: selectedOption.value,
    });
  };

  const onClickNext = (e) => {
    e.preventDefault();
    removePatientErrors();
    let validationRules = [];

    if (isRequiredResponsiblePartyData) {
      validationRules.push(
        {
          param: "responsibleFirstName",
          msg: "Responsible first name is required.",
        },
        {
          param: "responsibleLastName",
          msg: "Responsible last name is required.",
        },
        {
          param: "responsibleRelationship",
          msg: "The responsible relationship should have any of these values SELF, SPOUSE, CHILD, OTHER.",
        },
        {
          param: "responsibleDOB",
          msg: "Responsible DOB is required.",
        },
        {
          param: "responsiblePhoneNo",
          msg: "Please provide responsible phone number.",
        }
      );
    }

    const errors = validateForm(responsiblePartyData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }
    handleTabSelect("otherData");
  };

  return (
    <Form onSubmit={(e) => saveResponsiblePartyData(e)}>
      <Row>
        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Responsible First Name</Form.Label>
            <Form.Control
              type="text"
              name="responsibleFirstName"
              value={responsibleFirstName}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors
              current_key="responsibleFirstName"
              key="responsibleFirstName"
            />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Responsible Last Name</Form.Label>
            <Form.Control
              type="text"
              name="responsibleLastName"
              value={responsibleLastName}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors
              current_key="responsibleLastName"
              key="responsibleLastName"
            />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Responsible Relationship</Form.Label>
            <Select
              id="responsibleRelationship"
              name="responsibleRelationship"
              options={RelationshipGlobal}
              value={RelationshipGlobal.find(
                (r) => r.value === responsibleRelationship
              )}
              onChange={handleSelect2("responsibleRelationship")}
              isDisabled={isDisabled}
            />

            <Errors
              current_key="responsibleRelationship"
              key="responsibleRelationship"
            />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Responsible DOB</Form.Label>
            <Form.Control
              type="date"
              name="responsibleDOB"
              value={responsibleDOB}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="responsibleDOB" key="responsibleDOB" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Responsible Phone No</Form.Label>
            <Form.Control
              type="number"
              name="responsiblePhoneNo"
              value={responsiblePhoneNo || ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="responsiblePhoneNo" key="responsiblePhoneNo" />
          </Form.Group>
        </Col>

        <Col xxl="2" lg="3" md="4" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Responsible Email</Form.Label>
            <Form.Control
              type="text"
              name="responsibleEmail"
              value={responsibleEmail}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />

            <Errors current_key="responsibleEmail" key="responsibleEmail" />
          </Form.Group>
        </Col>
      </Row>

      {isEditor(loggedInUser) ? (
        <div className="float-end">
          {selectedPatientID ? (
            !isDisabled ? (
              <>
                <Button
                  className="m-2"
                  type="submit"
                  variant="primary"
                  disabled={loadingOnSubmit || isDisabled}
                >
                  {loadingOnSubmit ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {` Loading... `}
                    </>
                  ) : (
                    <>Save</>
                  )}
                </Button>
                <Button
                  className="ml-2"
                  type="reset"
                  variant="danger"
                  onClick={onClickCancel}
                  disabled={loadingOnSubmit || isDisabled}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                className="btn btn-outline-dark"
                size="sm"
                onClick={toggleEdit}
              >
                Click to Edit
              </Button>
            )
          ) : (
            <div>
              <Button
                className="m-2"
                variant="light"
                onClick={(e) => handleTabSelect("insuranceData")}
              >
                Back
              </Button>

              <Button
                type="btn"
                variant="primary"
                onClick={(e) => onClickNext(e)}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      ) : null}
    </Form>
  );
};

export default ResponsiblePartyDataTab;
