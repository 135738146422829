import React from "react";
import { connect } from "react-redux";

import SelectedGraph from "../SelectedGraph";
import { getSelectedClientCountByMonth } from "actions/dashboardActions";

const PatientsMonthlyView = ({
  selectedClient,
  getSelectedClientCountByMonth,
  currentClientPatientsByMonth,
  loadingDashboardPatientsByMonth,
}) => {
  React.useEffect(() => {
    if (!selectedClient || !selectedClient.code) return;
    getSelectedClientCountByMonth(selectedClient.code, "patients");
  }, [getSelectedClientCountByMonth, selectedClient]);

  const initialMonthData = {
    currentMonthCount: 0,
    previousMonthCount: 0,
    countsByMonth: {
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
    },
  };

  const [patientsCountByMonth, setPatientsCountByMonth] =
    React.useState(initialMonthData);

  React.useEffect(() => {
    if (!currentClientPatientsByMonth) return;

    const { currentMonthCount, previousMonthCount, countsByMonth } =
      currentClientPatientsByMonth;

    const monthNameValuePairs = Object.entries(countsByMonth).map(
      ([name, count]) => ({
        name,
        count,
      })
    );

    const currentData = {
      currentMonthCount: currentMonthCount ? currentMonthCount : 0,
      previousMonthCount: previousMonthCount ? previousMonthCount : 0,
      countsByMonth:
        monthNameValuePairs && monthNameValuePairs.length
          ? monthNameValuePairs
          : patientsCountByMonth.countsByMonth,
    };

    setPatientsCountByMonth(currentData);
  }, [currentClientPatientsByMonth]);

  return (
    <SelectedGraph
      loading={loadingDashboardPatientsByMonth}
      data={patientsCountByMonth.countsByMonth}
      filterLabel="Month"
      currentCount={patientsCountByMonth.currentMonthCount}
      lastCount={patientsCountByMonth.previousMonthCount}
      legend="# Patients"
    />
  );
};

const mapStateToProps = (state) => ({
  currentClientPatientsByMonth: state.dashboard.currentClientPatientsByMonth,
  loadingDashboardPatientsByMonth:
    state.dashboard.loadingDashboardPatientsByMonth,
});

export default connect(mapStateToProps, {
  getSelectedClientCountByMonth,
})(PatientsMonthlyView);
