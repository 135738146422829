import React from "react";
import { connect } from "react-redux";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { getStatusCountThisWeek } from "actions/dashboardActions";
import { clientStatus } from "constants/index";
import { getClientStatusByValue } from "utils/helper";

const PatientsStatusThisWeek = ({
  selectedClient,
  getStatusCountThisWeek,
  loadingPatientsStatusByWeek,
  currentClientPatientsStatusByWeek,
  statusOptions,
}) => {
  React.useEffect(() => {
    if (!selectedClient || !selectedClient.code) return;
    getStatusCountThisWeek(selectedClient.code, "patients", statusOptions);
  }, [getStatusCountThisWeek, selectedClient]);

  const initialData = {};
  const [patientsCountByStatusWeek, setPatientsCountByStatusWeek] =
    React.useState(initialData);

  React.useEffect(() => {
    if (!currentClientPatientsStatusByWeek) return;

    const currentData = [];
    for (const key in currentClientPatientsStatusByWeek) {
      const weekData = currentClientPatientsStatusByWeek[key];
      const { name, statusCount } = weekData;
      const weekObject = { name };

      clientStatus.forEach((status) => {
        const matchingStatus = statusCount.length
          ? statusCount.find((item) => item._id === status.value)
          : 0;
        weekObject[status.value] = matchingStatus ? matchingStatus.count : 0;
      });

      if (weekObject.name) currentData.push(weekObject);
    }

    if (currentData.length) setPatientsCountByStatusWeek(currentData);
  }, [currentClientPatientsStatusByWeek]);

  return (
    <div className="text-center line-graph">
      <ResponsiveContainer width="100%" height="100%">
        {loadingPatientsStatusByWeek ? (
          <span>Loading...</span>
        ) : (
          <BarChart
            width={500}
            height={300}
            data={patientsCountByStatusWeek}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" allowDecimals={false} />
            <Tooltip cursor={{ fill: "transparent" }} />
            <Legend verticalAlign="top" height={36} />
            {statusOptions.length &&
              statusOptions.map((each, i) => (
                <Bar
                  key={i}
                  dataKey={each}
                  fill={
                    each === "READY"
                      ? "#42d29d"
                      : each === "ONHOLD"
                      ? "#fa6767"
                      : "#8573f5"
                  }
                  name={getClientStatusByValue(each)}
                  maxBarSize={25}
                />
              ))}
          </BarChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loadingPatientsStatusByWeek: state.dashboard.loadingPatientsStatusByWeek,
  currentClientPatientsStatusByWeek:
    state.dashboard.currentClientPatientsStatusByWeek,
});

export default connect(mapStateToProps, {
  getStatusCountThisWeek,
})(PatientsStatusThisWeek);
