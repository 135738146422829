import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { FaRegEye } from "react-icons/fa";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { BiPlusMedical } from "react-icons/bi";

import PiDataTable from "views/DataTable/PiDataTable";

import { getClaimsList, resetComponentStore } from "actions/claimActions";
import { getClientPatientsListAll } from "actions/patientActions";
import { getCPTCodesListAll } from "actions/cpt_codeActions";
import { getListWoPagination } from "actions/insuranceActions";

import ClientClaimFilters from "./ClientClaimFilters";
import ClientClaimModal from "./ClientClaimModal";

import { clientStatus } from "constants/index";
import { getClientStatusByValue, isEditor } from "utils/helper";

const ClientClaimsList = ({
  clientClaimsList: { data, count },
  getClaimsList,
  loadingClientClaimList,
  resetComponentStore,
  sortingParams,
  clientCode,
  loggedInUser,
  getClientPatientsListAll,
  getCPTCodesListAll,
  getListWoPagination,
}) => {
  const { client_id } = useParams();

  const [onlyOnce, setOnce] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [selectedClaimID, setSelectedClaimID] = React.useState(null);
  const toggle = () => setModal(!modal);
  const [searchDefaultValue, setSearchDefaultValue] = React.useState("");
  const [onlyOnce2, setOnce2] = React.useState(true);

  const { page, limit } = sortingParams;

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const getInitialSortingParams = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const defaultSearchValue = params.get("patient-id");
    if (!defaultSearchValue) return initialSortingParams;
    if (onlyOnce2) {
      setSearchDefaultValue(defaultSearchValue);
      setOnce2(false);
    }
    return {
      ...initialSortingParams,
      filters: ["search"],
      query: {
        ...sortingParams.query,
        search: {
          patientId: {
            value: defaultSearchValue,
            type: "String",
          },
        },
      },
    };
  };

  const [clientClaimParams, setClientClaimParams] = React.useState(
    getInitialSortingParams()
  );

  const onClickHandler = (row_id) => {
    setSelectedClaimID(row_id);
    setModal(true);
  };

  const columns = [
    {
      name: "Order Number",
      selector: (row) => row.orderNumber,
      sortable: true,
      sortField: "orderNumber",
      width: "10%",
    },
    {
      name: "Patient Id",
      selector: (row) => (
        <Link
          to={`/admin/clients/${client_id}/workspace/patients?patient-id=${row.patientId}`}
        >
          <div>{row.patientId}</div>
        </Link>
      ),
      sortable: true,
      sortField: "patientId",
      width: "15%",
    },
    {
      name: "Date of Service",
      selector: (row) => row.dateOfService,
      sortable: true,
      sortField: "dateOfService",
      width: "15%",
    },
    {
      name: "Bill type",
      selector: (row) => row.billType,
      sortable: true,
      sortField: "billType",
      width: "15%",
    },
    {
      name: "Date Posted",
      selector: (row) =>
        row.createdAt ? moment(row.createdAt).format("MM/DD/YYYY") : "",
      sortable: true,
      sortField: "createdAt",
      width: "20%",
    },
    {
      name: "Status",
      selector: (row) =>
        row.trace && row.trace.bsys
          ? getClientStatusByValue(row.trace.bsys.status)
          : "",
      width: "10%",
    },
    {
      name: "Actions",
      width: "calc(15% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Button
            variant="primary"
            size="sm"
            onClick={(e) => onClickHandler(row._id)}
          >
            <FaRegEye />
          </Button>
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }
    if (!clientCode) return;
    getClaimsList(clientClaimParams, clientCode);
    getClientPatientsListAll(clientCode);
    getCPTCodesListAll();
    getListWoPagination();
  }, [getClaimsList, clientClaimParams, resetComponentStore]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };
    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              orderNumber: {
                value: searchText,
                type: "String",
              },
              patientId: {
                value: searchText,
                type: "String",
              },
              billType: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setClientClaimParams(params);
  };

  const onFilterChange = (newParams, optionValue) => {
    setClientClaimParams((params) => ({ ...params, ...newParams }));
  };

  const actions = (
    <div className="page-actions">
      {isEditor(loggedInUser) ? (
        <Button
          color="primary"
          size="sm"
          onClick={(e) => {
            setModal(true);
          }}
        >
          <BiPlusMedical /> Create New Encounter
        </Button>
      ) : null}
    </div>
  );

  return (
    <React.Fragment>
      <ClientClaimModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        selectedClaimID={selectedClaimID}
        setSelectedClaimID={setSelectedClaimID}
        clientCode={clientCode}
      />
      <Card>
        <Card.Body>
          <Row className="table-filter-section">
            {actions}
            <Col md="2">
              <ClientClaimFilters
                type="text"
                onSearch={handleTableChange}
                filterType="String"
                filterName="Search"
                filterParams={clientClaimParams}
                onFilterChange={onFilterChange}
                searchDefaultValue={searchDefaultValue}
              />
            </Col>

            <Col md="2">
              <ClientClaimFilters
                filter="trace.bsys.status"
                type="select"
                filterType="String"
                filterName="Status"
                filterParams={clientClaimParams}
                onFilterChange={onFilterChange}
                selectOptions={[
                  {
                    label: "All",
                    value: "all",
                  },
                  ...clientStatus,
                ]}
              />
            </Col>

            <Col md="2">
              <ClientClaimFilters
                filter="createdAt"
                type="date-range-picker"
                filterType="Date"
                filterName="Date Posted"
                filterParams={clientClaimParams}
                onFilterChange={onFilterChange}
              />
            </Col>
          </Row>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientClaimParams}
            setParams={setClientClaimParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingClientClaimList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

ClientClaimsList.propTypes = {
  getClaimsList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  clientClaimsList: state.claim.claimsList,
  loadingClientClaimList: state.claim.loadingClaimList,
  sortingParams: state.claim.sortingParams,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getClaimsList,
  resetComponentStore,
  getClientPatientsListAll,
  getCPTCodesListAll,
  getListWoPagination,
})(ClientClaimsList);
