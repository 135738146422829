import React from "react";
import { Link } from "react-router-dom";

import Breadcrumb from "react-bootstrap/Breadcrumb";
import Dropdown from "react-bootstrap/Dropdown";

import { capitalizeFirst } from "utils/helper";

const AppBreadcrumb = ({
  pageTitle,
  crumbs,
  selectedClient,
  setSelectedClient,
  clientsList,
}) => {
  const handleSelectClient = (eventKey) => {
    const currentClient = clientsList.data.find(
      (item) => item._id === eventKey
    );
    setSelectedClient(currentClient);
  };

  return (
    <div className="bread-crumb d-flex">
      <h4>{pageTitle}</h4>

      {crumbs ? (
        <Breadcrumb>
          <Breadcrumb.Item linkProps={{ to: "/admin/dashboard" }} linkAs={Link}>
            Dashboard
          </Breadcrumb.Item>
          {crumbs.map((item, i) => (
            <React.Fragment key={i}>
              {item.path ? (
                <Breadcrumb.Item linkProps={{ to: item.path }} linkAs={Link}>
                  {capitalizeFirst(item.name)}
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item active>
                  {capitalizeFirst(item.name)}
                </Breadcrumb.Item>
              )}
            </React.Fragment>
          ))}
        </Breadcrumb>
      ) : undefined}

      {!crumbs &&
      pageTitle === "Dashboard" &&
      clientsList &&
      clientsList.data.length ? (
        <div className="mb-3">
          <Dropdown onSelect={handleSelectClient}>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              {selectedClient.name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {clientsList.data.map((item, i) => (
                <Dropdown.Item key={i} eventKey={item._id}>
                  {item.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ) : null}
    </div>
  );
};

export default AppBreadcrumb;
