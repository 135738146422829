import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  resetClientWorkspace,
  clientClaimError,
  clientClaimDetailsById,
  patientNotificationError,
  patientNotificationsListUpdated,
  patientNotificationsSearchParametersUpdated,
  loadingPatientNotificationsList,
  clientPatientDetailsById,
  loadingClaimNotificationsList,
  claimNotificationDetailsById,
  claimNotificationsSearchParametersUpdated,
  claimNotificationsListUpdated,
  claimNotificationError,
  clientClaimDetailsLoading,
  clientPatientDetailsLoading,
  loadingClaimNotificationDetails,
} from "reducers/clientWorkspace";

export const getClientClaimDetailsById =
  (claim_id, client_code) => async (dispatch) => {
    dispatch(removeErrors());
    try {
      const config = {
        "Content-Type": "application/json",
      };

      dispatch(clientClaimDetailsLoading());

      const res = await axios.get(
        `/api/admin/client-workspace/${client_code}/claims/${claim_id}`,
        config
      );

      dispatch(clientClaimDetailsById(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            clientClaimError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getPatientNotificationsList =
  (patientNotificationParams, client_code) => async (dispatch) => {
    dispatch(removeErrors());
    try {
      const config = {
        "Content-Type": "application/json",
      };

      const query = patientNotificationParams.query
        ? patientNotificationParams.query
        : "";
      patientNotificationParams.query = query;
      config.params = patientNotificationParams;

      dispatch(loadingPatientNotificationsList());
      const res = await axios.get(
        `/api/admin/client-workspace/${client_code}/patient-notifications/list`,
        config
      );

      dispatch(
        patientNotificationsSearchParametersUpdated(patientNotificationParams)
      );

      dispatch(patientNotificationsListUpdated(res.data.response[0]));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            patientNotificationError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getClaimNotificationsList =
  (claimNotificationParams, client_code) => async (dispatch) => {
    dispatch(removeErrors());
    try {
      const config = {
        "Content-Type": "application/json",
      };

      const query = claimNotificationParams.query
        ? claimNotificationParams.query
        : "";
      claimNotificationParams.query = query;
      config.params = claimNotificationParams;

      dispatch(loadingClaimNotificationsList());
      const res = await axios.get(
        `/api/admin/client-workspace/${client_code}/claim-notifications/list`,
        config
      );

      dispatch(
        claimNotificationsSearchParametersUpdated(claimNotificationParams)
      );

      dispatch(claimNotificationsListUpdated(res.data.response[0]));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            claimNotificationError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getNotificationDetailsById =
  (notification_id) => async (dispatch) => {
    dispatch(removeErrors());
    try {
      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingClaimNotificationDetails());
      const res = await axios.get(
        `/api/admin/client-workspace/notifications/${notification_id}`,
        config
      );

      dispatch(claimNotificationDetailsById(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            claimNotificationError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getClientPatientDetailsById =
  (patient_id, client_code) => async (dispatch) => {
    dispatch(removeErrors());
    try {
      const config = {
        "Content-Type": "application/json",
      };

      dispatch(clientPatientDetailsLoading());

      const res = await axios.get(
        `/api/admin/client-workspace/${client_code}/patients/${patient_id}`,
        config
      );

      dispatch(clientPatientDetailsById(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            clientClaimError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getFileBase64 = (file_path, client_code) => async (dispatch) => {
  dispatch(removeErrors());
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const params = new URLSearchParams({ file_path: file_path });

    const res = await axios.get(
      `/api/admin/client-workspace/${client_code}/get-file-base64?${params.toString()}`,
      config
    );

    return res && res.data ? res.data.response : null;
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetClientWorkspace());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    // dispatch(clientError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};
