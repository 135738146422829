import React from "react";
import { useParams, Link } from "react-router-dom";

import { isAdmin } from "utils/helper";

const ClientNav = ({ client, loggedInUser }) => {
  const params = useParams();
  const currentTab = params["*"];

  return (
    <ul className="nav nav-tabs nav-bordered">
      <li className="nav-item">
        <Link
          className={`nav-link py-2 ${
            currentTab === "patients" ? "active" : ""
          }`}
          to={`/admin/clients/${client._id}/workspace/patients`}
        >
          Patients
        </Link>
      </li>

      <li className="nav-item">
        <Link
          className={`nav-link py-2 ${
            currentTab === "encounters" ? "active" : ""
          }`}
          to={`/admin/clients/${client._id}/workspace/encounters`}
        >
          Encounters
        </Link>
      </li>

      <li className="nav-item">
        <Link
          className={`nav-link py-2 ${
            currentTab === "benefits-investigation" ? "active" : ""
          }`}
          to={`/admin/clients/${client._id}/workspace/benefits-investigation`}
        >
          Benefits Investigation
        </Link>
      </li>

      <li className="nav-item">
        <Link
          className={`nav-link py-2 ${
            currentTab === "notifications/patients" ? "active" : ""
          }`}
          to={`/admin/clients/${client._id}/workspace/notifications/patients`}
        >
          Notifications/Patients
        </Link>
      </li>

      <li className="nav-item">
        <Link
          className={`nav-link py-2 ${
            currentTab === "notifications/encounters" ? "active" : ""
          }`}
          to={`/admin/clients/${client._id}/workspace/notifications/encounters`}
        >
          Notifications/Encounters
        </Link>
      </li>

      {isAdmin(loggedInUser) ? (
        <li className="nav-item">
          <Link
            className={`nav-link py-2 ${
              currentTab === "client-services" ? "active" : ""
            }`}
            to={`/admin/clients/${client._id}/workspace/client-services`}
          >
            Client Services
          </Link>
        </li>
      ) : null}
    </ul>
  );
};

export default ClientNav;
