import axios from "axios";
import { setAlert } from "./alert";

import { setErrorsList } from "./errors";
import { logout } from "actions/auth";

import { removeErrors } from "reducers/errors";

import {
  BI_PAError,
  BI_PADetailsById,
  BI_PASearchParametersUpdate,
  BI_PAListUpdated,
  BI_PACreated,
  loadingOnBI_PASubmit,
  resetBI_PA,
  loadingBI_PAList,
  loadBI_PA,
  BI_PAUpdated,
  BI_PAInsuranceListUpdated,
  BI_PA_CPTCodesListUpdated,
  BI_PA_PatientsListUpdated,
  BI_PADeleted,
} from "reducers/bi_paReducer";

export const getBI_PADetailsByID =
  (bi_pa_id, client_code) => async (dispatch) => {
    dispatch(removeErrors());
    try {
      const config = {
        "Content-Type": "application/json",
      };
      dispatch(loadBI_PA());

      const res = await axios.get(
        `/api/admin/client-workspace/${client_code}/bi_pa/${bi_pa_id}`,
        config
      );

      dispatch(BI_PADetailsById(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            BI_PAError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const createBI_PA = (formData, client_code) => async (dispatch) => {
  dispatch(removeErrors());
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnBI_PASubmit());

    const res = await axios.post(
      `/api/admin/client-workspace/${client_code}/bi_pa`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(BI_PACreated());
      dispatch(setAlert("Benefits Investigation Created.", "success"));
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(BI_PAError());
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          BI_PAError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const updateBI_PAByID =
  (formData, client_code, bi_pa_id) => async (dispatch) => {
    dispatch(removeErrors());
    try {
      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnBI_PASubmit());

      const res = await axios.put(
        `/api/admin/client-workspace/${client_code}/bi_pa/${bi_pa_id}`,
        formData,
        config
      );

      if (res.data.status === true) {
        dispatch(BI_PAUpdated(res.data.response));
        dispatch(setAlert("Benefits Investigation updaed.", "success"));
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(BI_PAError());
          dispatch(setAlert(res.data.message, "danger"));
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            BI_PAError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getBI_PAList = (claimParams, client_code) => async (dispatch) => {
  await dispatch(removeErrors());
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = claimParams.query ? claimParams.query : "";
    claimParams.query = query;
    config.params = claimParams;

    await dispatch(loadingBI_PAList());
    const res = await axios.get(
      `/api/admin/client-workspace/${client_code}/bi_pa`,
      config
    );

    dispatch(BI_PASearchParametersUpdate(claimParams));

    dispatch(BI_PAListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          BI_PAError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const getInsuranceList = (client_code) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      `/api/admin/client-workspace/${client_code}/bi_pa/insurances`,
      config
    );

    dispatch(BI_PAInsuranceListUpdated(res.data.response));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          BI_PAError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const getClientCPTCodesList = (client_code) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      `/api/admin/client-workspace/${client_code}/bi_pa/cpt-codes`,
      config
    );

    dispatch(BI_PA_CPTCodesListUpdated(res.data.response));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          BI_PAError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const getClientPatientsList = (client_code) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      `/api/admin/client-workspace/${client_code}/bi_pa/patients`,
      config
    );

    dispatch(BI_PA_PatientsListUpdated(res.data.response));

    return res ? res.data : {};
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          BI_PAError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const removeBI_PAErrors = () => async (dispatch) => {
  await dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(removeErrors());
  await dispatch(resetBI_PA());
};

export const deleteBI_PA = (client_code, bi_pa_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(
      `/api/admin/client-workspace/${client_code}/bi_pa/${bi_pa_id}`,
      config
    );

    dispatch(BI_PADeleted(bi_pa_id));
    dispatch(setAlert("BI/PA deleted", "success"));
  } catch (err) {
    console.error(err);
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
    err.response &&
      dispatch(
        BI_PAError({
          msg: err.response.data.message || err.response.statusText,
          status: err.response.status,
        })
      );
  }
};
