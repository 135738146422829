import React from "react";
import { connect } from "react-redux";

import SelectedGraph from "../SelectedGraph";
import { getSelectedClientCountByQuarter } from "actions/dashboardActions";

const PatientsQuarterlyView = ({
  selectedClient,
  getSelectedClientCountByQuarter,
  currentClientPatientsByQuarter,
  loadingDashboardPatientsByQuarter,
}) => {
  React.useEffect(() => {
    if (!selectedClient || !selectedClient.code) return;
    getSelectedClientCountByQuarter(selectedClient.code, "patients");
  }, [getSelectedClientCountByQuarter, selectedClient]);

  const initialQuarterData = {
    currentQuarterCount: 0,
    previousQuarterCount: 0,
    countsByQuarter: {
      "Jan-Feb-Mar": 0,
      "Apr-May-Jun": 0,
      "Jul-Aug-Sep": 0,
      "Oct-Nov-Dec": 0,
    },
  };

  const [patientsCountByQuarter, setPatientsCountByQuarter] =
    React.useState(initialQuarterData);

  React.useEffect(() => {
    if (!currentClientPatientsByQuarter) return;

    const { currentQuarterCount, previousQuarterCount, countsByQuarter } =
      currentClientPatientsByQuarter;

    const quarterNameValuePairs = Object.entries(countsByQuarter).map(
      ([name, count]) => ({
        name,
        count,
      })
    );

    const currentData = {
      currentQuarterCount: currentQuarterCount ? currentQuarterCount : 0,
      previousQuarterCount: previousQuarterCount ? previousQuarterCount : 0,
      countsByQuarter:
        quarterNameValuePairs && quarterNameValuePairs.length
          ? quarterNameValuePairs
          : patientsCountByQuarter.countsByQuarter,
    };

    setPatientsCountByQuarter(currentData);
  }, [currentClientPatientsByQuarter]);

  return (
    <SelectedGraph
      loading={loadingDashboardPatientsByQuarter}
      data={patientsCountByQuarter.countsByQuarter}
      filterLabel="Quarter"
      currentCount={patientsCountByQuarter.currentQuarterCount}
      lastCount={patientsCountByQuarter.previousQuarterCount}
      legend="# Patients"
    />
  );
};

const mapStateToProps = (state) => ({
  currentClientPatientsByQuarter:
    state.dashboard.currentClientPatientsByQuarter,
  loadingDashboardPatientsByQuarter:
    state.dashboard.loadingDashboardPatientsByQuarter,
});

export default connect(mapStateToProps, {
  getSelectedClientCountByQuarter,
})(PatientsQuarterlyView);
