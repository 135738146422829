import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  claimsList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentClaim: null,
  loadingClaimList: true,
  loadingClaim: true,
  loadingOnSubmit: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const claimSlice = createSlice({
  name: "claims",
  initialState: initialState,
  reducers: {
    resetClaim(state) {
      return {
        ...initialState,
      };
    },
    loadClaim(state) {
      return {
        ...state,
        loadingClaim: false,
      };
    },
    claimUpdated(state, action) {
      return {
        ...state,
        claimsList: {
          ...state.claimsList,
          data: state.claimsList.data.map((claim) =>
            claim._id === action.payload._id ? action.payload : claim
          ),
        },
        currentClaim: action.payload,
        sortingParams: initialState.sortingParams,
        loadingOnSubmit: false,
      };
    },
    claimError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingClaim: false,
        loadingOnSubmit: false,
        loadingClaimList: false,
      };
    },
    claimDetailsById(state, action) {
      return {
        ...state,
        currentClaim: action.payload,
        loadingClaim: false,
      };
    },
    claimListUpdated(state, action) {
      return {
        ...state,
        claimsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },

        loadingClaimList: false,
      };
    },
    claimSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingClaimList: false,
      };
    },
    loadingOnClaimSubmit(state) {
      return {
        ...state,
        loadingOnSubmit: true,
      };
    },
    loadingClientDetail(state) {
      return {
        ...state,
        loadingClaim: true,
      };
    },
    loadingClaimsList(state) {
      return {
        ...state,
        loadingClaimList: true,
      };
    },
    claimCreated(state, action) {
      return {
        ...state,
        loadingOnSubmit: false,
      };
    },
    claimLoadedToTebra(state, action) {
      return {
        ...state,
        currentClaim: action.payload,
      };
    },
  },
});

export const {
  loadClaim,
  claimUpdated,
  claimError,
  claimDetailsById,
  claimListUpdated,
  claimSearchParametersUpdate,
  loadingOnClaimSubmit,
  loadingClaimsList,
  loadingClientDetail,
  resetClaim,
  claimCreated,
  claimLoadedToTebra,
} = claimSlice.actions;
export default claimSlice.reducer;
