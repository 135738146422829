import React from "react";
import { Form, Modal, Tabs, Tab, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { JsonView, darkStyles } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

import Spinner from "views/Spinner";

import { getNotificationDetailsById } from "actions/clientWorkspaceActions";

const NotificationModal = ({
  modal,
  setModal,
  toggle,
  setectedNotificationID,
  setSetectedNotificationID,
  currentClaimNotification,
  loadingNotification,
  getNotificationDetailsById,
}) => {
  const reset = () => {
    setModal(false);
    setSetectedNotificationID(null);
  };

  React.useEffect(() => {
    if (!setectedNotificationID) return;
    getNotificationDetailsById(setectedNotificationID);
  }, [setectedNotificationID]);

  const initialData = {
    trace: {},
    postedData: {},
  };
  const [tabData, setTabData] = React.useState(initialData);

  React.useEffect(() => {
    if (!currentClaimNotification) return;

    const { trace, postedData } = currentClaimNotification;
    setTabData({ trace, postedData });
  }, [currentClaimNotification]);

  return (
    <Modal
      show={modal}
      onHide={reset}
      size="xl"
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
    >
      <Modal.Header toggle={toggle} closeButton></Modal.Header>
      <Modal.Body>
        {loadingNotification ? (
          <Spinner />
        ) : (
          <Tabs>
            <Tab eventKey="notification-trace" title="Trace">
              <Form>
                <Row>
                  <Col md="12">
                    <JsonView
                      data={tabData.trace}
                      shouldInitiallyExpand={() => true}
                      style={darkStyles}
                    />
                  </Col>
                </Row>
              </Form>
            </Tab>
            <Tab eventKey="notification-posted-data" title="Posted Data">
              <Form>
                <Row>
                  <Col md="12">
                    <JsonView
                      data={tabData.postedData}
                      shouldInitiallyExpand={() => true}
                      style={darkStyles}
                    />
                  </Col>
                </Row>
              </Form>
            </Tab>
          </Tabs>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currentClaimNotification: state.clientWorkspace.currentClaimNotification,
  loadingNotification: state.clientWorkspace.loadingClaimNotification,
});

export default connect(mapStateToProps, {
  getNotificationDetailsById,
})(NotificationModal);
