import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  clientsList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentClient: [],
  loadingClientList: true,
  loadingClient: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const clientSlice = createSlice({
  name: "clients",
  initialState: initialState,
  reducers: {
    clientCreated(state) {
      return {
        ...state,
        loadingClient: false,
      };
    },
    loadClientPage(state) {
      return {
        ...state,
        loadingClient: false,
      };
    },

    resetClient(state) {
      return {
        ...initialState,
      };
    },
    loadClient(state) {
      return {
        ...state,
        loadingClient: false,
      };
    },
    clientUpdated(state, action) {
      return {
        ...state,
        currentClient: action.payload,
        clientsList: {
          ...state.claimsList,
          data: state.clientsList.data.map((client) =>
            client._id === action.payload._id ? action.payload : client
          ),
        },
        sortingParams: initialState.sortingParams,
        loadingClient: false,
      };
    },
    clientError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingClient: false,
        loadingClientList: false,
      };
    },
    clientDeleted(state, action) {
      const currentCount = state.clientsList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.clientsList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        clientsList: {
          data: state.clientsList.data.filter(
            (client) => client._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingClientList: false,
      };
    },
    clientDetailsById(state, action) {
      return {
        ...state,
        currentClient: action.payload,
        loadingClient: false,
      };
    },
    clientListUpdated(state, action) {
      return {
        ...state,
        clientsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        // loadingClient: true,
        loadingClientList: false,
      };
    },
    clientSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingClientList: false,
      };
    },
    changeClientStatus(state, action) {
      return {
        ...state,
        clientsList: {
          ...state.clientsList,
          data: state.clientsList.data.map((client) =>
            client._id === action.payload._id
              ? { ...client, status: action.payload.status }
              : client
          ),
        },
      };
    },
    loadingOnClientSubmit(state) {
      return {
        ...state,
        loadingClient: true,
      };
    },
    loadingClientsList(state) {
      return {
        ...state,
        loadingClientList: true,
      };
    },
  },
});

export const {
  clientCreated,
  loadClientPage,
  clientUpdated,
  clientError,
  clientDeleted,
  clientDetailsById,
  clientListUpdated,
  clientSearchParametersUpdate,
  loadingOnClientSubmit,
  loadingClientsList,
  resetClient,
} = clientSlice.actions;
export default clientSlice.reducer;
