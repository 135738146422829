import React from "react";
import { connect } from "react-redux";

import SelectedGraph from "../SelectedGraph";
import { getSelectedClientCountByYear } from "actions/dashboardActions";

const PatientsYearlyView = ({
  selectedClient,
  getSelectedClientCountByYear,
  currentClientPatientsByYear,
  loadingDashboardPatientsByYear,
}) => {
  React.useEffect(() => {
    if (!selectedClient || !selectedClient.code) return;
    getSelectedClientCountByYear(selectedClient.code, "patients");
  }, [getSelectedClientCountByYear, selectedClient]);

  const initialYearData = {
    currentYearCount: 0,
    previousYearCount: 0,
    countsByYear: [],
  };

  const [patientsCountByYear, setPatientsCountByYear] =
    React.useState(initialYearData);

  React.useEffect(() => {
    if (!currentClientPatientsByYear) return;

    const { currentYearCount, previousYearCount, countsByYear } =
      currentClientPatientsByYear;

    const yearNameValuePairs = Object.entries(countsByYear).map(
      ([name, count]) => ({
        name,
        count,
      })
    );

    const currentData = {
      currentYearCount: currentYearCount ? currentYearCount : 0,
      previousYearCount: previousYearCount ? previousYearCount : 0,
      countsByYear:
        yearNameValuePairs && yearNameValuePairs.length
          ? yearNameValuePairs
          : 0,
    };

    setPatientsCountByYear(currentData);
  }, [currentClientPatientsByYear]);

  return (
    <SelectedGraph
      loading={loadingDashboardPatientsByYear}
      data={patientsCountByYear.countsByYear}
      filterLabel="Year"
      currentCount={patientsCountByYear.currentYearCount}
      lastCount={patientsCountByYear.previousYearCount}
      legend="# Patients"
    />
  );
};

const mapStateToProps = (state) => ({
  currentClientPatientsByYear: state.dashboard.currentClientPatientsByYear,
  loadingDashboardPatientsByYear:
    state.dashboard.loadingDashboardPatientsByYear,
});

export default connect(mapStateToProps, {
  getSelectedClientCountByYear,
})(PatientsYearlyView);
