import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { BiPlusMedical } from "react-icons/bi";

import Errors from "Notifications/Errors";
import { isEditor } from "utils/helper";
import { validateForm } from "utils/validation";

import { RelationshipGlobal } from "constants/index";

import ViewFile from "../ViewFile";

const InsuranceDataTab = ({
  insuranceData,
  setInsuranceData,
  saveInsuranceData,
  loadingOnSubmit,
  isDisabled,
  toggleEdit,
  onClickCancel,
  loggedInUser,
  selectedPatientID,
  handleTabSelect,
  setErrors,
  patientDOB,
  setIsRequiredResponsiblePartyData,
  checkIsSelfSubscriberRelationship,
  insurancesListAll,
  removePatientErrors,
  clientCode,
}) => {
  const onChange = (e, index) => {
    if (!e.target) return;
    const cpInsuranceData = insuranceData.map((data, i) => {
      const data2 = { ...data };
      if (i === index) {
        switch (e.target.name) {
          case "insuranceCard":
            var file = e.target.files[0];
            if (file) {
              var reader = new FileReader();

              reader.onload = function (e) {
                var base64String = e.target.result.split(",")[1]; // Extracting the Base64 part
                data2["insuranceCard"] = base64String;
              };

              reader.readAsDataURL(file);
            }

            break;
          default:
            data2[e.target.name] = e.target.value;
        }
      }

      return data2;
    });

    setInsuranceData(cpInsuranceData);
  };

  const handleSelect2 = (field, index) => (selectedOption) => {
    const cpInsuranceData = insuranceData.map((data, i) => {
      const data2 = { ...data };
      if (i === index) {
        switch (field) {
          case "insuranceCompanyId":
            data2[field] = selectedOption.value;

            const extractedName = /\(([^)]+)\)/.exec(selectedOption.label);
            data2["insuranceCompanyName"] = extractedName[1];
            break;
          default:
            data2[field] = selectedOption.value;
        }
      }

      return data2;
    });

    setInsuranceData(cpInsuranceData);
  };

  const onClickHadle = () => {
    setInsuranceData([
      ...insuranceData,
      {
        insuranceCompanyId: "",
        insuranceCompanyName: "",
        subscriberMemberNumber: "",
        subscriberGroupNumber: "",
        insuranceCard: "",
        subscriberRelationship: "",
        subscriberFirstName: "",
        subscriberLastName: "",
        subscriberDob: "",
        subscriberPhoneNo: "",
        subscriberEmail: "",
      },
    ]);
  };

  const onClickNext = (e) => {
    e.preventDefault();

    removePatientErrors();

    if (!insuranceData || !insuranceData.length) {
      return;
    }

    let validationRules = [
      {
        param: "insuranceCompanyId",
        msg: "Please provide insurance company Id.",
      },
      {
        param: "insuranceCompanyName",
        msg: "Please provide insurance company name.",
      },
      {
        param: "subscriberMemberNumber",
        msg: "Please provide subscriberMemberNumber.",
      },
      {
        param: "subscriberRelationship",
        msg: "The subscriber relationship should have any of these values SELF, SPOUSE, CHILD, OTHER.",
      },
    ];

    let errors = [];

    insuranceData.forEach((data, index) => {
      const dataErrors = validateForm(data, validationRules);

      if (dataErrors.length) {
        dataErrors.forEach((error) => {
          error.param = `insurance[${index}].${error.param}`;
        });
        errors = errors.concat(dataErrors);
      }
    });

    const isSelf = checkIsSelfSubscriberRelationship(patientDOB, insuranceData);
    setIsRequiredResponsiblePartyData(isSelf);

    if (errors.length) {
      setErrors(errors);
      return;
    }
    handleTabSelect("responsibleParty");
  };

  const onClickRemove = (index) => {
    setInsuranceData((previousArr) =>
      previousArr.filter((each, i) => i !== index)
    );
  };

  const [insuranceDropdownList, setInsuranceDropdownList] = React.useState([]);
  React.useEffect(() => {
    const list = insurancesListAll.map((ins) => ({
      label: `${ins.InternalInsCoID} (${ins.InternalInsCoName})`,
      value: ins.InternalInsCoID,
    }));

    setInsuranceDropdownList(list);
  }, [insurancesListAll]);

  return (
    <Form onSubmit={(e) => saveInsuranceData(e)}>
      <Row>
        {insuranceData.map((each, index) => (
          <React.Fragment key={index}>
            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Insurance Company Id</Form.Label>

                <Select
                  id="insuranceCompanyId"
                  name="insuranceCompanyId"
                  options={[
                    {
                      label: "Select Insurance",
                      value: "",
                    },
                    ...insuranceDropdownList,
                  ]}
                  value={insuranceDropdownList.find(
                    (r) => Number(r.value) === each.insuranceCompanyId
                  )}
                  onChange={handleSelect2("insuranceCompanyId", index)}
                  isDisabled={isDisabled}
                />

                <Errors
                  current_key={`insurance[${index}].insuranceCompanyId`}
                  key={`insurance[${index}].insuranceCompanyId`}
                />
              </Form.Group>
            </Col>

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Insurance Company Name</Form.Label>
                <Form.Control
                  type="text"
                  name="insuranceCompanyName"
                  value={each.insuranceCompanyName}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />

                <Errors
                  current_key={`insurance[${index}].insuranceCompanyName`}
                  key={`insurance[${index}].insuranceCompanyName`}
                />
              </Form.Group>
            </Col>

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Insurance Member Number</Form.Label>
                <Form.Control
                  type="text"
                  name="subscriberMemberNumber"
                  value={each.subscriberMemberNumber}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />

                <Errors
                  current_key={`insurance[${index}].subscriberMemberNumber`}
                  key={`insurance[${index}].subscriberMemberNumber`}
                />
              </Form.Group>
            </Col>

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Subscriber Group Number</Form.Label>
                <Form.Control
                  type="text"
                  name="subscriberGroupNumber"
                  value={each.subscriberGroupNumber}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />

                <Errors
                  current_key={`insurance[${index}].subscriberGroupNumber`}
                  key={`insurance[${index}].subscriberGroupNumber`}
                />
              </Form.Group>
            </Col>

            {each.insuranceCard || !isDisabled ? (
              <Col xxl="2" lg="3" md="4" xs="6">
                <Form.Group className="form-group">
                  <Form.Label>Insurance Card</Form.Label>
                  {selectedPatientID && isDisabled ? (
                    <ViewFile
                      filePath={each.insuranceCard}
                      clientCode={clientCode}
                    />
                  ) : (
                    <Form.Control
                      type="file"
                      name="insuranceCard"
                      accept="application/pdf,image/jpeg,image/png"
                      onChange={(e) => onChange(e, index)}
                      disabled={isDisabled}
                    />
                  )}

                  <Errors
                    current_key={`insurance[${index}].insuranceCard`}
                    key={`insurance[${index}].insuranceCard`}
                  />
                </Form.Group>
              </Col>
            ) : null}

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Subscriber Relationship</Form.Label>
                <Select
                  id="subscriberRelationship"
                  name="subscriberRelationship"
                  options={RelationshipGlobal}
                  value={RelationshipGlobal.find(
                    (r) => r.value === each.subscriberRelationship
                  )}
                  onChange={handleSelect2("subscriberRelationship", index)}
                  isDisabled={isDisabled}
                />

                <Errors
                  current_key={`insurance[${index}].subscriberRelationship`}
                  key={`insurance[${index}].subscriberRelationship`}
                />
              </Form.Group>
            </Col>

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Subscriber First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="subscriberFirstName"
                  value={each.subscriberFirstName}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />

                <Errors
                  current_key={`insurance[${index}].subscriberFirstName`}
                  key={`insurance[${index}].subscriberFirstName`}
                />
              </Form.Group>
            </Col>

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Subscriber Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="subscriberLastName"
                  value={each.subscriberLastName}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />

                <Errors
                  current_key={`insurance[${index}].subscriberLastName`}
                  key={`insurance[${index}].subscriberLastName`}
                />
              </Form.Group>
            </Col>

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Subscriber DOB</Form.Label>
                <Form.Control
                  type="date"
                  name="subscriberDob"
                  value={each.subscriberDob}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />

                <Errors
                  current_key={`insurance[${index}].subscriberDob`}
                  key={`insurance[${index}].subscriberDob`}
                />
              </Form.Group>
            </Col>

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Subscriber Phone No</Form.Label>
                <Form.Control
                  type="number"
                  name="subscriberPhoneNo"
                  value={each.subscriberPhoneNo}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />

                <Errors
                  current_key={`insurance[${index}].subscriberPhoneNo`}
                  key={`insurance[${index}].subscriberPhoneNo`}
                />
              </Form.Group>
            </Col>

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Subscriber Email</Form.Label>
                <Form.Control
                  type="text"
                  name="subscriberEmail"
                  value={each.subscriberEmail}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />

                <Errors
                  current_key={`insurance[${index}].subscriberEmail`}
                  key={`insurance[${index}].subscriberEmail`}
                />
              </Form.Group>
            </Col>

            {!isDisabled ? (
              <Col xxl="2" lg="3" md="4" xs="6">
                <Button
                  className="c-red mt-4"
                  variant="link"
                  size="sm"
                  onClick={(e) => onClickRemove(index)}
                >
                  Remove
                </Button>
              </Col>
            ) : null}

            <hr className="tab-hr-line" />
          </React.Fragment>
        ))}

        {!isDisabled ? (
          <div>
            <Button color="primary" size="sm" onClick={(e) => onClickHadle()}>
              <BiPlusMedical /> Add New
            </Button>

            <div>
              <Errors current_key="insurance" key="insurance" />
            </div>
          </div>
        ) : null}
      </Row>

      {isEditor(loggedInUser) ? (
        <div className="float-end">
          {selectedPatientID ? (
            !isDisabled ? (
              <>
                <Button
                  className="m-2"
                  type="submit"
                  variant="primary"
                  disabled={loadingOnSubmit || isDisabled}
                >
                  {loadingOnSubmit ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {` Loading... `}
                    </>
                  ) : (
                    <>Save</>
                  )}
                </Button>
                <Button
                  className="ml-2"
                  type="reset"
                  variant="danger"
                  onClick={onClickCancel}
                  disabled={loadingOnSubmit || isDisabled}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                className="btn btn-outline-dark"
                size="sm"
                onClick={toggleEdit}
              >
                Click to Edit
              </Button>
            )
          ) : (
            <div>
              <Button
                className="m-2"
                variant="light"
                onClick={(e) => handleTabSelect("patientData")}
              >
                Back
              </Button>

              <Button
                type="btn"
                variant="primary"
                onClick={(e) => onClickNext(e)}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      ) : null}
    </Form>
  );
};

export default InsuranceDataTab;
