import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";

import RecordCountBox from "../RecordCountBox";

import { getSelectedClientPostDataCount } from "actions/dashboardActions";

const PatientsCount = ({
  selectedClient,
  getSelectedClientPostDataCount,
  currentClientPatientsCount,
  loadingDashboardClientPatientsCount,
}) => {
  React.useEffect(() => {
    if (!selectedClient || !selectedClient.code) return;
    getSelectedClientPostDataCount(selectedClient.code, "patients");
  }, [getSelectedClientPostDataCount, selectedClient]);

  const initialData = {
    todayPostDataCount: 0,
    lastDayPostDataCount: 0,
    thisWeekPostDataCount: 0,
    lastWeekPostDataCount: 0,
    thisMonthPostDataCount: 0,
    lastMonthPostDataCount: 0,
    thisQuarterPostDataCount: 0,
    lastQuarterPostDataCount: 0,
    thisYearPostDataCount: 0,
    lastYearPostDataCount: 0,
  };

  const [patientsCount, setPatientsCount] = React.useState(initialData);

  React.useEffect(() => {
    if (!currentClientPatientsCount) return;

    const {
      todayPostDataCount,
      lastDayPostDataCount,
      thisWeekPostDataCount,
      lastWeekPostDataCount,
      thisMonthPostDataCount,
      lastMonthPostDataCount,
      thisQuarterPostDataCount,
      lastQuarterPostDataCount,
      thisYearPostDataCount,
      lastYearPostDataCount,
    } = currentClientPatientsCount;

    const currentData = {
      todayPostDataCount: todayPostDataCount ? todayPostDataCount : 0,
      lastDayPostDataCount: lastDayPostDataCount ? lastDayPostDataCount : 0,
      thisWeekPostDataCount: thisWeekPostDataCount ? thisWeekPostDataCount : 0,
      lastWeekPostDataCount: lastWeekPostDataCount ? lastWeekPostDataCount : 0,
      thisMonthPostDataCount: thisMonthPostDataCount
        ? thisMonthPostDataCount
        : 0,
      lastMonthPostDataCount: lastMonthPostDataCount
        ? lastMonthPostDataCount
        : 0,
      thisQuarterPostDataCount: thisQuarterPostDataCount
        ? thisQuarterPostDataCount
        : 0,
      lastQuarterPostDataCount: lastQuarterPostDataCount
        ? lastQuarterPostDataCount
        : 0,
      thisYearPostDataCount: thisYearPostDataCount ? thisYearPostDataCount : 0,
      lastYearPostDataCount: lastYearPostDataCount ? lastYearPostDataCount : 0,
    };

    setPatientsCount(currentData);
  }, [currentClientPatientsCount]);

  return (
    <div className="patients-count-box count-box">
      <Row className="d-flex first-line-data">
        <RecordCountBox
          countLabel="Patients Today"
          value={patientsCount.todayPostDataCount}
          previousLabel="Yesterday"
          previousValue={patientsCount.lastDayPostDataCount}
          loadingBox={loadingDashboardClientPatientsCount}
        />

        <RecordCountBox
          countLabel="Patients This Week"
          value={patientsCount.thisWeekPostDataCount}
          previousLabel="Last Week"
          previousValue={patientsCount.lastWeekPostDataCount}
          loadingBox={loadingDashboardClientPatientsCount}
        />

        <RecordCountBox
          countLabel="Patients This Month"
          value={patientsCount.thisMonthPostDataCount}
          previousLabel="Last Month"
          previousValue={patientsCount.lastMonthPostDataCount}
          loadingBox={loadingDashboardClientPatientsCount}
        />

        <RecordCountBox
          countLabel="Patients This Quarter"
          value={patientsCount.thisQuarterPostDataCount}
          previousLabel="Last Quarter"
          previousValue={patientsCount.lastQuarterPostDataCount}
          loadingBox={loadingDashboardClientPatientsCount}
        />

        <RecordCountBox
          countLabel="Patients YTD"
          value={patientsCount.thisYearPostDataCount}
          previousLabel="Last Year"
          previousValue={patientsCount.lastYearPostDataCount}
          loadingBox={loadingDashboardClientPatientsCount}
        />
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentClientPatientsCount: state.dashboard.currentClientPatientsCount,
  loadingDashboardClientPatientsCount:
    state.dashboard.loadingDashboardClientPatientsCount,
});

export default connect(mapStateToProps, {
  getSelectedClientPostDataCount,
})(PatientsCount);
