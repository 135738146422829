import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";

import { RxDotsVertical } from "react-icons/rx";

import { clientStatus } from "constants/index";
import PatientsStatusThisWeek from "./PatientsStatusThisWeek";
import PatientsStatusThisMonth from "./PatientsStatusThisMonth";
import PatientsStatusThisQuarter from "./PatientsStatusThisQuarter";
import PatientsStatusThisYear from "./PatientsStatusThisYear";

const PatientsStatusCountView = ({ selectedClient }) => {
  const [patientStatusViewType, setPatientStatusViewType] =
    React.useState("this-week");

  const handleSelectPatientFilter = (eventKey) => {
    setPatientStatusViewType(eventKey);
    console.log(`selected patient status filter: ${eventKey}`);
  };

  const [statusOptions, setStatusOptions] = React.useState([]);

  React.useEffect(() => {
    const options = clientStatus.map((each) => each.value);
    setStatusOptions(options);
  }, [clientStatus]);

  return (
    <div className="crm-card">
      <h5 className="d-inline-block">Patients with Status</h5>

      <Nav
        variant="patients-vdots-filter"
        activeKey={patientStatusViewType}
        onSelect={handleSelectPatientFilter}
        className="float-end"
      >
        <div className="status-type">{patientStatusViewType}</div>
        <NavDropdown
          className="vdots-filter"
          title={
            <span>
              <RxDotsVertical className="float-end" size={"25px"} />
            </span>
          }
          id="patients-filter"
        >
          <NavDropdown.Item eventKey="this-week">This Week</NavDropdown.Item>
          <NavDropdown.Item eventKey="this-month">This Month</NavDropdown.Item>
          <NavDropdown.Item eventKey="this-quarter">
            This Quarter
          </NavDropdown.Item>
          <NavDropdown.Item eventKey="this-year">This Year</NavDropdown.Item>
        </NavDropdown>
      </Nav>

      {patientStatusViewType === "this-week" ? (
        <PatientsStatusThisWeek
          selectedClient={selectedClient}
          statusOptions={statusOptions}
        />
      ) : null}

      {patientStatusViewType === "this-month" ? (
        <PatientsStatusThisMonth
          selectedClient={selectedClient}
          statusOptions={statusOptions}
        />
      ) : null}

      {patientStatusViewType === "this-quarter" ? (
        <PatientsStatusThisQuarter
          selectedClient={selectedClient}
          statusOptions={statusOptions}
        />
      ) : null}

      {patientStatusViewType === "this-year" ? (
        <PatientsStatusThisYear
          selectedClient={selectedClient}
          statusOptions={statusOptions}
        />
      ) : null}
    </div>
  );
};

export default PatientsStatusCountView;
