import React from "react";
import moment from "moment";
import { Form } from "react-bootstrap";

import { getDateRange } from "utils/helper";
const BI_PAFilters = (props) => {
  const {
    type,
    filterName,
    filter,
    searchDelay = 750,
    filterParams,
    filterType,
    onFilterChange,
    selectOptions,
    searchDefaultValue,
  } = props;

  let delayTimer;

  const handleSearch = (e) => {
    clearTimeout(delayTimer);
    const text = e.target.value;
    delayTimer = setTimeout(() => {
      props.onSearch("search", text);
    }, searchDelay);
  };

  return (
    <>
      {props.onSearch ? (
        <Form.Group>
          <Form.Label htmlFor="search">Search</Form.Label>
          <Form.Control
            type="text"
            placeholder="Search by Patient ID, Patient Name and Case Number"
            title="Search by Patient ID, Patient Name and Case Number"
            defaultValue={searchDefaultValue}
            onChange={(e) => handleSearch(e)}
          />
        </Form.Group>
      ) : null}
    </>
  );
};

export default BI_PAFilters;
