import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import Spinner from "../Spinner";
import Alert from "../../Notifications/Alert";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import Errors from "../../Notifications/Errors";

import { login, loadPage } from "../../actions/auth";

const LoginPage = ({ login, loadPage, loading }) => {
  const initialState = {
    email: "",
    password: "",
    rememberMe: "",
  };

  const navigate = useNavigate();

  const [formData, setFormData] = React.useState(initialState);
  const [showPassword, setShowPassword] = React.useState(false);
  const [inputType, setInputType] = React.useState("password");

  const onChange = (e) => {
    if (!e) return;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeCheck = (e) => {
    if (!e.target) return;

    setFormData({
      ...formData,
      terms: e.target.checked ? true : false,
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    setInputType(inputType === "password" ? "text" : "password");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    login(formData, navigate).then(() => {});
  };

  React.useEffect(() => {
    loadPage();
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <Helmet>
        <title>Login | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Container className="account-pages circle-1">
        <Row className="justify-content-center">
          <Col lg="10">
            <Card>
              <Card.Header className="pt-3 pb-3 text-center bg-primary">
                <Link to="/">
                  <span>
                    <img src={logo} alt="" height="26" />
                  </span>
                </Link>
              </Card.Header>
              <Card.Body className="p-4">
                <Card.Title className="text-center text-muted mt-3 mb-3">
                  Log In
                </Card.Title>
                <Card.Text className="text-center text-muted mt-3 mb-3">
                  Enter your email address and password to access admin panel.
                </Card.Text>
                <Alert />
                <Form onSubmit={(e) => onSubmit(e)}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      minLength="8"
                      name="email"
                      onChange={(e) => onChange(e)}
                      required
                    />

                    <Errors key="email" current_key="email" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Link
                      to="/forgot-password"
                      className="text-muted ms-1 text-decoration float-end"
                    >
                      Forgot your password?
                    </Link>
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        onChange={(e) => onChange(e)}
                        required
                      />
                      <InputGroup.Text onClick={toggleShowPassword}>
                        {inputType === "password" ? (
                          <FaRegEyeSlash />
                        ) : (
                          <FaRegEye />
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Remember me"
                    onChange={(e) => onChangeCheck(e)}
                    required
                  />
                  </Form.Group> */}
                  <Form.Group className="float-end">
                    <Button variant="primary" type="submit">
                      Log In
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
            {/* <Row className="text-center mt-3 mb-3">
              <Col>
                <span>Don't have an account?</span>
                <Link to="/signup" className="text-muted ms-1 text-decoration">
                  <strong>Sign Up</strong>
                </Link>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

LoginPage.proTypes = {
  errorList: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.auth.loading,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  login,
  loadPage,
})(LoginPage);
