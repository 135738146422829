import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { FaRegEye } from "react-icons/fa";
import moment from "moment";

import PiDataTable from "views/DataTable/PiDataTable";

import {
  getPatientNotificationsList,
  resetComponentStore,
} from "actions/clientWorkspaceActions";

import PatientNotificationFilters from "./PatientNotificationFIlters";
import NotificationModal from "./ViewNotificationModal";

const PatientNotificationsList = ({
  patientNotificationsList: { data, count },
  getPatientNotificationsList,
  loadingPatientNotificationList,
  resetComponentStore,
  sortingParams,
  clientCode,
}) => {
  const [onlyOnce, setOnce] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [setectedNotificationID, setSetectedNotificationID] =
    React.useState(null);
  const toggle = () => setModal(!modal);

  const { page, limit } = sortingParams;

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "postedAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const [patientNotificationParams, setPatientNotificationParams] =
    React.useState(initialSortingParams);

  const onClickHandler = (row_id) => {
    setSetectedNotificationID(row_id);
    setModal(true);
  };

  const columns = [
    {
      name: "Date Posted",
      selector: (row) => moment(row.postedAt).format("MM/DD/YYYY"),
      sortable: true,
      sortField: "postedAt",
      width: "15%",
    },
    {
      name: "Endpoint",
      selector: (row) => row.trace.operation,
      sortable: false,
      sortField: "endpoint",
      width: "15%",
    },
    {
      name: "ErrorCode",
      selector: (row) => row.trace.client.errCode,
      sortable: false,
      sortField: "errorCode",
      width: "10%",
    },
    {
      name: "Message",
      selector: (row) => row.trace.client.errMsg,
      sortable: false,
      sortField: "errorMsg",
      width: "40%",
      wrap: true,
    },
    {
      name: "Actions",
      width: "calc(20% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Button
            variant="primary"
            size="sm"
            onClick={(e) => onClickHandler(row._id)}
          >
            <FaRegEye />
          </Button>
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (!clientCode) return;
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }
    getPatientNotificationsList(patientNotificationParams, clientCode);
  }, [
    getPatientNotificationsList,
    patientNotificationParams,
    resetComponentStore,
    clientCode,
  ]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              "trace.client.errMsg": {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setPatientNotificationParams(params);
  };

  const onFilterChange = (newParams) => {
    setPatientNotificationParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <NotificationModal
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        setectedNotificationID={setectedNotificationID}
        setSetectedNotificationID={setSetectedNotificationID}
      />

      <Card>
        <Card.Body>
          <div className="table-filter-section">
            <Row>
              <Col md="2">
                <PatientNotificationFilters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterName="Search"
                  filterParams={patientNotificationParams}
                  onFilterChange={onFilterChange}
                />
              </Col>

              <Col md="2">
                <PatientNotificationFilters
                  filter="createdAt"
                  type="date-range-picker"
                  filterType="Date"
                  filterName="Date Posted"
                  filterParams={patientNotificationParams}
                  onFilterChange={onFilterChange}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={patientNotificationParams}
            setParams={setPatientNotificationParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingPatientNotificationList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

PatientNotificationsList.propTypes = {
  getPatientNotificationsList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  patientNotificationsList: state.clientWorkspace.patientNotificationsList,
  loadingPatientNotificationList:
    state.clientWorkspace.loadingPatientNotificationsList,
  sortingParams: state.clientWorkspace.patientNotificationParams,
});

export default connect(mapStateToProps, {
  getPatientNotificationsList,
  resetComponentStore,
})(PatientNotificationsList);
