import React from "react";
import { Row, Col } from "react-bootstrap";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const SelectedGraph = ({
  loading,
  data,
  filterLabel,
  currentCount,
  lastCount,
  legend,
}) => {
  return (
    <React.Fragment>
      <div className="chart-content-bg">
        <Row className="d-flex mb-3 text-center">
          <Col md="6">
            <p className="text-muted mb-0 mt-3">This {filterLabel}</p>

            {loading ? (
              <span>Loading...</span>
            ) : (
              <h2 className="fw-normal mb-3">
                <span>{currentCount}</span>
              </h2>
            )}
          </Col>
          <Col md="6">
            <p className="text-muted mb-0 mt-3">Last {filterLabel}</p>

            {loading ? (
              <span>Loading...</span>
            ) : (
              <h2 className="fw-normal mb-3">
                <span>{lastCount}</span>
              </h2>
            )}
          </Col>
        </Row>
      </div>

      <div className="text-center bar-graph">
        <ResponsiveContainer width="100%" height="100%">
          {loading ? (
            <span>Loading...</span>
          ) : (
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis type="number" allowDecimals={false} />
              <Tooltip cursor={{ fill: "transparent" }} />
              <Legend verticalAlign="top" height={36} />
              <Bar
                dataKey="count"
                fill="#8884d8"
                name={legend ? legend : "Count"}
                maxBarSize={30}
              />
            </BarChart>
          )}
        </ResponsiveContainer>
      </div>
    </React.Fragment>
  );
};

export default SelectedGraph;
