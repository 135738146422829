import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";

import Errors from "Notifications/Errors";
import { BillTypeGlobal, clientStatus } from "constants/index";
import { isEditor } from "utils/helper";
import { validateForm } from "utils/validation";

import ViewFile from "../ViewFile";

const EncounterDataTab = ({
  currentClientClaim,
  encounterData,
  setEncounterData,
  loadingOnSubmit,
  isDisabled,
  toggleEdit,
  onClickCancel,
  saveEncounterData,
  loggedInUser,
  selectedClaimID,
  handleTabSelect,
  setErrors,
  clientCode,
  loadEncounterToTebra,
  patientsListAll,
}) => {
  const [selectedStatus, setSelectedStatus] = React.useState(null);
  const [statusOptions, setStatusOptions] = React.useState([]);
  const [loadingTebra, setLoadingTebra] = React.useState(false);

  const {
    orderNumber,
    createdAt,
    patientId,
    patientName,
    dateOfService,
    billType,
    totalAmount,
    currency,
    dateOfReport,
    referrerNPI,
    accessionNumber,
    testResult,
    order,
    trace,
  } = encounterData;

  React.useEffect(() => {
    const options = clientStatus.filter((each) => each.value != "DONE");
    setStatusOptions(options);

    if (!trace || !trace.bsys) return;

    const filtered = clientStatus.find(
      (each) => each.value === trace.bsys.status
    );
    if (!filtered) return;

    setSelectedStatus(filtered);
  }, [encounterData]);

  const onChange = (e) => {
    if (!e.target) return;

    switch (e.target.name) {
      case "testResult":
      case "order":
        let name = e.target.name;
        let file = e.target.files[0];

        if (file) {
          let reader = new FileReader();

          reader.onload = function (e) {
            let base64String = e.target.result.split(",")[1]; // Remove Base64 part
            setEncounterData({
              ...encounterData,
              [name]: base64String,
            });
          };

          reader.readAsDataURL(file);
        }
        setEncounterData({
          ...encounterData,
          [e.target.name]: e.target.files[0],
        });
        break;
      default:
        setEncounterData({ ...encounterData, [e.target.name]: e.target.value });
    }
  };

  const handleSelect2 = (field) => (selectedOption) => {
    switch (field) {
      case "patientId":
        const extractedName = /\(([^)]+)\)/.exec(selectedOption.label);
        setEncounterData({
          ...encounterData,
          [field]: selectedOption.value,
          patientName: extractedName[1],
        });

        break;
      default:
        setEncounterData({ ...encounterData, [field]: selectedOption.value });
    }
  };

  const handleSelect = (selectedOption) => {
    setSelectedStatus(selectedOption);
    setEncounterData({
      ...encounterData,
      trace: {
        ...trace,
        bsys: {
          ...trace.bsys,
          status: selectedOption.value,
        },
      },
    });
  };

  const onClickNext = (e) => {
    e.preventDefault();

    let validationRules = [
      {
        param: "orderNumber",
        msg: "Order number is required.",
      },
      {
        param: "patientId",
        msg: "Patient Id is required.",
      },
    ];

    const errors = validateForm(encounterData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    handleTabSelect("procedureData");
  };

  const onClickLoadTebra = () => {
    if (
      !window.confirm("Are you sure you want to load this record to Tebra?")
    ) {
      return;
    }

    console.log("On Click load to Tebra.");

    setLoadingTebra(true);
    loadEncounterToTebra(selectedClaimID, clientCode).then(() => {
      setLoadingTebra(false);
    });
  };

  const [patientDropdownList, setPatientDropdownList] = React.useState([]);
  React.useEffect(() => {
    const list = patientsListAll.map((pt) => ({
      label: `${pt.patientId} (${pt.patientFirstName} ${pt.patientLastName})`,
      value: pt.patientId,
    }));

    setPatientDropdownList(list);
  }, [patientsListAll]);

  return (
    <React.Fragment>
      <Form onSubmit={(e) => saveEncounterData(e)}>
        <Row>
          <Col xxl="2" lg="3" md="4" xs="6">
            <Form.Group className="form-group">
              <Form.Label>Order Number</Form.Label>
              <Form.Control
                type="text"
                name="orderNumber"
                value={orderNumber}
                onChange={(e) => onChange(e)}
                disabled={isDisabled}
              />

              <Errors current_key="orderNumber" key="orderNumber" />
            </Form.Group>
          </Col>

          <Col xxl="2" lg="3" md="4" xs="6">
            <Form.Group className="form-group">
              <Form.Label>Date Posted</Form.Label>
              <Form.Control
                type="date"
                name="createdAt"
                value={createdAt}
                onChange={(e) => onChange(e)}
                disabled={isDisabled}
              />

              <Errors current_key="createdAt" key="createdAt" />
            </Form.Group>
          </Col>

          <Col xxl="2" lg="3" md="4" xs="6">
            <Form.Group className="form-group">
              <Form.Label>Patient Id</Form.Label>
              <Select
                id="patientId"
                name="patientId"
                options={patientDropdownList}
                value={patientDropdownList.find((b) => b.value === patientId)}
                onChange={handleSelect2("patientId")}
                isDisabled={isDisabled}
              />

              <Errors current_key="patientId" key="patientId" />
            </Form.Group>
          </Col>

          <Col xxl="2" lg="3" md="4" xs="6">
            <Form.Group className="form-group">
              <Form.Label>Patient Name</Form.Label>
              <Form.Control
                type="text"
                name="patientName"
                value={patientName}
                onChange={(e) => onChange(e)}
                disabled={isDisabled}
              />

              <Errors current_key="patientName" key="patientName" />
            </Form.Group>
          </Col>

          <Col xxl="2" lg="3" md="4" xs="6">
            <Form.Group className="form-group">
              <Form.Label>Date Of Service</Form.Label>
              <Form.Control
                type="date"
                name="dateOfService"
                value={dateOfService}
                onChange={(e) => onChange(e)}
                disabled={isDisabled}
              />

              <Errors current_key="dateOfService" key="dateOfService" />
            </Form.Group>
          </Col>

          <Col xxl="2" lg="3" md="4" xs="6">
            <Form.Group className="form-group">
              <Form.Label>Bill Type</Form.Label>
              <Select
                id="billType"
                name="billType"
                options={BillTypeGlobal}
                value={BillTypeGlobal.find((b) => b.value === billType)}
                onChange={handleSelect2("billType")}
                isDisabled={isDisabled}
              />

              <Errors current_key="billType" key="billType" />
            </Form.Group>
          </Col>

          <Col xxl="2" lg="3" md="4" xs="6">
            <Form.Group className="form-group">
              <Form.Label>Total Amount</Form.Label>
              <Form.Control
                type="text"
                name="totalAmount"
                value={totalAmount}
                onChange={(e) => onChange(e)}
                disabled={isDisabled}
              />

              <Errors current_key="totalAmount" key="totalAmount" />
            </Form.Group>
          </Col>

          <Col xxl="2" lg="3" md="4" xs="6">
            <Form.Group className="form-group">
              <Form.Label>Currency</Form.Label>
              <Form.Control
                type="text"
                name="currency"
                value={currency}
                onChange={(e) => onChange(e)}
                disabled={isDisabled}
              />

              <Errors current_key="currency" key="currency" />
            </Form.Group>
          </Col>

          <Col xxl="2" lg="3" md="4" xs="6">
            <Form.Group className="form-group">
              <Form.Label>Date Of Report</Form.Label>
              <Form.Control
                type="date"
                name="dateOfReport"
                value={dateOfReport}
                onChange={(e) => onChange(e)}
                disabled={isDisabled}
              />

              <Errors current_key="dateOfReport" key="dateOfReport" />
            </Form.Group>
          </Col>

          <Col xxl="2" lg="3" md="4" xs="6">
            <Form.Group className="form-group">
              <Form.Label>Referrer NPI</Form.Label>
              <Form.Control
                type="text"
                name="referrerNPI"
                value={referrerNPI}
                onChange={(e) => onChange(e)}
                disabled={isDisabled}
              />

              <Errors current_key="referrerNPI" key="referrerNPI" />
            </Form.Group>
          </Col>

          <Col xxl="2" lg="3" md="4" xs="6">
            <Form.Group className="form-group">
              <Form.Label>Accession Number</Form.Label>
              <Form.Control
                type="text"
                name="accessionNumber"
                value={accessionNumber}
                onChange={(e) => onChange(e)}
                disabled={isDisabled}
              />

              <Errors current_key="accessionNumber" key="accessionNumber" />
            </Form.Group>
          </Col>

          {testResult || !isDisabled ? (
            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Test Result</Form.Label>
                {selectedClaimID && isDisabled ? (
                  <ViewFile filePath={testResult} clientCode={clientCode} />
                ) : (
                  <>
                    <Form.Control
                      type="file"
                      accept="application/pdf,image/jpeg,image/png"
                      name="testResult"
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />
                    <small>PDF/Image only</small>
                  </>
                )}

                <Errors current_key="testResult" key="testResult" />
              </Form.Group>
            </Col>
          ) : null}

          {order || !isDisabled ? (
            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Order</Form.Label>
                {selectedClaimID && isDisabled ? (
                  <ViewFile filePath={order} clientCode={clientCode} />
                ) : (
                  <>
                    <Form.Control
                      type="file"
                      accept="application/pdf,image/jpeg,image/png"
                      name="order"
                      onChange={(e) => onChange(e)}
                      disabled={isDisabled}
                    />
                    <small>PDF/Image only</small>
                  </>
                )}

                <Errors current_key="order" key="order" />
              </Form.Group>
            </Col>
          ) : null}

          {trace && trace.bsys && trace.bsys.status != "DONE" ? (
            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Status</Form.Label>
                <Select
                  id="status"
                  name="status"
                  options={statusOptions}
                  value={selectedStatus}
                  onChange={handleSelect}
                  isDisabled={isDisabled}
                />

                <Errors current_key="status" key="status" />
              </Form.Group>
            </Col>
          ) : null}
        </Row>

        {isEditor(loggedInUser) ? (
          <div className="float-end">
            {selectedClaimID ? (
              <React.Fragment>
                {currentClientClaim &&
                currentClientClaim["trace"]["bsys"] &&
                currentClientClaim["trace"]["bsys"]["status"] === "READY" ? (
                  <Button
                    className="me-2 btn-outline-dark"
                    type="button"
                    onClick={onClickLoadTebra}
                    disabled={loadingTebra}
                  >
                    {loadingTebra ? "Loading..." : "Load to Tebra"}
                  </Button>
                ) : null}

                {!isDisabled ? (
                  <>
                    <Button
                      className="m-2"
                      type="submit"
                      variant="primary"
                      disabled={loadingOnSubmit || isDisabled}
                    >
                      {loadingOnSubmit ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {` Loading... `}
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </Button>
                    <Button
                      className="ml-2"
                      type="reset"
                      variant="danger"
                      onClick={onClickCancel}
                      disabled={loadingOnSubmit || isDisabled}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button
                    className="btn btn-outline-dark"
                    size="sm"
                    onClick={toggleEdit}
                  >
                    Click to Edit
                  </Button>
                )}
              </React.Fragment>
            ) : (
              <Button
                className="ml-2"
                type="btn"
                variant="primary"
                onClick={onClickNext}
              >
                Next
              </Button>
            )}
          </div>
        ) : null}
      </Form>
    </React.Fragment>
  );
};

export default EncounterDataTab;
