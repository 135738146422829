import React from "react";
import { connect } from "react-redux";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { getStatusCountThisYear } from "actions/dashboardActions";
import { clientStatus } from "constants/index";
import { getClientStatusByValue } from "utils/helper";

const PatientsStatusThisYear = ({
  selectedClient,
  getStatusCountThisYear,
  loadingPatientsStatusByYear,
  currentClientPatientsStatusByYear,
  statusOptions,
}) => {
  React.useEffect(() => {
    if (!selectedClient || !selectedClient.code) return;
    getStatusCountThisYear(selectedClient.code, "patients", statusOptions);
  }, [getStatusCountThisYear, selectedClient]);

  const initialData = {};

  const [patientsCountByStatusYear, setPatientsCountByStatusYear] =
    React.useState(initialData);

  React.useEffect(() => {
    if (!currentClientPatientsStatusByYear) return;

    const currentData = [];
    for (const key in currentClientPatientsStatusByYear) {
      const monthData = currentClientPatientsStatusByYear[key];
      const { name, statusCount } = monthData;
      const monthObject = { name };

      clientStatus.forEach((status) => {
        const matchingStatus = statusCount.find(
          (item) => item._id === status.value
        );
        monthObject[status.value] = matchingStatus ? matchingStatus.count : 0;
      });

      if (monthObject.name) currentData.push(monthObject);
    }

    if (currentData.length) setPatientsCountByStatusYear(currentData);
  }, [currentClientPatientsStatusByYear]);

  return (
    <div className="text-center line-graph">
      <ResponsiveContainer width="100%" height="100%">
        {loadingPatientsStatusByYear ? (
          <span>Loading...</span>
        ) : (
          <BarChart
            width={500}
            height={300}
            data={patientsCountByStatusYear}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis type="number" allowDecimals={false} />
            <Tooltip cursor={{ fill: "transparent" }} />
            <Legend verticalAlign="top" height={36} />
            {statusOptions.length &&
              statusOptions.map((each, i) => (
                <Bar
                  key={i}
                  dataKey={each}
                  fill={
                    each === "READY"
                      ? "#42d29d"
                      : each === "ONHOLD"
                      ? "#fa6767"
                      : "#8573f5"
                  }
                  name={getClientStatusByValue(each)}
                  maxBarSize={25}
                />
              ))}
          </BarChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loadingPatientsStatusByYear: state.dashboard.loadingPatientsStatusByYear,
  currentClientPatientsStatusByYear:
    state.dashboard.currentClientPatientsStatusByYear,
});

export default connect(mapStateToProps, {
  getStatusCountThisYear,
})(PatientsStatusThisYear);
