import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";

import { RxDotsVertical } from "react-icons/rx";
import PatientsMonthlyView from "./PatientsMonthlyView";
import PatientsQuarterlyView from "./PatientsQuarterlyView";
import PatientsYearlyView from "./PatientsYearlyView";

import { capitalizeFirst } from "utils/helper";

const PatientsWithFilterView = ({ selectedClient }) => {
  const [patientsViewType, setPatientsViewType] = React.useState("monthly");

  const handleSelectPatientsFilter = (eventKey) => {
    setPatientsViewType(eventKey);
    console.log(`selected patients filter: ${eventKey}`);
  };

  return (
    <div className="crm-card">
      <h5 className="d-inline-block">
        Patients {capitalizeFirst(patientsViewType)}
      </h5>

      <Nav
        variant="patients-vdots-filter"
        activeKey={patientsViewType}
        onSelect={handleSelectPatientsFilter}
        className="float-end"
      >
        <NavDropdown
          className="vdots-filter"
          title={
            <span>
              <RxDotsVertical className="float-end" size={"25px"} />
            </span>
          }
          id="patients-filter"
        >
          <NavDropdown.Item eventKey="monthly">Monthly</NavDropdown.Item>
          <NavDropdown.Item eventKey="quarterly">Quarterly</NavDropdown.Item>
          <NavDropdown.Item eventKey="yearly">Yearly</NavDropdown.Item>
        </NavDropdown>
      </Nav>

      {patientsViewType === "monthly" ? (
        <PatientsMonthlyView selectedClient={selectedClient} />
      ) : null}

      {patientsViewType === "quarterly" ? (
        <PatientsQuarterlyView selectedClient={selectedClient} />
      ) : null}

      {patientsViewType === "yearly" ? (
        <PatientsYearlyView selectedClient={selectedClient} />
      ) : null}
    </div>
  );
};

export default PatientsWithFilterView;
