import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

import Errors from "Notifications/Errors";

import OtherDataURLs from "./OtherDataURLs";
import { isEditor } from "utils/helper";
import Select from "react-select";
import { BiPlusMedical } from "react-icons/bi";

import { otherDataValues } from "constants/index";

import ViewFile from "../ViewFile";

const OtherDataTab = ({
  otherData,
  setOtherData,
  onSubmitOtherData,
  loadingOnSubmit,
  isDisabled,
  toggleEdit,
  onClickCancel,
  loggedInUser,
  clientCode,
}) => {
  const onChange = (e, index) => {
    if (!e.target) return;
    const cpOtherData = otherData.map((data, i) => {
      const data2 = { ...data };

      if (i === index) {
        switch (e.target.name) {
          case "file":
            let file = e.target.files[0];

            if (file) {
              let reader = new FileReader();

              reader.onload = function (e) {
                let base64String = e.target.result.split(",")[1]; // Remove Base64 part
                data2["value"] = base64String;
              };

              reader.readAsDataURL(file);
            }
            break;
          case "switch":
            data2["value"] = e.target.checked ? "true" : "false";
            break;
          default:
            data2[e.target.name] = e.target.value;
        }
      }

      return data2;
    });

    setOtherData(cpOtherData);
  };

  const handleSelect = (index) => (selectedOption) => {
    const cpOtherData = otherData.map((data, i) => {
      const data2 = { ...data };
      if (i === index) {
        data2["type"] = selectedOption ? selectedOption.label : "";
        data2["inputType"] = selectedOption ? selectedOption.value : "";
      }

      return data2;
    });

    setOtherData(cpOtherData);
  };

  const onClickHadle = () => {
    setOtherData([
      ...otherData,
      {
        id: "",
        type: "",
        value: "",
      },
    ]);
  };

  const getSelectedValue = (type) => {
    const value = otherDataValues.find((each) => each.label === type);

    return value ? value : {};
  };

  const onClickRemove = (index) => {
    setOtherData((previousArr) => previousArr.filter((each, i) => i !== index));
  };

  return (
    <React.Fragment>
      <Form onSubmit={(e) => onSubmitOtherData(e)}>
        {isDisabled ? (
          <Row>
            {otherData.length
              ? otherData.map((each, i) => (
                  <React.Fragment key={i}>
                    {each.type.toLowerCase() === "file" ? (
                      <Col xxl="2" lg="3" md="4" xs="6">
                        <Form.Group className="form-group">
                          <Form.Label>
                            {each.title}(Data Type: {each.type})
                          </Form.Label>
                          <ViewFile
                            filePath={each.value}
                            clientCode={clientCode}
                          />
                        </Form.Group>
                      </Col>
                    ) : each.type.toLowerCase() === "url" ? (
                      <Col xxl="2" lg="3" md="4" xs="6">
                        <OtherDataURLs
                          onChange={onChange}
                          title={each.title}
                          value={each.value}
                          isDisabled={isDisabled}
                          i={i}
                        />
                      </Col>
                    ) : each.type.toLowerCase() === "boolean" ? (
                      <Col xxl="2" lg="3" md="4" xs="6">
                        <Form.Group className="form-group">
                          <Form.Label>
                            {each.title}(Data Type: {each.type})
                          </Form.Label>
                          <Form.Check
                            className="fancy-checkbox-switch"
                            name="switch"
                            type="switch"
                            checked={each.value === "true"}
                            id="custom-switch"
                            onChange={(e) => onChange(e, i)}
                            disabled={isDisabled}
                          />
                        </Form.Group>
                      </Col>
                    ) : (
                      <Col xxl="2" lg="3" md="4" xs="6">
                        <Form.Group className="form-group">
                          <Form.Label>{each.title}</Form.Label>
                          <Form.Control
                            type="text"
                            name="value"
                            value={each.value}
                            onChange={(e) => onChange(e, i)}
                            disabled={isDisabled}
                          />

                          <Errors current_key={each.id} key={each.id} />
                        </Form.Group>
                      </Col>
                    )}
                  </React.Fragment>
                ))
              : null}
          </Row>
        ) : (
          <Row>
            {otherData.length
              ? otherData.map((each, i) => (
                  <React.Fragment key={i}>
                    <Col xxl="2" lg="3" md="4" xs="6">
                      <Form.Group className="form-group">
                        <Form.Label>Label</Form.Label>
                        <Form.Control
                          type="text"
                          name="id"
                          value={each.id}
                          onChange={(e) => onChange(e, i)}
                        />
                      </Form.Group>
                    </Col>

                    <Col xxl="2" lg="3" md="4" xs="6">
                      <Form.Group className="form-group">
                        <Form.Label>Type</Form.Label>
                        <Select
                          id="type"
                          name="type"
                          options={otherDataValues}
                          value={getSelectedValue(each.type)}
                          onChange={handleSelect(i)}
                        />
                      </Form.Group>
                    </Col>

                    {each && each.type ? (
                      <Col xxl="2" lg="3" md="4" xs="6">
                        <Form.Label>Value</Form.Label>
                        {each.type.toLowerCase() === "boolean" ? (
                          <Form.Check
                            className="fancy-checkbox-switch"
                            name="switch"
                            type="switch"
                            checked={each.value === "true"}
                            id="custom-switch"
                            onChange={(e) => onChange(e, i)}
                          />
                        ) : each.type.toLowerCase() === "file" ? (
                          <Form.Group className="form-group">
                            <Form.Control
                              type="file"
                              accept="application/pdf,image/jpeg,image/png"
                              name="file"
                              onChange={(e) => onChange(e, i)}
                            />
                          </Form.Group>
                        ) : (
                          <Form.Group className="form-group">
                            <Form.Control
                              type={each.inputType}
                              name="value"
                              value={each.value}
                              onChange={(e) => onChange(e, i)}
                            />
                          </Form.Group>
                        )}
                      </Col>
                    ) : null}

                    <Col xxl="2" lg="3" md="4" xs="6">
                      <Button
                        className="c-red mt-4"
                        variant="link"
                        size="sm"
                        onClick={(e) => onClickRemove(i)}
                      >
                        Remove
                      </Button>
                    </Col>

                    <hr className="tab-hr-line" />
                  </React.Fragment>
                ))
              : null}

            <div>
              <Button color="primary" size="sm" onClick={(e) => onClickHadle()}>
                <BiPlusMedical /> Add New
              </Button>
            </div>
          </Row>
        )}

        {isEditor(loggedInUser) ? (
          <div className="float-end">
            {!isDisabled ? (
              <>
                <Button
                  className="m-2"
                  type="submit"
                  variant="primary"
                  disabled={loadingOnSubmit || isDisabled}
                >
                  {loadingOnSubmit ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {` Loading... `}
                    </>
                  ) : (
                    <>Save</>
                  )}
                </Button>
                <Button
                  className="ml-2"
                  type="reset"
                  variant="danger"
                  onClick={onClickCancel}
                  disabled={loadingOnSubmit || isDisabled}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                className="btn btn-outline-dark"
                size="sm"
                onClick={toggleEdit}
              >
                Click to Edit
              </Button>
            )}
          </div>
        ) : null}
      </Form>
    </React.Fragment>
  );
};

export default OtherDataTab;
