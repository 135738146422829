import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  patientsList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentPatient: null,
  loadingPatientList: true,
  loadingPatient: true,
  loadingOnSubmit: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
  patientsListAll: [],
};

const patientSlice = createSlice({
  name: "patients",
  initialState: initialState,
  reducers: {
    resetPatient(state) {
      return {
        ...initialState,
      };
    },
    loadPatient(state) {
      return {
        ...state,
        loadingPatient: false,
      };
    },
    patientUpdated(state, action) {
      return {
        ...state,
        currentPatient: action.payload,
        patientsList: {
          ...state.patientsList,
          data: state.patientsList.data.map((patient) =>
            patient._id === action.payload._id ? action.payload : patient
          ),
        },
        sortingParams: initialState.sortingParams,
        loadingOnSubmit: false,
      };
    },
    patientError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingPatient: false,
        loadingOnSubmit: false,
        loadingPatientList: false,
      };
    },
    patientDetailsById(state, action) {
      return {
        ...state,
        currentPatient: action.payload,
        loadingPatient: false,
      };
    },
    patientListUpdated(state, action) {
      return {
        ...state,
        patientsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },

        loadingPatientList: false,
      };
    },
    patientSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingPatientList: false,
      };
    },
    loadingOnPatientSubmit(state) {
      return {
        ...state,
        loadingOnSubmit: true,
      };
    },
    loadingPatient(state) {
      return {
        ...state,
        loadingPatient: true,
      };
    },
    loadingPatientsList(state) {
      return {
        ...state,
        loadingPatientList: true,
      };
    },
    patientCreated(state) {
      return {
        ...state,
        loadingOnSubmit: false,
      };
    },
    patientLoadedToTebra(state, action) {
      return {
        ...state,
        currentPatient: action.payload,
      };
    },
    patientListUpdatedAll(state, action) {
      return {
        ...state,
        patientsListAll: action.payload,
      };
    },
  },
});

export const {
  loadPatient,
  patientUpdated,
  patientError,
  patientDetailsById,
  patientListUpdated,
  patientSearchParametersUpdate,
  loadingOnPatientSubmit,
  loadingPatientsList,
  loadingPatient,
  resetPatient,
  patientCreated,
  patientLoadedToTebra,
  patientListUpdatedAll,
} = patientSlice.actions;
export default patientSlice.reducer;
