import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";

import RecordCountBox from "../RecordCountBox";

import { getSelectedClientPostDataCount } from "actions/dashboardActions";

const ClaimsCount = ({
  selectedClient,
  getSelectedClientPostDataCount,
  currentClientClaimsCount,
  loadingDashboardClientClaimsCount,
}) => {
  React.useEffect(() => {
    if (!selectedClient || !selectedClient.code) return;
    getSelectedClientPostDataCount(selectedClient.code, "claims");
  }, [getSelectedClientPostDataCount, selectedClient]);

  const initialData = {
    todayPostDataCount: 0,
    lastDayPostDataCount: 0,
    thisWeekPostDataCount: 0,
    lastWeekPostDataCount: 0,
    thisMonthPostDataCount: 0,
    lastMonthPostDataCount: 0,
    thisQuarterPostDataCount: 0,
    lastQuarterPostDataCount: 0,
    thisYearPostDataCount: 0,
    lastYearPostDataCount: 0,
  };

  const [claimsCount, setClaimsCount] = React.useState(initialData);

  React.useEffect(() => {
    if (!currentClientClaimsCount) return;

    const {
      todayPostDataCount,
      lastDayPostDataCount,
      thisWeekPostDataCount,
      lastWeekPostDataCount,
      thisMonthPostDataCount,
      lastMonthPostDataCount,
      thisQuarterPostDataCount,
      lastQuarterPostDataCount,
      thisYearPostDataCount,
      lastYearPostDataCount,
    } = currentClientClaimsCount;

    const currentData = {
      todayPostDataCount: todayPostDataCount ? todayPostDataCount : 0,
      lastDayPostDataCount: lastDayPostDataCount ? lastDayPostDataCount : 0,
      thisWeekPostDataCount: thisWeekPostDataCount ? thisWeekPostDataCount : 0,
      lastWeekPostDataCount: lastWeekPostDataCount ? lastWeekPostDataCount : 0,
      thisMonthPostDataCount: thisMonthPostDataCount
        ? thisMonthPostDataCount
        : 0,
      lastMonthPostDataCount: lastMonthPostDataCount
        ? lastMonthPostDataCount
        : 0,
      thisQuarterPostDataCount: thisQuarterPostDataCount
        ? thisQuarterPostDataCount
        : 0,
      lastQuarterPostDataCount: lastQuarterPostDataCount
        ? lastQuarterPostDataCount
        : 0,
      thisYearPostDataCount: thisYearPostDataCount ? thisYearPostDataCount : 0,
      lastYearPostDataCount: lastYearPostDataCount ? lastYearPostDataCount : 0,
    };

    setClaimsCount(currentData);
  }, [currentClientClaimsCount]);

  return (
    <div className="claims-count-box count-box">
      <Row className="d-flex first-line-data">
        <RecordCountBox
          countLabel="Claims Today"
          value={claimsCount.todayPostDataCount}
          previousLabel="Yesterday"
          previousValue={claimsCount.lastDayPostDataCount}
          loadingBox={loadingDashboardClientClaimsCount}
        />

        <RecordCountBox
          countLabel="Claims This Week"
          value={claimsCount.thisWeekPostDataCount}
          previousLabel="Last Week"
          previousValue={claimsCount.lastWeekPostDataCount}
          loadingBox={loadingDashboardClientClaimsCount}
        />

        <RecordCountBox
          countLabel="Claims This Month"
          value={claimsCount.thisMonthPostDataCount}
          previousLabel="Last Month"
          previousValue={claimsCount.lastMonthPostDataCount}
          loadingBox={loadingDashboardClientClaimsCount}
        />

        <RecordCountBox
          countLabel="Claims This Quarter"
          value={claimsCount.thisQuarterPostDataCount}
          previousLabel="Last Quarter"
          previousValue={claimsCount.lastQuarterPostDataCount}
          loadingBox={loadingDashboardClientClaimsCount}
        />

        <RecordCountBox
          countLabel="Claims YTD"
          value={claimsCount.thisYearPostDataCount}
          previousLabel="Last Year"
          previousValue={claimsCount.lastYearPostDataCount}
          loadingBox={loadingDashboardClientClaimsCount}
        />
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentClientClaimsCount: state.dashboard.currentClientClaimsCount,
  loadingDashboardClientClaimsCount:
    state.dashboard.loadingDashboardClientClaimsCount,
});

export default connect(mapStateToProps, {
  getSelectedClientPostDataCount,
})(ClaimsCount);
