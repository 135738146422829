import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  clientClaimsList: {
    page: 1,
    data: [],
    count: 0,
  },
  loadingClientClaimsList: true,
  loadingClientClaim: true,
  currentClientClaim: null,
  patientNotificationsList: {
    page: 1,
    data: [],
    count: 0,
  },
  claimNotificationsList: {
    page: 1,
    data: [],
    count: 0,
  },
  loadingPatientNotificationsList: true,
  loadingClaimNotificationsList: true,
  loadingPatientNotification: true,
  loadingClaimNotification: true,
  currentPatientNotification: null,
  currentClaimNotification: null,
  clientPatientsList: {
    page: 1,
    data: [],
    count: 0,
  },
  loadingClientPatientsList: true,
  currentClientPatient: null,
  loadingClientPatient: true,
  error: {},
  claimParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
    filters: [],
  },
  patientNotificationParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
    filters: [],
  },
  claimNotificationParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
    filters: [],
  },
  patientParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
    filters: [],
  },
};

const clientWorkspaceSlice = createSlice({
  name: "clientWorkspaces",
  initialState: initialState,
  reducers: {
    resetClientWorkspace(state) {
      return {
        ...initialState,
      };
    },
    clientClaimError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingClientClaimsList: false,
        loadingClientClaim: false,
      };
    },
    clientClaimDetailsLoading(state, action) {
      return {
        ...state,
        currentClientClaim: null,
        loadingClientClaim: true,
      };
    },
    clientClaimDetailsById(state, action) {
      return {
        ...state,
        currentClientClaim: action.payload,
        loadingClientClaim: false,
      };
    },
    clientClaimsListUpdated(state, action) {
      return {
        ...state,
        clientClaimsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingClientClaimsList: false,
      };
    },
    clientClaimsSearchParametersUpdated(state, action) {
      return {
        ...state,
        claimParams: { ...action.payload },
        loadingClientClaimsList: false,
      };
    },
    loadingClientClaimsList(state) {
      return {
        ...state,
        loadingClientClaimsList: true,
      };
    },
    patientNotificationError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingpPatientNotificationsList: false,
        loadingPatientNotification: false,
      };
    },
    claimNotificationError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingClaimNotificationsList: false,
        loadingClaimNotification: false,
      };
    },
    loadingPatientNotificationDetails(state) {
      return {
        ...state,
        loadingPatientNotification: true,
      };
    },
    patientNotificationDetailsById(state, action) {
      return {
        ...state,
        currentPatientNotification: action.payload,
        loadingPatientNotification: false,
      };
    },
    loadingClaimNotificationDetails(state) {
      return {
        ...state,
        loadingClaimNotification: true,
      };
    },
    claimNotificationDetailsById(state, action) {
      return {
        ...state,
        currentClaimNotification: action.payload,
        loadingClaimNotification: false,
      };
    },
    patientNotificationsListUpdated(state, action) {
      return {
        ...state,
        patientNotificationsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingPatientNotificationsList: false,
      };
    },
    claimNotificationsListUpdated(state, action) {
      return {
        ...state,
        claimNotificationsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingClaimNotificationsList: false,
      };
    },
    patientNotificationsSearchParametersUpdated(state, action) {
      return {
        ...state,
        patientNotificationParams: { ...action.payload },
        loadingPatientNotificationsList: false,
      };
    },
    claimNotificationsSearchParametersUpdated(state, action) {
      return {
        ...state,
        claimNotificationParams: { ...action.payload },
        loadingClaimNotificationsList: false,
      };
    },
    loadingPatientNotificationsList(state) {
      return {
        ...state,
        loadingPatientNotificationsList: true,
      };
    },
    loadingClaimNotificationsList(state) {
      return {
        ...state,
        loadingClaimNotificationsList: true,
      };
    },
    clientPatientError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingClientPatientsList: false,
        loadingClientPatient: false,
      };
    },
    clientPatientDetailsLoading(state, action) {
      return {
        ...state,
        currentClientPatient: null,
        loadingClientPatient: true,
      };
    },
    clientPatientDetailsById(state, action) {
      return {
        ...state,
        currentClientPatient: action.payload,
        loadingClientPatient: false,
      };
    },
    clientPatientsListUpdated(state, action) {
      return {
        ...state,
        clientPatientsList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingClientPatientsList: false,
      };
    },
    clientPatientsSearchParametersUpdated(state, action) {
      return {
        ...state,
        patientParams: { ...action.payload },
        loadingClientPatientsList: false,
      };
    },
    loadingClientPatientsList(state) {
      return {
        ...state,
        loadingClientPatientsList: true,
      };
    },
  },
});

export const {
  resetClientWorkspace,
  clientClaimError,
  clientClaimDetailsById,
  clientClaimsListUpdated,
  clientClaimsSearchParametersUpdated,
  loadingClientClaimsList,
  patientNotificationError,
  patientNotificationDetailsById,
  patientNotificationsListUpdated,
  patientNotificationsSearchParametersUpdated,
  loadingPatientNotificationsList,
  clientPatientError,
  clientPatientDetailsLoading,
  clientPatientDetailsById,
  clientPatientsListUpdated,
  clientPatientsSearchParametersUpdated,
  loadingClientPatientsList,
  loadingClaimNotificationsList,
  claimNotificationsSearchParametersUpdated,
  claimNotificationsListUpdated,
  claimNotificationError,
  clientClaimDetailsLoading,
  claimNotificationDetailsById,
  loadingPatientNotificationDetails,
  loadingClaimNotificationDetails,
} = clientWorkspaceSlice.actions;
export default clientWorkspaceSlice.reducer;
