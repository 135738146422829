import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingList,
  searchParametersUpdate,
  listUpdated,
  loadingOnSubmit,
  recordSaved,
  insuranceError,
  recordDeleted,
  resetStore,
  listUpdatedAll,
  insuranceStatusUpdated,
} from "reducers/insuranceReducer";

export const getList = (params, partner_id) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = params.query ? params.query : "";
    params.query = query;
    config.params = params;

    dispatch(loadingList());
    const res = await axios.get(
      `/api/admin/partners/${partner_id}/insurances`,
      config
    );

    dispatch(searchParametersUpdate(params));

    dispatch(listUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          insuranceError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const save = (formData, partner_id) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnSubmit());

    const res = await axios.post(
      `/api/admin/partners/${partner_id}/insurance`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(recordSaved(res.data.response));

      dispatch(setAlert("Insurance saved.", "success"));
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(insuranceError());
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          insuranceError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const edit =
  (formData, partner_id, insurance_id) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnSubmit());

      const res = await axios.put(
        `/api/admin/partners/${partner_id}/insurance/${insurance_id}`,
        formData,
        config
      );

      if (res.data.status === true) {
        dispatch(recordSaved(res.data.response));

        dispatch(setAlert("Insurance updated.", "success"));
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(insuranceError());
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            insuranceError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.data.message, "danger"));
      }
    }
  };

export const deleteRecord = (partner_id, insurance_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(
      `/api/admin/partners//${partner_id}/insurance/${insurance_id}`,
      config
    );

    dispatch(recordDeleted(insurance_id));
    dispatch(setAlert("Insurance deleted", "success"));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          insuranceError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const changeInsuranceStatusByID =
  (partner_id, insurance_id, status) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `/api/admin/partners/${partner_id}/insurance/${insurance_id}/update-status`,
        { status },
        config
      );

      if (res.data.status === true) {
        await dispatch(
          insuranceStatusUpdated({
            insurance_id,
            status,
          })
        );
        dispatch(setAlert(res.data.message, "success"));
        return res.data ? res.data : { status: false };
      } else {
        const errors = res.data.errors;
        if (errors.length) {
          dispatch(insuranceError());
          dispatch(setAlert(errors[0].msg, "danger"));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.data && err.response.data.tokenStatus === 0) {
          dispatch(logout());
        } else {
          dispatch(
            insuranceError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
          dispatch(
            setAlert(
              err.response.data.message || err.response.statusText,
              "danger"
            )
          );
        }
      }
    }
  };

// Delete Physician
export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/clients");
};

// page not found
export const notFound = (navigate) => async (dispatch) => {
  navigate("/admin/page-not-found");
};

// reset errors
export const removePageErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetStore());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(insuranceError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const getListWoPagination = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(`/api/admin/partners/all-insurances`, config);

    dispatch(listUpdatedAll(res.data.response));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(setAlert("Invalid Access", "danger"));
    } else {
      err.response &&
        dispatch(
          insuranceError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.data.message, "danger"));
    }

    return err ? err.response : {};
  }
};
