import axios from "axios";
import { setAlert, removeAlert } from "./alert";

import { setErrorsList } from "./errors";
import { logout } from "actions/auth";

import { removeErrors } from "reducers/errors";

import {
  patientUpdated,
  patientError,
  patientDetailsById,
  patientListUpdated,
  patientSearchParametersUpdate,
  loadingOnPatientSubmit,
  loadingPatientsList,
  loadingPatient,
  resetPatient,
  patientCreated,
  patientLoadedToTebra,
  patientListUpdatedAll,
} from "reducers/patient";

export const getClientPatientDetailsById =
  (patient_id, client_code) => async (dispatch) => {
    dispatch(removeErrors());
    try {
      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingPatient());

      const res = await axios.get(
        `/api/admin/client-workspace/${client_code}/patients/${patient_id}`,
        config
      );

      dispatch(patientDetailsById(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            patientError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const create = (formData, client_code) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnPatientSubmit());

    const res = await axios.post(
      `/api/admin/client-workspace/${client_code}/patients/create`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(patientCreated());
      dispatch(setAlert("Patient Created.", "success"));
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(patientError());
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          patientError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const updatePatientData =
  (patientData, client_code, patient_id, navigate) => async (dispatch) => {
    try {
      dispatch(removeErrors());

      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnPatientSubmit());

      const res = await axios.put(
        `/api/admin/client-workspace/${client_code}/patients/${patient_id}`,
        patientData,
        config
      );

      if (res.data.status === true) {
        dispatch(patientUpdated(res.data.response));
        dispatch(removeAlert());
        dispatch(setAlert("Patient updated.", "success"));
        // navigate(`/admin/clients/${client_code}/workspace/patients`);
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(patientError());
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            patientError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const updateResponsiblePartyData =
  (formData, client_code, patient_id, navigate) => async (dispatch) => {
    try {
      dispatch(removeErrors());

      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnPatientSubmit());

      const res = await axios.put(
        `/api/admin/client-workspace/${client_code}/patients/${patient_id}/responsible-party`,
        formData,
        config
      );
      if (res.data.status === true) {
        dispatch(patientUpdated(res.data.response));
        dispatch(removeAlert());
        dispatch(setAlert("Patient updated.", "success"));
        // navigate(`/admin/clients/${client_code}/workspace/patients`);
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(setAlert(res.data.message, "danger"));
          dispatch(patientError());
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            patientError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const updateInsuranceData =
  (insuranceData, client_code, patient_id, navigate) => async (dispatch) => {
    try {
      dispatch(removeErrors());

      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnPatientSubmit());

      const data = {
        insurance: insuranceData,
      };

      const res = await axios.put(
        `/api/admin/client-workspace/${client_code}/patients/${patient_id}/insurance-data`,
        data,
        config
      );
      if (res.data.status === true) {
        dispatch(patientUpdated(res.data.response));
        dispatch(removeAlert());
        dispatch(setAlert("Patient updated.", "success"));
        // navigate(`/admin/clients/${client_code}/workspace/patients`);
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(patientError());
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            patientError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const updatePatientOtherData =
  (otherData, client_code, patient_id, navigate) => async (dispatch) => {
    try {
      dispatch(removeErrors());

      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnPatientSubmit());

      const data = {
        otherData,
      };

      const res = await axios.put(
        `/api/admin/client-workspace/${client_code}/patients/${patient_id}/other-data`,
        data,
        config
      );
      if (res.data.status === true) {
        dispatch(patientUpdated(res.data.response));
        dispatch(removeAlert());
        dispatch(setAlert("Patient other data updated.", "success"));
      } else {
        const errors = res.data.errors;
        dispatch(patientError());
        if (errors) {
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            patientError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getClientPatientsList =
  (patientParams, client_code) => async (dispatch) => {
    dispatch(removeErrors());
    try {
      const config = {
        "Content-Type": "application/json",
      };

      const query = patientParams.query ? patientParams.query : "";
      patientParams.query = query;
      config.params = patientParams;

      dispatch(loadingPatientsList());
      const res = await axios.get(
        `/api/admin/client-workspace/${client_code}/patients/list`,
        config
      );

      dispatch(patientSearchParametersUpdate(patientParams));

      dispatch(patientListUpdated(res.data.response[0]));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            patientError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(removeErrors());
  await dispatch(resetPatient());
};

export const removePatientErrors = () => async (dispatch) => {
  await dispatch(removeErrors());
};

export const loadPatientToTebra =
  (patient_id, client_code) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      // dispatch(loadingOnPatientSubmit());

      const formData = {};
      const res = await axios.post(
        `/api/admin/client-workspace/${client_code}/patients/${patient_id}/load-tebra`,
        formData,
        config
      );

      if (res.data.status === true) {
        dispatch(patientLoadedToTebra(res.data.response));
        dispatch(setAlert("Patient Loaded to Tebra.", "success"));
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(setAlert(res.data.message, "danger"));
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        if (err.response && err.response.data) {
          dispatch(setAlert(err.response.data.message, "danger"));
        }
      }
    }
  };

export const getClientPatientsListAll = (client_code) => async (dispatch) => {
  dispatch(removeErrors());
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      `/api/admin/client-workspace/${client_code}/patients/list-all`,
      config
    );

    dispatch(patientListUpdatedAll(res.data.response));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          patientError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};
