import axios from "axios";
import { removeAlert, setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  clientCreated,
  loadClientPage,
  clientUpdated,
  clientError,
  clientDeleted,
  clientDetailsById,
  clientListUpdated,
  clientSearchParametersUpdate,
  loadingOnClientSubmit,
  loadingClientsList,
  resetClient,
} from "reducers/client";

export const getClientsList = (clientParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = clientParams.query ? clientParams.query : "";
    clientParams.query = query;
    config.params = clientParams;

    dispatch(loadingClientsList());
    const res = await axios.get("/api/admin/clients/list", config);

    dispatch(clientSearchParametersUpdate(clientParams));

    dispatch(clientListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          clientError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

// get Client by id
export const getClientById = (client_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnClientSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/admin/clients/${client_id}`, config);
    // dispatch({ type: REMOVE_ALERT });

    await dispatch(clientDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          clientError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const create = (formData, navigate) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnClientSubmit());

    const res = await axios.post("/api/admin/clients", formData, config);
    if (res.data.status === true) {
      dispatch(clientCreated(res.data.response));
      // dispatch({ type: REMOVE_ALERT });
      dispatch(setAlert("Client Created.", "success"));
      navigate(`/admin/clients`);
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(clientError());
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          clientError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

// Edit Client
export const edit = (formData, navigate, client_id) => async (dispatch) => {
  dispatch(removeErrors());
  dispatch(loadingOnClientSubmit());
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.put(
      `/api/admin/clients/${client_id}`,
      formData,
      config
    );
    if (res.data.status === true) {
      dispatch(clientUpdated(res.data.response));
      dispatch(setAlert("Client Updated.", "success"));
      navigate("/admin/clients");
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(clientError());
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          clientError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

// Delete Client
export const deleteClient = (client_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(`/api/admin/clients/${client_id}`, config);

    dispatch(clientDeleted(client_id));
    dispatch(setAlert("Client deleted", "success"));
  } catch (err) {
    console.error(err);
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
    err.response &&
      dispatch(
        clientError({
          msg: err.response.data.message || err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

// Delete Physician
export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/clients");
};

// page not found
export const notFound = (navigate) => async (dispatch) => {
  navigate("/admin/page-not-found");
};

// reset errors
export const removeClientErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetClient());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(clientError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

// Load Page/Show Page
export const loadPage = () => async (dispatch) => {
  dispatch(loadClientPage());
};

export const getClientsWoPagination = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(
      `/api/admin/clients/list-wo-pagination`,
      config
    );

    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      dispatch(
        clientError({
          msg: err.response.statusText,
          status: err.response.status,
        })
      );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};
