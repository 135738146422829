import React from "react";
import { Row, Col } from "react-bootstrap";

import { BsArrowUp, BsArrowDown } from "react-icons/bs";

const RecordCountBox = ({
  countLabel,
  value,
  previousLabel,
  previousValue,
  loadingBox,
}) => {
  const formatNumber = (number) => {
    const roundedNumber = parseFloat(number).toFixed(2);
    return Number.isInteger(number) ? number.toString() : roundedNumber;
  };

  const [valuePercent, setValuePercent] = React.useState(null);
  React.useEffect(() => {
    let change = 0;
    if (previousValue !== 0) {
      change = ((value - previousValue) / previousValue) * 100;
    } else if (value !== 0) {
      change = ((value - previousValue) / value) * 100;
    }

    change = Math.abs(change);
    change = formatNumber(change);

    setValuePercent(change);
  }, [value, previousValue]);

  const classes = ` mt-3 ${previousValue > value ? "c-red" : "c-green"}`;

  return (
    <Col xs="12" sm="12" className="col">
      <div className="crm-card">
        <h6>{countLabel}</h6>
        {loadingBox ? (
          <span>Loading...</span>
        ) : (
          <React.Fragment>
            <strong>{value}</strong>
            <span className="d-block">
              <span className={classes}>
                <React.Fragment>
                  {previousValue > value ? <BsArrowDown /> : <BsArrowUp />}
                  <span>{valuePercent}%</span>
                </React.Fragment>
              </span>

              <span title={`${previousLabel} Count`} className="previous-value">
                {`${previousLabel}: ${previousValue}`}
              </span>
            </span>
          </React.Fragment>
        )}
      </div>
    </Col>
  );
};

export default RecordCountBox;
