import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";

import { RxDotsVertical } from "react-icons/rx";

import ClaimsMonthlyView from "./ClaimsMonthlyView";
import ClaimsQuarterlyView from "./ClaimsQuarterlyView";
import ClaimsYearlyView from "./ClaimsYearlyView";

import { capitalizeFirst } from "utils/helper";

const ClaimsWithFilterView = ({ selectedClient }) => {
  const [claimViewType, setClaimViewType] = React.useState("monthly");

  const handleSelectClaimFilter = (eventKey) => {
    setClaimViewType(eventKey);
    console.log(`selected claim filter: ${eventKey}`);
  };

  return (
    <div className="crm-card">
      <h5 className="d-inline-block">
        Claims {capitalizeFirst(claimViewType)}
      </h5>

      <Nav
        variant="claims-vdots-filter"
        activeKey={claimViewType}
        onSelect={handleSelectClaimFilter}
        className="float-end"
      >
        <NavDropdown
          className="vdots-filter"
          title={
            <span>
              <RxDotsVertical className="float-end" size={"25px"} />
            </span>
          }
          id="claims-filter"
        >
          <NavDropdown.Item eventKey="monthly">Monthly</NavDropdown.Item>
          <NavDropdown.Item eventKey="quarterly">Quarterly</NavDropdown.Item>
          <NavDropdown.Item eventKey="yearly">Yearly</NavDropdown.Item>
        </NavDropdown>
      </Nav>

      {claimViewType === "monthly" ? (
        <ClaimsMonthlyView selectedClient={selectedClient} />
      ) : null}
      {claimViewType === "quarterly" ? (
        <ClaimsQuarterlyView selectedClient={selectedClient} />
      ) : null}
      {claimViewType === "yearly" ? (
        <ClaimsYearlyView selectedClient={selectedClient} />
      ) : null}
    </div>
  );
};

export default ClaimsWithFilterView;
