import React from "react";
import moment from "moment";
import { Form, Dropdown } from "react-bootstrap";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import { getDateRange } from "utils/helper";

const LogFilters = (props) => {
  const {
    searchDelay = 750,
    filter,
    filterName,
    type,
    filterParams,
    onFilterChange,
    filterType,
  } = props;

  let delayTimer;

  const [selectedOption, setSelectedOption] = React.useState("All");
  const [logRangeFilter, setLogRangeFilter] = React.useState({
    logStartDate: "",
    logEndDate: "",
  });

  let { logStartDate, logEndDate } = logRangeFilter;

  const handleSelect = (option, date_picker) => {
    let optionValue;

    setSelectedOption(option.label);

    if (!date_picker && option.value === "custom") {
      setLogRangeFilter({
        logStartDate: "",
        logEndDate: "",
      });
      return;
    }

    if (type === "date-range-picker") {
      if (option.value === "all") {
        setLogRangeFilter({
          logStartDate: "",
          logEndDate: "",
        });
        optionValue = "";
      } else if (option.value === "custom") {
        const { startDate, endDate } = date_picker;
        setLogRangeFilter({
          logStartDate: moment(startDate).format("MM/DD/YYYY"),
          logEndDate: moment(endDate).format("MM/DD/YYYY"),
        });

        optionValue =
          moment(startDate).format("MM/DD/YYYY HH:mm:ss") +
          "-" +
          moment(endDate).format("MM/DD/YYYY HH:mm:ss");
      } else {
        const { dateFrom, dateTo } = getDateRange(option.value);
        setLogRangeFilter({
          logStartDate: moment(dateFrom).format("MM/DD/YYYY"),
          logEndDate: moment(dateTo).format("MM/DD/YYYY"),
        });
        optionValue =
          moment(dateFrom).format("MM/DD/YYYY HH:mm:ss") +
          "-" +
          moment(dateTo).format("MM/DD/YYYY HH:mm:ss");
      }
    }

    let filters, params;

    if (optionValue === "all" || optionValue === "") {
      filters = filterParams.filters.filter((item) => item !== filter);

      const temp = {};
      params = {
        ...filterParams,
        filters,
      };
      for (var i in params.query) {
        if (i === filter) continue;
        temp[i] = params.query[i];
      }
      params.query = temp;
    } else {
      filters = filterParams.filters.includes(filter)
        ? filterParams.filters
        : [...filterParams.filters, filter];

      params = {
        ...filterParams,
        filters,
      };

      params.query = {
        ...params.query,
        [filter]: {
          value: optionValue,
          type: filterType,
        },
      };
    }

    // to bring on first page on every filter change
    params.page = 1;

    onFilterChange(params);
  };

  React.useEffect(() => {
    if (selectedOption === "Custom") {
      setLogRangeFilter({
        logStartDate: "",
        logEndDate: "",
      });
    }
  }, [selectedOption]);

  const dateRangeOptions = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "last-1-hour",
      label: "Last 1 hour",
    },
    {
      value: "last-6-hours",
      label: "Last 6 hours",
    },
    {
      value: "last-24-hours",
      label: "Last 24 hours",
    },
    {
      value: "custom",
      label: "Custom",
    },
  ];

  return (
    <>
      {type === "date-range-picker" ? (
        <Form.Group>
          <Form.Label>{filterName ? filterName : filter}</Form.Label>

          <div className="d-flex">
            <Dropdown>
              <Dropdown.Toggle className="btn-light">
                {selectedOption}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {dateRangeOptions
                  ? dateRangeOptions.map((each, i) => (
                      <Dropdown.Item
                        onClick={(e) => handleSelect(each)}
                        key={i}
                      >
                        {each.label}
                      </Dropdown.Item>
                    ))
                  : null}
              </Dropdown.Menu>
            </Dropdown>

            {selectedOption === "Custom" ? (
              <>
                <DateRangePicker
                  showDropdowns={true}
                  onApply={(e, picker) =>
                    handleSelect({ label: "Custom", value: "custom" }, picker)
                  }
                  autoApply={true}
                >
                  <Form.Control
                    className="ml-2 date-range-input"
                    type="text"
                    readOnly
                    defaultValue=""
                    value={
                      logStartDate
                        ? logStartDate + " - " + logEndDate
                        : "Select Date Range"
                    }
                    placeholder="Select Date Range"
                  />
                </DateRangePicker>
              </>
            ) : null}
          </div>
        </Form.Group>
      ) : null}
    </>
  );
};

export default LogFilters;
