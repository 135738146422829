import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";

import { getFileBase64 } from "actions/clientWorkspaceActions";
import { showBase64ToNewWindow } from "utils/helper";

const ViewFile = ({ filePath, clientCode, getFileBase64 }) => {
  const [loading, setLoading] = React.useState(false);

  const onClickViewFile = async (e) => {
    e.preventDefault();

    setLoading(true);
    getFileBase64(filePath, clientCode).then((base64Data) => {
      setLoading(false);

      if (base64Data) {
        return showBase64ToNewWindow(base64Data);
      } else {
        console.error("File not found.");
      }
    });
  };

  return (
    <React.Fragment>
      <Button
        className="btn-outline-secondary d-block"
        onClick={(e) => onClickViewFile(e)}
        disabled={loading}
      >
        {loading ? "Loading File..." : "View File"}
      </Button>
    </React.Fragment>
  );
};

export default connect(null, {
  getFileBase64,
})(ViewFile);
