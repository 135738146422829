import moment from "moment";
import { detectMimeType } from "type-files";
import { clientStatus, BIPAStatusGlobal } from "constants/index";

export const capitalizeFirst = (text) => {
  if (!text) return "";
  const [first, ...rest] = text;
  return first.toUpperCase() + rest.join("");
};

export const dividePascalCaseString = (str) => {
  return str.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
};

export const isAdmin = (user) => {
  return user && user.role === 2 ? true : false;
};

export const isEditor = (user) => {
  return user && (user.role === 2 || user.role === 3) ? true : false;
};

export const userNameToShow = (fullname) => {
  fullname = fullname.trim();

  if (fullname) {
    let extractedInitials = fullname.split(" ");
    extractedInitials = extractedInitials.filter((n) => n);

    const firstName = extractedInitials[0];
    const middleName = extractedInitials[1] ? extractedInitials[1] : "";

    return firstName + " " + middleName;
  }
  return fullname;
};

export const getDateRange = (option) => {
  let dateFrom = "";
  let dateTo = "";

  let todayDate = moment();

  switch (option) {
    case "today":
      dateFrom = todayDate.clone().startOf("day").format();
      dateTo = todayDate.clone().endOf("day").format();
      break;
    case "yesterday":
      dateFrom = todayDate.clone().subtract(1, "days").startOf("day").format();
      dateTo = todayDate.clone().subtract(1, "days").endOf("day").format();
      break;
    case "last-7-days":
      dateFrom = todayDate.clone().subtract(7, "days").startOf("day").format();
      dateTo = todayDate.clone().endOf("day").format();
      break;
    case "last-30-days":
      dateFrom = todayDate.clone().subtract(1, "month").startOf("day").format();
      dateTo = todayDate.clone().endOf("day").format();
      break;
    case "last-1-hour":
      dateFrom = todayDate.clone().subtract(1, "hours").format();
      dateTo = todayDate.clone().format();
      break;
    case "last-6-hours":
      dateFrom = todayDate.clone().subtract(6, "hours").format();
      dateTo = todayDate.clone().format();
      break;
    case "last-24-hours":
      dateFrom = todayDate.clone().subtract(1, "day").format();
      dateTo = todayDate.clone().format();
      break;
    default:
      dateTo = "";
      dateFrom = "";
  }

  return {
    dateFrom,
    dateTo,
  };
};

export const checkBase64Type = (base64String) => {
  const cleanedBase64 = base64String.replace(/\s/g, "");

  // Decode the Base64 string to binary data
  const binaryData = window.atob(cleanedBase64);

  // Check for common patterns in the binary data to make an educated guess
  if (hasImagePattern(binaryData)) {
    return "image";
  } else if (hasPDFPattern(binaryData)) {
    return "pdf";
  } else {
    return "unknown";
  }
};

function hasImagePattern(binaryData) {
  return (
    binaryData.includes("\xFF\xD8\xFF") ||
    binaryData.includes("PNG") ||
    binaryData.includes("GIF")
  );
}

function hasPDFPattern(binaryData) {
  // Check for common patterns in PDF binary data
  return binaryData.includes("%PDF-");
}

export const isBase64String = (str) => {
  // Base64 characters and padding regex pattern
  const base64Regex = /^[A-Za-z0-9+/=]+$/;

  // Check if the string matches the Base64 pattern and has correct padding
  return base64Regex.test(str) && str.length % 4 === 0 && str.length >= 4;
};

export const getClientStatusByValue = (value) => {
  if (!value) return "";

  const status = clientStatus.find((each) => each.value === value);
  if (!status) return "";

  return status.label;
};

export const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

function detectMimeTypeManually(b64) {
  const signatures = {
    JVBERi0: "application/pdf",
    R0lGODdh: "image/gif",
    R0lGODlh: "image/gif",
    iVBORw0KGgo: "image/png",
    "/9j/": "image/jpg",
  };

  for (var s in signatures) {
    if (b64.indexOf(s) === 0) {
      return signatures[s];
    }
  }
}

export const convertBase64ToBlob = (base64String) => {
  const binaryData = atob(base64String);
  const byteArray = new Uint8Array(binaryData.length);

  for (let i = 0; i < binaryData.length; i++) {
    byteArray[i] = binaryData.charCodeAt(i);
  }

  const contentType = detectMimeType(base64String);

  if (!contentType) return;
  const { mime } = contentType;
  return new Blob([byteArray], { type: mime });
};

export const showBase64ToNewWindow = (base64String) => {
  if (!base64String) return;
  const blob = convertBase64ToBlob(base64String);
  if (!blob) return;
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl, "_blank");
};

export const capitalizeInput = (e) => {
  e.target.value = ("" + e.target.value).toUpperCase();
};

export const getBI_PAStatusByValue = (value) => {
  const filtered = BIPAStatusGlobal.find((each) => each.value === value);

  if (!filtered) return {};

  return filtered;
};

export const getPatientFullAddress = (patient) => {
  const address = [
    patient?.address1,
    patient?.address2,
    patient?.city,
    patient?.state,
    patient?.country,
    patient?.zip,
  ]
    .filter(Boolean)
    .join(", ");

  return address;
};

export const allowNumericOnly = (event) => {
  let ASCIICode = event.which ? event.which : event.keyCode;
  if (ASCIICode != 46 && ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    event.preventDefault();
  }
};
