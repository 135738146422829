import React from "react";
import { Row, Col } from "react-bootstrap";

import ClaimsWeeklyView from "./ClaimsWeeklyView";
import ClaimsWithFilterView from "./ClaimsWithFilterView";

const ClaimsOverview = ({ selectedClient }) => {
  return (
    <Row>
      <Col md="6">
        <ClaimsWeeklyView selectedClient={selectedClient} />
      </Col>
      <Col md="6">
        <ClaimsWithFilterView selectedClient={selectedClient} />
      </Col>
    </Row>
  );
};

export default ClaimsOverview;
