import React from "react";
import moment from "moment";
import { Form, Dropdown } from "react-bootstrap";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import { getDateRange } from "utils/helper";
const ClaimNotificationFilters = (props) => {
  const {
    type,
    filterName,
    filter,
    searchDelay = 750,
    filterParams,
    filterType,
    onFilterChange,
  } = props;

  let delayTimer;

  const [selectedOption, setSelectedOption] = React.useState("All");

  const [orderRangeFilter, setOrderRangeFilter] = React.useState({
    orderStartDate: "",
    orderEndDate: "",
  });

  let { orderStartDate, orderEndDate } = orderRangeFilter;

  const handleSearch = (e) => {
    clearTimeout(delayTimer);
    const text = e.target.value;
    delayTimer = setTimeout(() => {
      props.onSearch("search", text);
    }, searchDelay);
  };

  const handleSelect = (option, date_picker) => {
    let optionValue;
    setSelectedOption(option.title);

    if (!date_picker && option.value === "custom") {
      setOrderRangeFilter({
        orderStartDate: "",
        orderEndDate: "",
      });
      return;
    }

    if (type === "date-range-picker") {
      if (option.value === "all") {
        setOrderRangeFilter({
          orderStartDate: "",
          orderEndDate: "",
        });
        optionValue = "";
      } else if (option.value === "custom") {
        const { startDate, endDate } = date_picker;
        setOrderRangeFilter({
          orderStartDate: moment(startDate).format("MM/DD/YYYY"),
          orderEndDate: moment(endDate).format("MM/DD/YYYY"),
        });

        optionValue =
          moment(startDate).format("MM/DD/YYYY HH:mm:ss") +
          "-" +
          moment(endDate).format("MM/DD/YYYY HH:mm:ss");
      } else {
        const { dateFrom, dateTo } = getDateRange(option.value);
        setOrderRangeFilter({
          orderStartDate: moment(dateFrom).format("MM/DD/YYYY"),
          orderEndDate: moment(dateTo).format("MM/DD/YYYY"),
        });
        optionValue =
          moment(dateFrom).format("MM/DD/YYYY HH:mm:ss") +
          "-" +
          moment(dateTo).format("MM/DD/YYYY HH:mm:ss");
      }
    }

    let filters, params;

    if (optionValue === "all" || optionValue === "") {
      filters = filterParams.filters.filter((item) => item !== filter);

      const temp = {};
      params = {
        ...filterParams,
        filters,
      };
      for (var i in params.query) {
        if (i === filter) continue;
        temp[i] = params.query[i];
      }
      params.query = temp;
    } else {
      filters = filterParams.filters.includes(filter)
        ? filterParams.filters
        : [...filterParams.filters, filter];

      params = {
        ...filterParams,
        filters,
      };

      params.query = {
        ...params.query,
        [filter]: {
          value: optionValue,
          type: filterType,
        },
      };
    }

    // to bring on first page on every filter change
    params.page = 1;

    onFilterChange(params);
  };

  React.useEffect(() => {
    if (selectedOption === "Custom") {
      setOrderRangeFilter({
        orderStartDate: "",
        orderEndDate: "",
      });
    }
  }, [selectedOption]);

  const dateRangeOptions = [
    {
      title: "All",
      value: "all",
    },
    {
      title: "Today",
      value: "today",
    },
    {
      title: "Yesterday",
      value: "yesterday",
    },
    {
      title: "Last 7 days",
      value: "last-7-days",
    },
    {
      title: "Last 30 days",
      value: "last-30-days",
    },
    {
      title: "Custom",
      value: "custom",
    },
  ];

  return (
    <>
      {props.onSearch ? (
        <Form.Group>
          <Form.Label htmlFor="search">Search</Form.Label>
          <Form.Control
            type="text"
            placeholder="Search by Error Message"
            title="Search by Error Message"
            // name="search"
            // id="search"
            onChange={(e) => handleSearch(e)}
          />
        </Form.Group>
      ) : null}

      {type === "date-range-picker" ? (
        <Form.Group>
          <Form.Label>{filterName ? filterName : filter}</Form.Label>

          <div className="d-flex">
            <Dropdown>
              <Dropdown.Toggle className="btn-light">
                {selectedOption}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {dateRangeOptions
                  ? dateRangeOptions.map((each, i) => (
                      <Dropdown.Item
                        onClick={(e) => handleSelect(each)}
                        key={i}
                      >
                        {each.title}
                      </Dropdown.Item>
                    ))
                  : null}
              </Dropdown.Menu>
            </Dropdown>

            {selectedOption === "Custom" ? (
              <>
                <DateRangePicker
                  showDropdowns={true}
                  onApply={(e, picker) =>
                    handleSelect({ title: "Custom", value: "custom" }, picker)
                  }
                  autoApply={true}
                >
                  <Form.Control
                    className="ml-2 date-range-input"
                    type="text"
                    readOnly
                    defaultValue=""
                    value={
                      orderStartDate
                        ? orderStartDate + " - " + orderEndDate
                        : "Select Date Range"
                    }
                    placeholder="Select Date Range"
                  />
                </DateRangePicker>
              </>
            ) : null}
          </div>
        </Form.Group>
      ) : null}
    </>
  );
};

export default ClaimNotificationFilters;
