import React from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import {
  removePageErrors,
  save,
  getList,
  setErrors,
  edit,
} from "actions/insuranceActions";

import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";

const InsuranceModal = ({
  modal,
  setModal,
  toggle,
  partner,
  selectedID,
  setSelectedID,
  loadingRecord,
  setErrors,
  removePageErrors,
  save,
  edit,
  getList,
  List,
}) => {
  const { partner_id } = useParams();
  const initialData = {
    InternalInsCoID: "",
    InternalInsCoName: "",
    PartnerInsCoID: "",
    PartnerInsCoName: "",
    PartnerPlanID: "",
    PartnerPlanName: "",
    comments: "",
  };
  const [formData, setFormData] = React.useState(initialData);
  const [currentRecord, setCurrentRecord] = React.useState(null);

  const {
    InternalInsCoID,
    InternalInsCoName,
    PartnerInsCoID,
    PartnerInsCoName,
    PartnerPlanID,
    PartnerPlanName,
    comments,
  } = formData;

  React.useEffect(() => {
    setFormData(initialData);

    removePageErrors();

    if (!selectedID) return;

    const selectedRow = List.data.find((row) => row._id === selectedID);
    setCurrentRecord((old) => ({ ...old, ...selectedRow }));
  }, [selectedID, modal]);

  React.useEffect(() => {
    if (!currentRecord) return;

    const {
      InternalInsCoID,
      InternalInsCoName,
      PartnerInsCoID,
      PartnerInsCoName,
      PartnerPlanID,
      PartnerPlanName,
      comments,
    } = currentRecord;

    const data = {
      InternalInsCoID,
      InternalInsCoName,
      PartnerInsCoID,
      PartnerInsCoName,
      PartnerPlanID,
      PartnerPlanName,
      comments,
    };
    setFormData((form) => ({ ...form, ...data }));
  }, [currentRecord]);

  const onChange = (e) => {
    if (!e.target) return;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    removePageErrors();

    let validationRules = [
      {
        param: "InternalInsCoID",
        msg: "The Insurance company ID is required",
      },
      {
        param: "InternalInsCoName",
        msg: "The Insurance company name is required",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const excludeList = selectedID
      ? {
          InternalInsCoID: 1,
        }
      : {};

    const submitData = {};
    for (let i in formData) {
      if (formData[i] === null || formData[i] === undefined || excludeList[i])
        continue;
      submitData[i] = formData[i];
    }

    if (selectedID) {
      edit(submitData, partner._id, selectedID).then((res) => {
        if (res.status) {
          reset();
          getList({}, partner_id);
        }
      });
    } else {
      save(submitData, partner._id).then((res) => {
        if (res.status) {
          reset();
          getList({}, partner_id);
        }
      });
    }
  };

  const reset = () => {
    setModal(false);
    setSelectedID(null);
    setFormData(initialData);
  };

  return (
    <Modal
      show={modal}
      onHide={reset}
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
    >
      <Modal.Header toggle={toggle} closeButton>
        <h4>{selectedID ? "Edit" : "Add new"} Insurance</h4>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={(e) => onSubmitHandler(e)} autoComplete="off">
          <Row>
            <Col xs="12">
              <Form.Group className="form-group">
                <Form.Label htmlFor="InternalInsCoID">
                  Insurance Company ID (Internal) <span className="red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="InternalInsCoID"
                  name="InternalInsCoID"
                  maxLength="8"
                  value={InternalInsCoID}
                  onChange={(e) => onChange(e)}
                  disabled={selectedID ? true : false}
                />

                <Errors current_key="InternalInsCoID" key="InternalInsCoID" />
              </Form.Group>
            </Col>

            <Col xs="12">
              <Form.Group className="form-group">
                <Form.Label htmlFor="InternalInsCoName">
                  Insurance Company Name (Internal){" "}
                  <span className="red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="InternalInsCoName"
                  name="InternalInsCoName"
                  maxLength="80"
                  value={InternalInsCoName}
                  onChange={(e) => onChange(e)}
                />

                <Errors
                  current_key="InternalInsCoName"
                  key="InternalInsCoName"
                />
              </Form.Group>
            </Col>

            <Col xs="12">
              <Form.Group className="form-group">
                <Form.Label htmlFor="PartnerInsCoID">
                  {partner.name} Insurance Company ID{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  id="PartnerInsCoID"
                  name="PartnerInsCoID"
                  maxLength="8"
                  value={PartnerInsCoID}
                  onChange={(e) => onChange(e)}
                />

                <Errors current_key="PartnerInsCoID" key="PartnerInsCoID" />
              </Form.Group>
            </Col>

            <Col xs="12">
              <Form.Group className="form-group">
                <Form.Label htmlFor="PartnerInsCoName">
                  {partner.name} Insurance Company Name{" "}
                </Form.Label>
                <Form.Control
                  type="text"
                  id="PartnerInsCoName"
                  name="PartnerInsCoName"
                  maxLength="80"
                  value={PartnerInsCoName}
                  onChange={(e) => onChange(e)}
                />

                <Errors current_key="PartnerInsCoName" key="PartnerInsCoName" />
              </Form.Group>
            </Col>

            <Col xs="12">
              <Form.Group className="form-group">
                <Form.Label htmlFor="PartnerPlanID">
                  {partner.name} Plan ID
                </Form.Label>
                <Form.Control
                  type="text"
                  id="PartnerPlanID"
                  name="PartnerPlanID"
                  maxLength="8"
                  value={PartnerPlanID}
                  onChange={(e) => onChange(e)}
                />

                <Errors current_key="PartnerPlanID" key="PartnerPlanID" />
              </Form.Group>
            </Col>

            <Col xs="12">
              <Form.Group className="form-group">
                <Form.Label htmlFor="PartnerPlanName">
                  {partner.name} Plan Name
                </Form.Label>
                <Form.Control
                  type="text"
                  id="PartnerPlanName"
                  name="PartnerPlanName"
                  maxLength="80"
                  value={PartnerPlanName}
                  onChange={(e) => onChange(e)}
                />

                <Errors current_key="PartnerPlanName" key="PartnerPlanName" />
              </Form.Group>
            </Col>

            <Col xs="12">
              <Form.Group className="form-group">
                <Form.Label htmlFor="comments">Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  id="comments"
                  name="comments"
                  value={comments}
                  maxLength="300"
                  onChange={(e) => onChange(e)}
                />

                <Errors current_key="comments" key="comments" />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="float-end">
                <Button
                  className="m-2"
                  type="submit"
                  size="sm"
                  variant="primary"
                  disabled={loadingRecord}
                >
                  {loadingRecord ? "Saving..." : "Save"}
                </Button>
                <Button
                  className="ml-2"
                  type="reset"
                  size="sm"
                  variant="danger"
                  onClick={reset}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loadingRecord: state.insurance.loadingRecord,
  List: state.insurance.List,
});

export default connect(mapStateToProps, {
  setErrors,
  removePageErrors,
  save,
  edit,
  getList,
})(InsuranceModal);
