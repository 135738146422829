import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingList,
  searchParametersUpdate,
  listUpdated,
  loadingOnSubmit,
  recordSaved,
  clientError,
  recordDeleted,
  resetStore,
  getRecordById,
} from "reducers/partnerReducer";

export const getList = (params) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = params.query ? params.query : "";
    params.query = query;
    config.params = params;

    dispatch(loadingList());
    const res = await axios.get(`/api/admin/partners/list`, config);

    dispatch(searchParametersUpdate(params));

    dispatch(listUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          clientError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const getPartnerById = (partner_id) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(`/api/admin/partners/${partner_id}`, config);

    await dispatch(getRecordById(res.data.response));
    return res.data ? res.data.response : null;
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          clientError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const save = (formData, partner_id) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnSubmit());

    let res = null;
    if (partner_id) {
      res = await axios.put(
        `/api/admin/partners/${partner_id}`,
        formData,
        config
      );
    } else {
      res = await axios.post(`/api/admin/partners/`, formData, config);
    }

    if (res.data.status === true) {
      dispatch(recordSaved(res.data.response));

      dispatch(setAlert("Partner saved.", "success"));
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(clientError());
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          clientError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const deleteRecord = (partner_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(`/api/admin/partners/${partner_id}`, config);

    dispatch(recordDeleted(partner_id));
    dispatch(setAlert("Partner deleted", "success"));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          clientError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

// Delete Physician
export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/clients");
};

// page not found
export const notFound = (navigate) => async (dispatch) => {
  navigate("/admin/page-not-found");
};

// reset errors
export const removePageErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetStore());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(clientError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};
