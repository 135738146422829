import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingList,
  searchParametersUpdate,
  listUpdated,
  loadingOnSubmit,
  CPT_CodeError,
  recordDeleted,
  resetStore,
  listUpdatedAll,
  recordUpdated,
  recordCreated,
} from "reducers/cpt_codeReducer";

export const getCPTCodesList = (CPTCodeParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = CPTCodeParams.query ? CPTCodeParams.query : "";
    CPTCodeParams.query = query;
    config.params = CPTCodeParams;

    dispatch(loadingList());
    const res = await axios.get(`/api/admin/cpt-codes`, config);

    dispatch(searchParametersUpdate(CPTCodeParams));

    dispatch(listUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          CPT_CodeError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const save = (formData, cpt_code_id) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnSubmit());

    let url = `/api/admin/cpt-codes`;
    if (cpt_code_id) {
      url = `/api/admin/cpt-codes/${cpt_code_id}`;
    }

    const res = await axios.put(url, formData, config);
    if (res.data.status === true) {
      if (cpt_code_id) {
        dispatch(recordUpdated(res.data.response));
      } else {
        dispatch(recordCreated(res.data.response));
      }

      dispatch(setAlert("CPT Code saved.", "success"));
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(CPT_CodeError());
        dispatch(setAlert(res.data.message, "danger"));

        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          CPT_CodeError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const deleteCPTCode = (cpt_code_id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    await axios.delete(`/api/admin/cpt-codes/${cpt_code_id}`, config);

    dispatch(recordDeleted(cpt_code_id));
    dispatch(setAlert("CPT Code deleted", "success"));
  } catch (err) {
    console.error(err);
    dispatch(
      setAlert(err.response.data.message || err.response.statusText, "danger")
    );
    err.response &&
      dispatch(
        CPT_CodeError({
          msg: err.response.data.message || err.response.statusText,
          status: err.response.status,
        })
      );
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/cpt-codes");
};

// page not found
export const notFound = (navigate) => async (dispatch) => {
  navigate("/admin/page-not-found");
};

// reset errors
export const removePageErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetStore());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(CPT_CodeError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const getCPTCodesListAll = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(`/api/admin/cpt-codes/all`, config);

    dispatch(listUpdatedAll(res.data.response));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          CPT_CodeError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};
