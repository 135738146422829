import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useLocation, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";

import AdminRoutes from "views/Routing/AdminRoutes";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import DefaultTopNav from "./DefaultTopNav";

const AdminLayout = ({ auth: { actionPending }, alerts }) => {
  const createAlertNotification = (type, message) => {
    switch (type) {
      case "info":
        return toast.info(message);
      case "success":
        return toast.success(message);
      case "warning":
        return toast.warning(message);
      case "danger":
        return toast.error(message);
    }
  };

  React.useEffect(() => {
    alerts !== null &&
      alerts.length > 0 &&
      alerts.map((alert) => {
        createAlertNotification(`${alert.alertType}`, alert.msg);
      });
  }, [alerts, actionPending]);

  return (
    <div className="admin-dashboard">
      <Helmet>
        <title>Admin Portal | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>

      <div className="top-layout">
        <DefaultHeader />
        <ToastContainer />
        <DefaultTopNav />
        <Container fluid>
          <Routes>
            {AdminRoutes.map((route, i) => {
              return (
                <Route path={route.path} element={route.element} key={i} />
              );
            })}
          </Routes>
        </Container>
      </div>
      <div className="footer-layout">
        <DefaultFooter />
      </div>
    </div>
  );
};

AdminLayout.propTypes = {
  auth: PropTypes.object.isRequired,
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  alerts: state.alert,
});
export default connect(mapStateToProps, {
  // loadPendingAction,
  // updateSidebar,
  // logout,
})(AdminLayout);
