import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Row,
  Card,
  InputGroup,
  Alert,
} from "react-bootstrap";
import Select from "react-select";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import Errors from "Notifications/Errors";
import Spinner from "views/Spinner";

import { validateForm } from "utils/validation";

import {
  createAppToken,
  cancelSave,
  setErrors,
  removeAppTokenErrors,
  resetComponentStore,
  getAllClients,
} from "actions/appTokenActions";
import { isAdmin } from "utils/helper";

const CreateAppToken = ({
  createAppToken,
  errorList,
  cancelSave,
  loadingAppToken,
  setErrors,
  removeAppTokenErrors,
  resetComponentStore,
  getAllClients,
  clientsList,
  loggedInUser,
}) => {
  const navigate = useNavigate();

  const [isTokenCreated, setIsTokenCreated] = React.useState(false);
  const [token, setToken] = React.useState("");
  const [copied, setCopied] = React.useState(false);

  const initialFormData = {
    client: "",
    allowedIPs: "",
    label: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);

  const { client, allowedIPs, label } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    removeAppTokenErrors();

    let validationRules = [
      {
        param: "client",
        msg: "Please choose a client",
      },
      {
        param: "label",
        msg: "Label is required",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};

    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    submitData.client = submitData.client ? submitData.client.value : "";

    // console.log("Add App Token Submit data", submitData);
    createAppToken(submitData, navigate).then((res) => {
      if (!res.status) return;
      setToken(res.response.appToken);
      setIsTokenCreated(true);
    });
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(navigate);
  };

  React.useEffect(() => {
    getAllClients();
    resetComponentStore();

    if (!loggedInUser) return;

    if (!isAdmin(loggedInUser)) {
      navigate("/admin/dashboard");
    }
  }, [loggedInUser]);

  const onClickCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(token);
  };

  const handleSelect = (selectedOption) => {
    setFormData({
      ...formData,
      client: selectedOption,
    });
  };

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Create New App Token"
        crumbs={[
          { name: "App Tokens", path: "/admin/app-tokens" },
          { name: "Create New App Token" },
        ]}
      />

      {loadingAppToken ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <h4 className="header-title mb-3">Token Information</h4>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="client">
                    Client <span>*</span>
                  </Form.Label>
                  <br></br>
                  <Select
                    id="client"
                    name="client"
                    options={clientsList.map((client) => ({
                      value: client._id,
                      label: client.name,
                    }))}
                    value={client}
                    onChange={handleSelect}
                  />
                  <Errors current_key="client" key="client" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="label">
                    Label <span>*</span>
                  </Form.Label>
                  <Form.Control
                    className={errorList.label ? "invalid" : ""}
                    type="text"
                    id="label"
                    name="label"
                    maxLength="100"
                    value={label}
                    onChange={(e) => onChange(e)}
                  />
                  <Errors current_key="label" key="label" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="allowedIPs">Allowed IPs</Form.Label>

                  <Form.Control
                    className={errorList.allowedIPs ? "invalid" : ""}
                    as="textarea"
                    rows={5}
                    id="allowedIPs"
                    name="allowedIPs"
                    value={allowedIPs}
                    onChange={(e) => onChange(e)}
                  />
                  <small className="float-end">
                    Note: Put multiple IPs with Comma (,) separated.
                  </small>

                  <Errors current_key="allowedIPs" key="allowedIPs" />
                </Form.Group>

                {isTokenCreated && token ? (
                  <Form.Group className="form-group mt-5 mb-3">
                    <Alert show={true} variant="success">
                      App Token created.
                    </Alert>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        id="app-token"
                        value={token}
                        readOnly
                      />
                      <InputGroup.Text id="basic-addon2" onClick={onClickCopy}>
                        {copied ? "Copied" : "Copy"}
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                ) : undefined}

                <br></br>

                <div className="float-end">
                  {!isTokenCreated ? (
                    <>
                      <Button
                        className="m-2"
                        type="submit"
                        size="sm"
                        variant="primary"
                      >
                        Create
                      </Button>
                      <Button
                        className="ml-2"
                        type="reset"
                        size="sm"
                        variant="danger"
                        onClick={onClickHandel}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Link to="/admin/app-tokens">
                      <Button color="primary" size="sm">
                        View All App Tokens
                      </Button>
                    </Link>
                  )}
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

CreateAppToken.propTypes = {
  createAppToken: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingAppToken: state.appToken.loadingAppToken,
  clientsList: state.appToken.clientsList,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  createAppToken,
  cancelSave,
  setErrors,
  removeAppTokenErrors,
  resetComponentStore,
  getAllClients,
})(CreateAppToken);
