import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingLogsList,
  logSearchParametersUpdate,
  logListUpdated,
  resetLog,
  logError,
  logDetailsById,
} from "reducers/logReducer";

export const getLogsList = (logParams) => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = logParams.query ? logParams.query : "";
    logParams.query = query;
    config.params = logParams;

    dispatch(loadingLogsList());
    const res = await axios.get("/api/admin/logs", config);

    dispatch(logSearchParametersUpdate(logParams));
    dispatch(logListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response && dispatch();
      logError({
        msg: err.response.statusText,
        status: err.response.status,
      });

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const getLogById = (log_id) => async (dispatch) => {
  dispatch(removeErrors());

  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.get(`/api/admin/logs/${log_id}`, config);

    dispatch(logDetailsById(res.data.response));
    return res.data ? res.data.response : { status: false };
  } catch (err) {
    // console.log(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          logError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetLog());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(logError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};
