import React from "react";
import { connect } from "react-redux";

import SelectedGraph from "../SelectedGraph";
import { getSelectedClientCountByQuarter } from "actions/dashboardActions";

const ClaimsQuarterlyView = ({
  selectedClient,
  getSelectedClientCountByQuarter,
  currentClientClaimsByQuarter,
  loadingDashboardClaimsByQuarter,
}) => {
  React.useEffect(() => {
    if (!selectedClient || !selectedClient.code) return;
    getSelectedClientCountByQuarter(selectedClient.code, "claims");
  }, [getSelectedClientCountByQuarter, selectedClient]);

  const initialQuarterData = {
    currentQuarterCount: 0,
    previousQuarterCount: 0,
    countsByQuarter: {
      "Jan-Feb-Mar": 0,
      "Apr-May-Jun": 0,
      "Jul-Aug-Sep": 0,
      "Oct-Nov-Dec": 0,
    },
  };

  const [claimsCountByQuarter, setClaimsCountByQuarter] =
    React.useState(initialQuarterData);

  React.useEffect(() => {
    if (!currentClientClaimsByQuarter) return;

    const { currentQuarterCount, previousQuarterCount, countsByQuarter } =
      currentClientClaimsByQuarter;

    const quarterNameValuePairs = Object.entries(countsByQuarter).map(
      ([name, count]) => ({
        name,
        count,
      })
    );

    const currentData = {
      currentQuarterCount: currentQuarterCount ? currentQuarterCount : 0,
      previousQuarterCount: previousQuarterCount ? previousQuarterCount : 0,
      countsByQuarter:
        quarterNameValuePairs && quarterNameValuePairs.length
          ? quarterNameValuePairs
          : claimsCountByQuarter.countsByQuarter,
    };

    setClaimsCountByQuarter(currentData);
  }, [currentClientClaimsByQuarter]);

  return (
    <SelectedGraph
      loading={loadingDashboardClaimsByQuarter}
      data={claimsCountByQuarter.countsByQuarter}
      filterLabel="Quarter"
      currentCount={claimsCountByQuarter.currentQuarterCount}
      lastCount={currentClientClaimsByQuarter.previousQuarterCount}
      legend="# Claims"
    />
  );
};

const mapStateToProps = (state) => ({
  currentClientClaimsByQuarter: state.dashboard.currentClientClaimsByQuarter,
  loadingDashboardClaimsByQuarter:
    state.dashboard.loadingDashboardClaimsByQuarter,
});

export default connect(mapStateToProps, {
  getSelectedClientCountByQuarter,
})(ClaimsQuarterlyView);
