import React from "react";
import { PropTypes } from "prop-types";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BiPlusMedical } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import PiDataTable from "views/DataTable/PiDataTable";

import {
  getAppTokensList,
  resetComponentStore,
  changeTokenStatus,
} from "actions/appTokenActions";

import AppTokenFilters from "./AppTokenFilters";
import EditAppTokenModal from "./EditAppTokenModal";
import { isAdmin } from "utils/helper";

const AppTokensList = ({
  appTokenList: { data, count },
  getAppTokensList,
  changeTokenStatus,
  loadingAppTokenList,
  resetComponentStore,
  sortingParams,
  loggedInUser,
}) => {
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = React.useState({});

  React.useEffect(() => {
    const statusKeysObj = {};
    data.forEach((each, i) => {
      statusKeysObj[`button-${each.appToken}`] = false;
    });

    setButtonLoading(statusKeysObj);
  }, [data]);

  const initialSortingParams = {
    limit: 20,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    filters: [],
  };

  const { page, limit } = sortingParams;

  const [clientParams, setClientParams] = React.useState(initialSortingParams);
  const [modal, setModal] = React.useState(false);
  const [selectedToken, setSelectedToken] = React.useState({});

  const [onlyOnce, setOnce] = React.useState(true);
  const actions = (
    <div className="page-actions">
      <Link to="/admin/app-tokens/create" title="create">
        <Button color="primary" size="sm">
          <BiPlusMedical /> Create New App Token
        </Button>
      </Link>
    </div>
  );

  const getSelectedToken = (row_id) => {
    if (!row_id || !selected) return;

    const selected = data.find((each) => each._id === row_id);

    setSelectedToken(selected);
  };

  const onClickHandler = (row_id) => {
    getSelectedToken(row_id);
    setModal(true);
  };

  const onChangeStatus = (status, token_id, index) => {
    buttonLoading[index] = true;
    setButtonLoading({ ...buttonLoading });

    changeTokenStatus(status, token_id).then(() => {
      buttonLoading[index] = false;
      setButtonLoading({ ...buttonLoading });
    });
  };

  const columns = [
    {
      name: "Client",
      selector: (row) => row.client.name,
      sortable: false,
      width: "10%",
    },
    {
      name: "Label",
      selector: (row) => row.label,
      sortable: false,
      width: "10%",
    },
    {
      name: "App Token",
      button: true,
      selector: (row) => row.appToken,
      sortable: false,
      width: "30%",
    },
    {
      name: "Allowed IPs",
      selector: (row) => <div>{row.allowedIPs ? row.allowedIPs : "N/A"}</div>,
      sortable: false,
      width: "25%",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row, i) => (
        <div>
          <Form>
            <Form.Check
              className="fancy-checkbox-switch"
              type="switch"
              id="custom-switch"
              onChange={(e) =>
                onChangeStatus(
                  row._id,
                  row.status === 1 ? 2 : 1,
                  `button-${row.appToken}-${i}`
                )
              }
              checked={row.status === 1 ? true : false}
              disabled={
                buttonLoading[`button-${row.appToken}-${i}`] === true
                  ? true
                  : false
              }
            />
          </Form>
        </div>
      ),
      sortable: true,
      sortField: "status",
      width: "10%",
    },
    {
      name: "Actions",
      width: "calc(15% - 48px)",
      button: true,
      cell: (row) => (
        <div className="App table-list-buttons">
          <Button
            size="sm"
            title="Edit"
            type="button"
            variant="primary"
            onClick={(e) => onClickHandler(row._id)}
          >
            <FaEdit />
          </Button>
        </div>
      ),
    },
  ];

  React.useMemo(() => {
    if (onlyOnce) {
      resetComponentStore();
      setOnce(false);
    }

    if (!loggedInUser) return;

    if (!isAdmin(loggedInUser)) {
      navigate("/admin/dashboard");
      return;
    }
    getAppTokensList(clientParams);
  }, [getAppTokensList, clientParams, resetComponentStore, loggedInUser]);

  const handleTableChange = (type, searchText) => {
    let params = {
      limit: limit,
      page: type === "search" ? 1 : page ? page : 1,
    };

    let filters = [];
    if (type === "search") {
      if (searchText.length > 0) {
        filters = sortingParams.filters.includes(type)
          ? sortingParams.filters
          : [...sortingParams.filters, type];
        params = {
          ...params,
          query: {
            ...sortingParams.query,
            [type]: {
              "client.name": {
                value: searchText,
                type: "String",
              },
              "client.code": {
                value: searchText,
                type: "String",
              },
              label: {
                value: searchText,
                type: "String",
              },
              appToken: {
                value: searchText,
                type: "String",
              },
              allowedIPs: {
                value: searchText,
                type: "String",
              },
            },
          },
          filters,
        };
      } else {
        filters = sortingParams.filters.filter((item) => item !== type);
        const temp = {};
        params = {
          ...sortingParams,
          filters,
        };
        for (var i in params.query) {
          if (i === type) continue;
          temp[i] = params.query[i];
        }
        params.query = temp;
      }
    }

    setClientParams(params);
  };

  const onFilterChange = (newParams) => {
    setClientParams((params) => ({ ...params, ...newParams }));
  };

  return (
    <React.Fragment>
      <AppBreadcrumb pageTitle="App Tokens" crumbs={[{ name: "App Tokens" }]} />

      <EditAppTokenModal
        modal={modal}
        setModal={setModal}
        selectedToken={selectedToken}
        setSelectedToken={setSelectedToken}
      />
      <Card>
        <Card.Body>
          {actions}

          <div className="table-filter-section">
            <Row>
              <Col md="2">
                <AppTokenFilters
                  type="text"
                  onSearch={handleTableChange}
                  filterType="String"
                  filterParams={clientParams}
                  onFilterChange={onFilterChange}
                />
              </Col>

              <Col md="2">
                <AppTokenFilters
                  type="dropdown"
                  filter="status"
                  filterName="Status"
                  filterType="Number"
                  filterParams={clientParams}
                  onFilterChange={onFilterChange}
                  options={[
                    {
                      value: "all",
                      label: "All",
                    },
                    {
                      value: 1,
                      label: "Active",
                    },
                    {
                      value: 2,
                      label: "Inactive",
                    },
                  ]}
                />
              </Col>
            </Row>
          </div>

          <PiDataTable
            columns={columns}
            data={data}
            count={count}
            params={clientParams}
            setParams={setClientParams}
            pagination
            responsive
            striped={true}
            selectableRows
            progressPending={loadingAppTokenList}
            highlightOnHover
            persistTableHead={true}
            paginationServer
          />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

AppTokensList.propTypes = {
  getAppTokensList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  appTokenList: state.appToken.appTokenList,
  loadingAppTokenList: state.appToken.loadingAppTokenList,
  sortingParams: state.appToken.sortingParams,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getAppTokensList,
  changeTokenStatus,
  resetComponentStore,
})(AppTokensList);
