import axios from "axios";
import { setAlert, removeAlert } from "./alert";

import { setErrorsList } from "./errors";
import { logout } from "actions/auth";

import { removeErrors } from "reducers/errors";

import {
  claimUpdated,
  claimError,
  claimDetailsById,
  claimListUpdated,
  claimSearchParametersUpdate,
  loadingOnClaimSubmit,
  loadingClaimsList,
  loadingClientDetail,
  resetClaim,
  claimCreated,
  claimLoadedToTebra,
} from "reducers/claim";

export const getClaimDetailsById =
  (claim_id, client_code) => async (dispatch) => {
    dispatch(removeErrors());
    try {
      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingClientDetail());

      const res = await axios.get(
        `/api/admin/client-workspace/${client_code}/claims/${claim_id}`,
        config
      );

      dispatch(claimDetailsById(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            claimError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const createClaim = (formData, client_code) => async (dispatch) => {
  dispatch(removeErrors());
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingOnClaimSubmit());

    const res = await axios.post(
      `/api/admin/client-workspace/${client_code}/claims/create`,
      formData,
      config
    );

    if (res.data.status === true) {
      dispatch(claimCreated());
      dispatch(setAlert("Claim Created.", "success"));
    } else {
      const errors = res.data.errors;
      if (errors) {
        dispatch(claimError());
        dispatch(setAlert(res.data.message, "danger"));
        errors.forEach((error) => {
          dispatch(setErrorsList(error.msg, error.param));
        });
      }
    }
    return res.data ? res.data : { status: false };
  } catch (err) {
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          claimError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const updateClaimEncounterData =
  (encounterData, client_code, claim_id, navigate) => async (dispatch) => {
    try {
      dispatch(removeErrors());

      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnClaimSubmit());

      const res = await axios.put(
        `/api/admin/client-workspace/${client_code}/claims/${claim_id}`,
        encounterData,
        config
      );

      if (res.data.status === true) {
        dispatch(claimUpdated(res.data.response));
        dispatch(removeAlert());
        dispatch(setAlert("Claim updated.", "success"));
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(claimError());
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            claimError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const updateClaimProcedureData =
  (procedureData, client_code, claim_id, navigate) => async (dispatch) => {
    try {
      dispatch(removeErrors());

      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnClaimSubmit());

      const data = {
        items: procedureData,
      };
      const res = await axios.put(
        `/api/admin/client-workspace/${client_code}/claims/${claim_id}/procedure-data`,
        data,
        config
      );
      if (res.data.status === true) {
        dispatch(claimUpdated(res.data.response));
        dispatch(removeAlert());
        dispatch(setAlert("Claim updated.", "success"));
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(setAlert(res.data.message, "danger"));
          dispatch(claimError());
          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            claimError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const updateClaimInsuranceData =
  (insuranceData, client_code, claim_id, navigate) => async (dispatch) => {
    try {
      dispatch(removeErrors());

      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnClaimSubmit());

      const res = await axios.put(
        `/api/admin/client-workspace/${client_code}/claims/${claim_id}/insurance-data`,
        insuranceData,
        config
      );
      if (res.data.status === true) {
        dispatch(claimUpdated(res.data.response));
        dispatch(removeAlert());
        dispatch(setAlert("Claim updated.", "success"));
      } else {
        dispatch(claimError());
        const errors = res.data.errors;
        if (errors) {
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            claimError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const updateClaimOtherData =
  (otherData, client_code, claim_id, navigate) => async (dispatch) => {
    try {
      dispatch(removeErrors());

      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnClaimSubmit());

      const data = {
        otherData,
      };

      const res = await axios.put(
        `/api/admin/client-workspace/${client_code}/claims/${claim_id}/other-data`,
        data,
        config
      );
      if (res.data.status === true) {
        dispatch(claimUpdated(res.data.response));
        dispatch(removeAlert());
        dispatch(setAlert("Claim updated.", "success"));
      } else {
        const errors = res.data.errors;
        dispatch(claimError());
        if (errors) {
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            claimError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );
        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getClaimsList = (claimParams, client_code) => async (dispatch) => {
  dispatch(removeErrors());
  try {
    const config = {
      "Content-Type": "application/json",
    };

    const query = claimParams.query ? claimParams.query : "";
    claimParams.query = query;
    config.params = claimParams;

    dispatch(loadingClaimsList());
    const res = await axios.get(
      `/api/admin/client-workspace/${client_code}/claims/list`,
      config
    );

    dispatch(claimSearchParametersUpdate(claimParams));

    dispatch(claimListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          claimError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(removeErrors());
  await dispatch(resetClaim());
};

export const removeClaimErrors = () => async (dispatch) => {
  await dispatch(removeErrors());
};

export const loadEncounterToTebra =
  (claim_id, client_code) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      const formData = {};
      const res = await axios.post(
        `/api/admin/client-workspace/${client_code}/claims/${claim_id}/load-tebra`,
        formData,
        config
      );

      if (res.data.status === true) {
        dispatch(claimLoadedToTebra(res.data.response));
        dispatch(setAlert("Claim Loaded to Tebra.", "success"));
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(setAlert(res.data.message, "danger"));
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        if (err.response && err.response.data) {
          dispatch(setAlert(err.response.data.message, "danger"));
        }
      }
    }
  };
