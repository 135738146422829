import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  List: {
    page: 1,
    data: [],
    count: 0,
  },
  loadingList: true,
  loadingRecord: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
  ListAll: [],
};

const slice = createSlice({
  name: "Insurances",
  initialState: initialState,
  reducers: {
    recordSaved(state) {
      return {
        ...state,
        loadingRecord: false,
      };
    },
    resetStore(state) {
      return {
        ...initialState,
      };
    },
    insuranceError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingRecord: false,
        loadingList: false,
      };
    },
    recordDeleted(state, action) {
      const currentCount = state.List.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.List.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        List: {
          data: state.List.data.filter((r) => r._id !== action.payload),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingList: false,
      };
    },
    listUpdated(state, action) {
      return {
        ...state,
        List: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        // loadingRecord: true,
        loadingList: false,
      };
    },
    searchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingList: false,
      };
    },
    loadingOnSubmit(state) {
      return {
        ...state,
        loadingRecord: true,
      };
    },
    loadingList(state) {
      return {
        ...state,
        loadingList: true,
      };
    },
    listUpdatedAll(state, action) {
      return {
        ...state,
        ListAll: action.payload,
      };
    },
    insuranceStatusUpdated(state, action) {
      return {
        ...state,
        List: {
          ...state.List,
          data: state.List.data.map((insurance) =>
            insurance._id === action.payload.insurance_id
              ? { ...insurance, status: action.payload.status }
              : insurance
          ),
        },
      };
    },
  },
});

export const {
  loadingList,
  searchParametersUpdate,
  listUpdated,
  loadingOnSubmit,
  recordSaved,
  insuranceError,
  recordDeleted,
  resetStore,
  listUpdatedAll,
  insuranceStatusUpdated,
} = slice.actions;
export default slice.reducer;
