import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";

import Errors from "Notifications/Errors";
import { isEditor } from "utils/helper";

const InsuranceDataTab = ({
  insuranceData,
  setInsuranceData,
  onSubmitInsuranceData,
  loadingOnSubmit,
  isDisabled,
  toggleEdit,
  onClickCancel,
  loggedInUser,
  selectedClaimID,
  handleTabSelect,
  insurancesListAll,
}) => {
  const { insuranceCompanyId } = insuranceData;
  const [primaryInsurance, setPrimaryInsurance] = React.useState(null);
  const [secondaryInsurance, setSecondaryInsurance] = React.useState(null);

  React.useEffect(() => {
    if (!insuranceCompanyId) return;

    const splited = insuranceCompanyId.split(",");
    if (!splited.length) return;

    const filtered = insurancesListAll.find(
      (ins) => ins.InternalInsCoID == splited[0]
    );
    setPrimaryInsurance(filtered);

    if (!splited[1]) return;
    const filtered2 = insurancesListAll.find(
      (ins) => ins.InternalInsCoID == splited[1]
    );
    setSecondaryInsurance(filtered2);
  }, [insuranceCompanyId]);

  React.useEffect(() => {
    const ids = [];
    if (primaryInsurance) {
      ids.push(primaryInsurance.InternalInsCoID);
    }
    if (secondaryInsurance) {
      ids.push(secondaryInsurance.InternalInsCoID);
    }

    setInsuranceData({ insuranceCompanyId: ids.join(",") });
  }, [primaryInsurance, secondaryInsurance]);

  const onChange = (e) => {
    if (!e.target) return;
    setInsuranceData({ ...insuranceData, [e.target.name]: e.target.value });
  };

  const handleSelect2 = (field) => (selectedOption) => {
    const filtered = insurancesListAll.find(
      (ins) => ins.InternalInsCoID == selectedOption.value
    );
    switch (field) {
      case "primaryInsuranceId":
        setPrimaryInsurance(filtered);
        break;
      case "secondaryInsuranceId":
        setSecondaryInsurance(filtered);
        break;
      default:
        return;
    }
  };

  const onClickNext = (e) => {
    e.preventDefault();

    handleTabSelect("otherData");
  };

  const onClickBack = (e) => {
    e.preventDefault();

    handleTabSelect("procedureData");
  };

  const [insuranceDropdownList, setInsuranceDropdownList] = React.useState([]);
  React.useEffect(() => {
    const list = insurancesListAll.map((ins) => ({
      label: `${ins.InternalInsCoID} (${ins.InternalInsCoName})`,
      value: ins.InternalInsCoID,
    }));

    setInsuranceDropdownList(list);
  }, [insurancesListAll]);

  return (
    <Form onSubmit={(e) => onSubmitInsuranceData(e)}>
      <Row>
        <Col md="3" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Primary Insurance Company ID</Form.Label>

            <Select
              id="primaryInsuranceId"
              name="primaryInsuranceId"
              options={[
                {
                  label: "Select Insurance",
                  value: "",
                },
                ...insuranceDropdownList,
              ]}
              value={insuranceDropdownList.find(
                (r) =>
                  primaryInsurance &&
                  primaryInsurance.InternalInsCoID == r.value
              )}
              onChange={handleSelect2("primaryInsuranceId")}
              isDisabled={isDisabled}
            />

            <Errors current_key="primaryInsuranceId" key="primaryInsuranceId" />
          </Form.Group>
        </Col>

        <Col md="3" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Primary Insurance Company Name</Form.Label>
            <Form.Control
              type="text"
              value={primaryInsurance ? primaryInsurance.InternalInsCoName : ""}
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col md="3" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Secondary Insurance Company ID</Form.Label>

            <Select
              id="secondaryInsuranceId"
              name="secondaryInsuranceId"
              options={[
                {
                  label: "Select Insurance",
                  value: "",
                },
                ...insuranceDropdownList,
              ]}
              value={insuranceDropdownList.find(
                (r) =>
                  secondaryInsurance &&
                  secondaryInsurance.InternalInsCoID == r.value
              )}
              onChange={handleSelect2("secondaryInsuranceId")}
              isDisabled={isDisabled}
            />

            <Errors
              current_key="secondaryInsuranceId"
              key="secondaryInsuranceId"
            />
          </Form.Group>
        </Col>

        <Col md="3" xs="6">
          <Form.Group className="form-group">
            <Form.Label>Secondary Insurance Company Name</Form.Label>
            <Form.Control
              type="text"
              value={
                secondaryInsurance ? secondaryInsurance.InternalInsCoName : ""
              }
              onChange={(e) => onChange(e)}
              disabled={isDisabled}
            />
          </Form.Group>
        </Col>
      </Row>

      {isEditor(loggedInUser) ? (
        <div className="float-end">
          {selectedClaimID ? (
            !isDisabled ? (
              <>
                <Button
                  className="m-2"
                  type="submit"
                  variant="primary"
                  disabled={loadingOnSubmit || isDisabled}
                >
                  {loadingOnSubmit ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {` Loading... `}
                    </>
                  ) : (
                    <>Save</>
                  )}
                </Button>
                <Button
                  className="ml-2"
                  type="reset"
                  variant="danger"
                  onClick={onClickCancel}
                  disabled={loadingOnSubmit || isDisabled}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                className="btn btn-outline-dark"
                size="sm"
                onClick={toggleEdit}
              >
                Click to Edit
              </Button>
            )
          ) : (
            <div>
              <Button
                className="m-2"
                variant="light"
                onClick={(e) => onClickBack(e)}
              >
                Back
              </Button>
              <Button
                className="ml-2"
                type="btn"
                variant="primary"
                onClick={(e) => onClickNext(e)}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      ) : null}
    </Form>
  );
};

export default InsuranceDataTab;
