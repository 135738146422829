import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  logList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentLog: [],
  loadingLogList: true,
  loadingLog: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const logSlice = createSlice({
  name: "logs",
  initialState: initialState,
  reducers: {
    loadingLogsList(state) {
      return {
        ...state,
        loadingLogList: true,
      };
    },
    logSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingLogList: false,
      };
    },
    logListUpdated(state, action) {
      return {
        ...state,
        logList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingLogList: false,
      };
    },
    resetLog(state) {
      return {
        ...initialState,
      };
    },
    logError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingLog: false,
        loadingLogList: false,
      };
    },
    logDetailsById(state, action) {
      return {
        ...state,
        currentLog: action.payload,
        loadingLog: false,
      };
    },
  },
});

export const {
  loadingLogsList,
  logSearchParametersUpdate,
  logListUpdated,
  resetLog,
  logError,
  logDetailsById,
} = logSlice.actions;
export default logSlice.reducer;
