export const ADMIN_ROLE = 1;
export const LAB_ROLE = 3;
export const EMPLOYER_ROLE = 2;
export const EMPLOYEE_ROLE = 4;
export const DEFAULT_PAGE_SIZE = 20;

export const PAGE_SIZE_OPTIONS = [
  {
    text: "20",
    page: 20,
  },
  {
    text: "50",
    page: 50,
  },
  {
    text: "100",
    page: 100,
  },
];

export const clientStatus = [
  {
    label: "Ready To Post",
    value: "READY",
  },
  {
    label: "On Hold",
    value: "ONHOLD",
  },
  {
    label: "Posted",
    value: "DONE",
  },
];

export const otherDataValues = [
  {
    label: "STRING",
    value: "text",
  },
  {
    label: "INTEGER",
    value: "number",
  },
  {
    label: "BOOLEAN",
    value: "boolean",
  },
  {
    label: "DOUBLE",
    value: "text",
  },
  {
    label: "DATE",
    value: "date",
  },
  {
    label: "TIMESTAMP",
    value: "textarea",
  },
  {
    label: "FILE",
    value: "file",
  },
];

export const PatientRaceGlobal = [
  {
    label: "White",
    value: "white",
  },
  {
    label: "Caucasian",
    value: "caucasian",
  },
  {
    label: "Black",
    value: "black",
  },
  {
    label: "African American",
    value: "african american",
  },
  {
    label: "Native",
    value: "native",
  },
  {
    label: "American",
    value: "american",
  },
  {
    label: "Asian",
    value: "asian",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const PatientEthnicityGlobal = [
  {
    label: "Hispanic",
    value: "hispanic",
  },
  {
    label: "Non-hispanic",
    value: "non-hispanic",
  },
];

export const BillTypeGlobal = [
  {
    label: "SELFPAY",
    value: "SELFPAY",
  },
  {
    label: "INSURANCE",
    value: "INSURANCE",
  },
  {
    label: "NONBILLABLE",
    value: "NONBILLABLE",
  },
  {
    label: "CLIENT",
    value: "CLIENT",
  },
];

export const GenderGlobal = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
  {
    label: "Other",
    value: "O",
  },
  {
    label: "Unknown",
    value: "U",
  },
];

export const RelationshipGlobal = [
  {
    label: "SELF",
    value: "SELF",
  },
  {
    label: "SPOUSE",
    value: "SPOUSE",
  },
  {
    label: "CHILD",
    value: "CHILD",
  },
  {
    label: "OTHER",
    value: "OTHER",
  },
];

export const BIPAStatusGlobal = [
  {
    value: "Entered",
    label: "Entered",
  },
  {
    value: "BIinProcess",
    label: "BI in Process",
  },
  {
    value: "BICompleted",
    label: "BI Completed",
  },
  {
    value: "PAinProcess",
    label: "PA in Process",
  },
  {
    value: "PACompleted",
    label: "PA Completed",
  },
  {
    value: "Completed",
    label: "Completed",
  },
];
