import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  BI_PAList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentBI_PA: null,
  loadingBI_PAList: false,
  loadingBI_PA: false,
  loadingOnSubmit: false,
  error: {},
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
  insurancesList: [],
  CPT_CodesList: [],
  patientsList: [],
};

const benefitsInvestigation = createSlice({
  name: "benefitsInvestigation",
  initialState: initialState,
  reducers: {
    resetBI_PA(state) {
      return {
        ...initialState,
      };
    },
    loadBI_PA(state) {
      return {
        ...state,
        loadingBI_PA: true,
      };
    },
    BI_PAUpdated(state, action) {
      return {
        ...state,
        BI_PAList: {
          ...state.BI_PAList,
          data: state.BI_PAList.data.map((bi_pa) =>
            bi_pa._id === action.payload._id ? action.payload : bi_pa
          ),
        },
        currentBI_PA: action.payload,
        sortingParams: initialState.sortingParams,
        loadingOnSubmit: false,
      };
    },
    BI_PAError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingBI_PA: false,
        loadingOnSubmit: false,
        loadingBI_PAList: false,
      };
    },
    BI_PADetailsById(state, action) {
      return {
        ...state,
        currentBI_PA: action.payload,
        loadingBI_PA: false,
      };
    },
    BI_PAListUpdated(state, action) {
      return {
        ...state,
        BI_PAList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingBI_PAList: false,
      };
    },
    BI_PASearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingBI_PAList: false,
      };
    },
    loadingOnBI_PASubmit(state) {
      return {
        ...state,
        loadingOnSubmit: true,
      };
    },
    loadingBI_PAList(state) {
      return {
        ...state,
        loadingBI_PAList: true,
      };
    },
    BI_PACreated(state, action) {
      return {
        ...state,
        loadingOnSubmit: false,
      };
    },
    BI_PAInsuranceListUpdated(state, action) {
      return {
        ...state,
        insurancesList: action.payload,
      };
    },
    BI_PA_CPTCodesListUpdated(state, action) {
      return {
        ...state,
        CPT_CodesList: action.payload,
      };
    },
    BI_PA_PatientsListUpdated(state, action) {
      return {
        ...state,
        patientsList: action.payload,
      };
    },
    BI_PADeleted(state, action) {
      const currentCount = state.BI_PAList.count;
      const currentLimit = state.sortingParams.limit;
      const currentPage = parseInt(state.BI_PAList.page);
      const remainingPages = Math.ceil((currentCount - 1) / currentLimit);
      return {
        ...state,
        BI_PAList: {
          data: state.BI_PAList.data.filter(
            (record) => record._id !== action.payload
          ),
          count: currentCount - 1,
          page:
            currentPage <= remainingPages
              ? currentPage.toString()
              : remainingPages.toString(),
        },
        sortingParams: initialState.sortingParams,
        loadingBI_PAList: false,
      };
    },
  },
});

export const {
  loadBI_PA,
  BI_PAUpdated,
  BI_PAError,
  BI_PADetailsById,
  BI_PAListUpdated,
  BI_PASearchParametersUpdate,
  loadingOnBI_PASubmit,
  loadingBI_PAList,
  resetBI_PA,
  BI_PACreated,
  BI_PAInsuranceListUpdated,
  BI_PA_CPTCodesListUpdated,
  BI_PA_PatientsListUpdated,
  BI_PADeleted,
} = benefitsInvestigation.actions;
export default benefitsInvestigation.reducer;
