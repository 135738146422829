import React from "react";
import { connect } from "react-redux";

import SelectedGraph from "../SelectedGraph";
import { getSelectedClientCountByYear } from "actions/dashboardActions";

const ClaimsYearlyView = ({
  selectedClient,
  getSelectedClientCountByYear,
  currentClientClaimsByYear,
  loadingDashboardClaimsByYear,
}) => {
  React.useEffect(() => {
    if (!selectedClient || !selectedClient.code) return;
    getSelectedClientCountByYear(selectedClient.code, "claims");
  }, [getSelectedClientCountByYear, selectedClient]);

  const initialYearData = {
    currentYearCount: 0,
    previousYearCount: 0,
    countsByYear: [],
  };

  const [claimsCountByYear, setClaimsCountByYear] =
    React.useState(initialYearData);

  React.useEffect(() => {
    if (!currentClientClaimsByYear) return;

    const { currentYearCount, previousYearCount, countsByYear } =
      currentClientClaimsByYear;

    const yearNameValuePairs = Object.entries(countsByYear).map(
      ([name, count]) => ({
        name,
        count,
      })
    );

    const currentData = {
      currentYearCount: currentYearCount ? currentYearCount : 0,
      previousYearCount: previousYearCount ? previousYearCount : 0,
      countsByYear:
        yearNameValuePairs && yearNameValuePairs.length
          ? yearNameValuePairs
          : 0,
    };

    setClaimsCountByYear(currentData);
  }, [currentClientClaimsByYear]);

  return (
    <SelectedGraph
      loading={loadingDashboardClaimsByYear}
      data={claimsCountByYear.countsByYear}
      filterLabel="Year"
      currentCount={claimsCountByYear.currentYearCount}
      lastCount={claimsCountByYear.previousYearCount}
      legend="# Claims"
    />
  );
};

const mapStateToProps = (state) => ({
  currentClientClaimsByYear: state.dashboard.currentClientClaimsByYear,
  loadingDashboardClaimsByYear: state.dashboard.loadingDashboardClaimsByYear,
});

export default connect(mapStateToProps, {
  getSelectedClientCountByYear,
})(ClaimsYearlyView);
