import React from "react";
import { Modal, Form, Row, Col, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";

import { BillTypeGlobal } from "constants/index";
import { allowNumericOnly } from "utils/helper";

import {
  getClientServicesList,
  setErrors,
  save,
  removePageErrors,
  isRecordExists,
  setAlertError,
} from "actions/clientServicesActions";
import { getCPTCodesListAll } from "actions/cpt_codeActions";

import { validateForm } from "utils/validation";
import Errors from "Notifications/Errors";

const ClientServiceModal = ({
  modal,
  setModal,
  toggle,
  selectedID,
  setSelectedID,
  loadingRecord,
  setErrors,
  removePageErrors,
  save,
  getClientServicesList,
  List,
  getCPTCodesListAll,
  CPTCodesList,
  clientCode,
  isRecordExists,
  setAlertError,
}) => {
  const initialData = {
    service_code: "",
    description: "",
    service_CPT: "",
    billTypes: BillTypeGlobal.map((bt) => ({ type: bt.value, fee: "" })),
  };
  const [formData, setFormData] = React.useState(initialData);
  const [currentRecord, setCurrentRecord] = React.useState(null);
  const [selectedCPT_Code, setSelectedCPT_Code] = React.useState(null);
  const [recordExists, setRecordExists] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");

  const { service_code, description, service_CPT, billTypes } = formData;

  React.useEffect(() => {
    setFormData(initialData);

    removePageErrors();
    setErrorMsg("");

    if (!selectedID) return;

    const selectedRow = List.data.find((row) => row._id === selectedID);
    setCurrentRecord((old) => ({ ...old, ...selectedRow }));
  }, [selectedID, modal]);

  React.useEffect(() => {
    if (!currentRecord) return;

    const { service_code, description, service_CPT, service } = currentRecord;

    const filteredBillTypes = List.data.filter(
      (row) => row.service === service
    );

    const billTypesCP = [];

    billTypes.map((each) => {
      const filtered = filteredBillTypes.find(
        (bt) => each.type === bt.billType
      );
      if (!filtered) {
        billTypesCP.push(each);
        return;
      }

      billTypesCP.push({
        type: filtered.billType,
        fee: filtered.fee,
      });
    });

    const data = {
      service_code,
      description,
      service_CPT,
      billTypes: billTypesCP,
    };

    setFormData((form) => ({ ...form, ...data }));

    if (!CPTCodesList) return;
    const selectedCPT = CPTCodesList.find(
      (each) => each.cpt_code === service_CPT
    );

    if (!selectedCPT) return;

    setSelectedCPT_Code({
      label: selectedCPT.cpt_code,
      value: selectedCPT.cpt_code,
    });
  }, [currentRecord]);

  const onChange = (e, value) => {
    if (!e.target) return;
    switch (e.target.name) {
      case "fee":
        const isExists = billTypes.find((each) => each?.type === value);

        if (!isExists) {
          setFormData({
            ...formData,
            billTypes: [
              ...billTypes,
              {
                type: value,
                fee: e.target.value,
              },
            ],
          });
        } else {
          setFormData({
            ...formData,
            billTypes: [
              ...billTypes.filter((each) => each.type !== value),
              {
                type: value,
                fee: e.target.value,
              },
            ],
          });
        }
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSelect = (field) => (selectedOption) => {
    setFormData({
      ...formData,
      [field]: selectedOption.value,
    });
    setSelectedCPT_Code(selectedOption);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    removePageErrors();

    let validationRules = [
      {
        param: "service_code",
        msg: "The Service Code is required.",
      },
      {
        param: "description",
        msg: "The Description is required.",
      },
      {
        param: "service_CPT",
        msg: "The CPT Code is required.",
      },
    ];

    const errors = validateForm(formData, validationRules);

    const billTypesCopy = billTypes.filter((item) => item.fee !== "");
    if (!billTypesCopy.length) {
      errors.push({
        param: "bill_type_fee",
        msg: "At least one fee is required.",
      });
    }

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};
    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    submitData.billTypes = billTypesCopy;

    save(submitData, clientCode, selectedID).then((res) => {
      if (res.status) {
        reset();
        getClientServicesList({}, clientCode);
      }
    });
  };

  const reset = () => {
    setModal(false);
    setSelectedID(null);
    setFormData(initialData);
    setSelectedCPT_Code(null);
  };

  React.useEffect(() => {
    if (!modal) return;

    getCPTCodesListAll();
  }, [modal]);

  // Check the service fees already exists
  React.useEffect(() => {
    if (!service_CPT || !service_code || selectedID) return;

    let delayTimer;

    clearTimeout(delayTimer);

    delayTimer = setTimeout(() => {
      const submitData = {
        service_code: formData.service_code,
        service_CPT: formData.service_CPT,
      };
      isRecordExists(clientCode, submitData).then((res) => {
        setRecordExists(false);
        setErrorMsg("");
        if (!res.status) {
          return;
        }

        const response = res.response;
        if (!response.exists) {
          return;
        }

        if (response.data.length === 4) {
          setRecordExists(true);
          const msg = "The Record is already exists, Please try again.";
          setAlertError(msg);
          setErrorMsg(msg);
        } else {
          const data = response.data;
          const newBillTypes = data.map((bt) => ({
            type: bt.billType,
            fee: bt.fee,
          }));
          setFormData({ ...formData, billTypes: newBillTypes });
        }
      });
    }, 750);
  }, [service_CPT, service_code]);

  return (
    <Modal
      show={modal}
      onHide={reset}
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
      size="xl"
    >
      <Modal.Header toggle={toggle} closeButton>
        <h4>{selectedID ? "Edit" : "Create New"} Service</h4>
      </Modal.Header>

      <Modal.Body>
        {errorMsg ? (
          <Row>
            <Col>
              <Alert key="danger" variant="danger">
                {errorMsg}
              </Alert>
            </Col>
          </Row>
        ) : null}

        <Form onSubmit={(e) => onSubmitHandler(e)} autoComplete="off">
          <Row>
            <Col xs="12" md="3">
              <Form.Group className="form-group">
                <Form.Label htmlFor="service_code">
                  Service Code <span className="red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="service_code"
                  name="service_code"
                  value={service_code}
                  maxLength="100"
                  disabled={selectedID}
                  onChange={(e) => onChange(e)}
                  required
                />

                <Errors current_key="service_code" key="service_code" />
              </Form.Group>
            </Col>

            <Col xs="12" md="6">
              <Form.Group className="form-group">
                <Form.Label htmlFor="description">
                  Description <span className="red">*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="4"
                  id="description"
                  name="description"
                  value={description}
                  onChange={(e) => onChange(e)}
                  required
                />

                <Errors current_key="description" key="description" />
              </Form.Group>
            </Col>

            <Col xs="12" md="3">
              <Form.Group className="form-group">
                <Form.Label htmlFor="service_CPT">
                  CPT Code <span className="red">*</span>
                </Form.Label>
                <Select
                  id="service_CPT"
                  name="service_CPT"
                  options={CPTCodesList.map((code) => ({
                    value: code.cpt_code,
                    label: code.cpt_code,
                  }))}
                  value={selectedCPT_Code}
                  onChange={handleSelect("service_CPT")}
                  isDisabled={selectedID}
                  required
                />
                <Errors current_key="service_CPT" key="service_CPT" />
              </Form.Group>
            </Col>
          </Row>

          {BillTypeGlobal.map((eachType, i) => {
            const fdBillType =
              billTypes && billTypes.find((bt) => bt.type === eachType.value);
            const fee = fdBillType && fdBillType.fee ? fdBillType.fee : "";
            return (
              <Row key={i}>
                <Col xs="12" md="4">
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="name">Bill Type</Form.Label>
                    <Form.Control
                      type="text"
                      id={`billType_${i}`}
                      name="billType"
                      value={eachType.value}
                      disabled
                    />
                  </Form.Group>
                </Col>

                <Col md="1"></Col>

                <Col xs="12" md="4">
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="fee">Fee (USD)</Form.Label>
                    <Form.Control
                      type="text"
                      id={`fee_${i}`}
                      name="fee"
                      onChange={(e) => onChange(e, eachType.value)}
                      onKeyPress={allowNumericOnly}
                      value={fee}
                    />
                  </Form.Group>
                </Col>
              </Row>
            );
          })}

          <Row>
            <Col>
              <Errors current_key="bill_type_fee" key="bill_type_fee" />
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="float-end">
                <Button
                  className="m-2"
                  type="submit"
                  size="sm"
                  variant="primary"
                  disabled={loadingRecord || recordExists}
                >
                  {loadingRecord ? "Saving..." : "Save"}
                </Button>
                <Button
                  className="ml-2"
                  type="reset"
                  size="sm"
                  variant="danger"
                  onClick={reset}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loadingRecord: state.client_service.loadingRecord,
  List: state.client_service.List,
  CPTCodesList: state.cpt_code.CPTCodesListAll,
});

export default connect(mapStateToProps, {
  setErrors,
  removePageErrors,
  save,
  getClientServicesList,
  getCPTCodesListAll,
  isRecordExists,
  setAlertError,
})(ClientServiceModal);
