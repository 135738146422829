import React from "react";
import { Row, Col } from "react-bootstrap";

import ClaimsStatusCountView from "./Claims/ClaimsStatusCountView";
import PatientsStatusCountView from "./Patients/PatientsStatusCountView";

const SubmissionStatusOverview = ({ selectedClient }) => {
  return (
    <Row>
      <Col md="6">
        <ClaimsStatusCountView selectedClient={selectedClient} />
      </Col>
      <Col md="6">
        <PatientsStatusCountView selectedClient={selectedClient} />
      </Col>
    </Row>
  );
};

export default SubmissionStatusOverview;
