import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";

import { RxDotsVertical } from "react-icons/rx";

import { clientStatus } from "constants/index";
import ClaimsStatusThisWeek from "./ClaimsStatusThisWeek";
import ClaimsStatusThisMonth from "./ClaimsStatusThisMonth";
import ClaimsStatusThisQuarter from "./ClaimsStatusThisQuarter";
import ClaimsStatusThisYear from "./ClaimsStatusThisYear";

const ClaimsStatusCountView = ({ selectedClient }) => {
  const [claimStatusViewType, setClaimStatusViewType] =
    React.useState("this-week");

  const handleSelectClaimFilter = (eventKey) => {
    setClaimStatusViewType(eventKey);
    console.log(`selected claim status filter: ${eventKey}`);
  };

  const [statusOptions, setStatusOptions] = React.useState([]);

  React.useEffect(() => {
    const options = clientStatus.map((each) => each.value);
    setStatusOptions(options);
  }, [clientStatus]);

  return (
    <div className="crm-card">
      <h5 className="d-inline-block">Claims with Status</h5>

      <Nav
        variant="claims-vdots-filter"
        activeKey={claimStatusViewType}
        onSelect={handleSelectClaimFilter}
        className="float-end"
      >
        <div className="status-type">{claimStatusViewType}</div>
        <NavDropdown
          className="vdots-filter"
          title={
            <span>
              <RxDotsVertical className="float-end" size={"25px"} />
            </span>
          }
          id="claims-filter"
        >
          <NavDropdown.Item eventKey="this-week">This Week</NavDropdown.Item>
          <NavDropdown.Item eventKey="this-month">This Month</NavDropdown.Item>
          <NavDropdown.Item eventKey="this-quarter">
            This Quarter
          </NavDropdown.Item>
          <NavDropdown.Item eventKey="this-year">This Year</NavDropdown.Item>
        </NavDropdown>
      </Nav>

      {claimStatusViewType === "this-week" ? (
        <ClaimsStatusThisWeek
          selectedClient={selectedClient}
          statusOptions={statusOptions}
        />
      ) : null}

      {claimStatusViewType === "this-month" ? (
        <ClaimsStatusThisMonth
          selectedClient={selectedClient}
          statusOptions={statusOptions}
        />
      ) : null}

      {claimStatusViewType === "this-quarter" ? (
        <ClaimsStatusThisQuarter
          selectedClient={selectedClient}
          statusOptions={statusOptions}
        />
      ) : null}

      {claimStatusViewType === "this-year" ? (
        <ClaimsStatusThisYear
          selectedClient={selectedClient}
          statusOptions={statusOptions}
        />
      ) : null}
    </div>
  );
};

export default ClaimsStatusCountView;
