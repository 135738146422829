import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams, Routes, Route } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import Spinner from "views/Spinner";
import ClientNav from "./ClientNav";

import ClientPatientsList from "./Patients/ClientPatientsList";
import ClientClaimsList from "./Claims/ClientClaimsList";
import BenefitsInvestigationsList from "./BenefitsInvestigation/BenefitsInvestigationsList";
import PatientNotificationsList from "./PatientNotifications/PatientNotificationsList";
import ClaimNotificationsList from "./ClaimsNotifications/ClaimNotificationsList";
import ClientServicesList from "./ClientServices/ClientServicesList";

import { getClientById } from "actions/client";

const ClientWorkspace = ({
  loadingClient,
  getClientById,
  currentClient,
  loggedInUser,
}) => {
  const { client_id } = useParams();

  React.useMemo(() => {
    getClientById(client_id);
  }, []);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle={currentClient ? currentClient.name : "Workspace"}
        crumbs={[
          { name: "Clients", path: "/admin/clients" },
          { name: currentClient ? currentClient.name : "Workspace" },
        ]}
      />

      {loadingClient ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="12">
            <ClientNav client={currentClient} loggedInUser={loggedInUser} />

            <Routes path="/admin/clients/:client_id/workspace">
              <Route
                path="/patients"
                element={<ClientPatientsList clientCode={currentClient.code} />}
              />
              <Route
                path="/encounters"
                element={<ClientClaimsList clientCode={currentClient.code} />}
              />

              <Route
                path="/benefits-investigation"
                element={
                  <BenefitsInvestigationsList clientCode={currentClient.code} />
                }
              />

              <Route
                path="/notifications/patients"
                element={
                  <PatientNotificationsList clientCode={currentClient.code} />
                }
              />
              <Route
                path="/notifications/encounters"
                element={
                  <ClaimNotificationsList clientCode={currentClient.code} />
                }
              />

              <Route
                path="/client-services"
                element={<ClientServicesList clientCode={currentClient.code} />}
              />
            </Routes>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

ClientWorkspace.propTypes = {
  errorList: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingClient: state.client.loadingClient,
  currentClient: state.client.currentClient,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  getClientById,
})(ClientWorkspace);
