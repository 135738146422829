import axios from "axios";
import { setAlert } from "./alert";
import { logout } from "./auth";

import {
  loadingDashboardClientsList,
  dashboardClientListUpdated,
  dashboardError,
  loadingDashboardClientClaimsCount,
  dashboardClientClaimsCount,
  loadingDashboardClientPatientsCount,
  dashboardClientPatientsCount,
  loadingDashboardClaimsForWeek,
  dashboardClaimsForWeek,
  loadingDashboardPatientsForWeek,
  dashboardPatientsForWeek,
  loadingDashboardClaimsByMonth,
  dashboardClaimsByMonth,
  loadingDashboardPatientsByMonth,
  dashboardPatientsByMonth,
  loadingDashboardClaimsByYear,
  dashboardClaimsByYear,
  loadingDashboardPatientsByYear,
  dashboardPatientsByYear,
  loadingClaimsStatusByWeek,
  dashboardClaimsStatusByWeek,
  loadingPatientsStatusByWeek,
  dashboardPatientsStatusByWeek,
  loadingClaimsStatusByMonth,
  dashboardClaimsStatusByMonth,
  loadingPatientsStatusByMonth,
  dashboardPatientsStatusByMonth,
  loadingClaimsStatusByQuarter,
  dashboardClaimsStatusByQuarter,
  loadingPatientsStatusByQuarter,
  dashboardPatientsStatusByQuarter,
  loadingClaimsStatusByYear,
  dashboardClaimsStatusByYear,
  loadingPatientsStatusByYear,
  dashboardPatientsStatusByYear,
  loadingDashboardClaimsByQuarter,
  dashboardClaimsByQuarter,
  loadingDashboardPatientsByQuarter,
  dashboardPatientsByQuarter,
} from "reducers/dashboardReducer";

export const getDashboardClientsList = () => async (dispatch) => {
  try {
    const config = {
      "Content-Type": "application/json",
    };

    dispatch(loadingDashboardClientsList());
    const res = await axios.get("/api/admin/dashboard/clients-list", config);

    dispatch(dashboardClientListUpdated(res.data.response[0]));
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          dashboardError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.message, "danger"));
    }
  }
};

export const getSelectedClientPostDataCount =
  (client_code, record_type) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };
      if (record_type === "claims")
        dispatch(loadingDashboardClientClaimsCount());
      else dispatch(loadingDashboardClientPatientsCount());
      const res = await axios.get(
        `/api/admin/dashboard/${client_code}/${record_type}/postdata-count`,
        config
      );

      if (record_type === "claims")
        dispatch(dashboardClientClaimsCount(res.data.response));
      else dispatch(dashboardClientPatientsCount(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            dashboardError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getSelectedClientCountForWeek =
  (client_code, record_type) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };
      if (record_type === "claims") dispatch(loadingDashboardClaimsForWeek());
      else dispatch(loadingDashboardPatientsForWeek());
      const res = await axios.get(
        `/api/admin/dashboard/${client_code}/${record_type}/count-for-week`,
        config
      );

      if (record_type === "claims")
        dispatch(dashboardClaimsForWeek(res.data.response));
      else dispatch(dashboardPatientsForWeek(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            dashboardError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getSelectedClientCountByMonth =
  (client_code, record_type) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };
      if (record_type === "claims") dispatch(loadingDashboardClaimsByMonth());
      else dispatch(loadingDashboardPatientsByMonth());
      const res = await axios.get(
        `/api/admin/dashboard/${client_code}/${record_type}/count-by-month`,
        config
      );

      if (record_type === "claims")
        dispatch(dashboardClaimsByMonth(res.data.response));
      else dispatch(dashboardPatientsByMonth(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            dashboardError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getSelectedClientCountByQuarter =
  (client_code, record_type) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };
      if (record_type === "claims") dispatch(loadingDashboardClaimsByQuarter());
      else dispatch(loadingDashboardPatientsByQuarter());
      const res = await axios.get(
        `/api/admin/dashboard/${client_code}/${record_type}/count-by-quarter`,
        config
      );

      if (record_type === "claims")
        dispatch(dashboardClaimsByQuarter(res.data.response));
      else dispatch(dashboardPatientsByQuarter(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            dashboardError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getSelectedClientCountByYear =
  (client_code, record_type) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };
      if (record_type === "claims") dispatch(loadingDashboardClaimsByYear());
      else dispatch(loadingDashboardPatientsByYear());
      const res = await axios.get(
        `/api/admin/dashboard/${client_code}/${record_type}/count-by-year`,
        config
      );

      if (record_type === "claims")
        dispatch(dashboardClaimsByYear(res.data.response));
      else dispatch(dashboardPatientsByYear(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            dashboardError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getStatusCountThisWeek =
  (client_code, record_type, status_options) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      if (record_type === "claims") dispatch(loadingClaimsStatusByWeek());
      else dispatch(loadingPatientsStatusByWeek());

      const res = await axios.put(
        `/api/admin/dashboard/${client_code}/${record_type}/count-status-by-week/`,
        {
          status_options,
        },
        config
      );

      if (record_type === "claims")
        dispatch(dashboardClaimsStatusByWeek(res.data.response));
      else dispatch(dashboardPatientsStatusByWeek(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            dashboardError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getStatusCountThisMonth =
  (client_code, record_type, status_options) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      if (record_type === "claims") dispatch(loadingClaimsStatusByMonth());
      else dispatch(loadingPatientsStatusByMonth());

      const res = await axios.put(
        `/api/admin/dashboard/${client_code}/${record_type}/count-status-by-month/`,
        {
          status_options,
        },
        config
      );

      if (record_type === "claims")
        dispatch(dashboardClaimsStatusByMonth(res.data.response));
      else dispatch(dashboardPatientsStatusByMonth(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            dashboardError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getStatusCountThisQuarter =
  (client_code, record_type, status_options) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      if (record_type === "claims") dispatch(loadingClaimsStatusByQuarter());
      else dispatch(loadingPatientsStatusByQuarter());

      const res = await axios.put(
        `/api/admin/dashboard/${client_code}/${record_type}/count-status-by-quarter/`,
        {
          status_options,
        },
        config
      );

      if (record_type === "claims")
        dispatch(dashboardClaimsStatusByQuarter(res.data.response));
      else dispatch(dashboardPatientsStatusByQuarter(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            dashboardError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };

export const getStatusCountThisYear =
  (client_code, record_type, status_options) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      if (record_type === "claims") dispatch(loadingClaimsStatusByYear());
      else dispatch(loadingPatientsStatusByYear());

      const res = await axios.put(
        `/api/admin/dashboard/${client_code}/${record_type}/count-status-by-year/`,
        {
          status_options,
        },
        config
      );

      if (record_type === "claims")
        dispatch(dashboardClaimsStatusByYear(res.data.response));
      else dispatch(dashboardPatientsStatusByYear(res.data.response));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            dashboardError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.message, "danger"));
      }
    }
  };
