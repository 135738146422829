import React from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";

import {
  removePageErrors,
  save,
  getICDCodeList,
  setErrors,
} from "actions/icd_codeActions";

import Errors from "Notifications/Errors";

import { validateForm } from "utils/validation";

const ICDCodeModal = ({
  modal,
  setModal,
  toggle,
  selectedID,
  setSelectedID,
  loadingRecord,
  setErrors,
  removePageErrors,
  save,
  getICDCodeList,
  List,
}) => {
  const initialData = {
    icd10_code: "",
    name: "",
  };
  const [formData, setFormData] = React.useState(initialData);
  const [currentRecord, setCurrentRecord] = React.useState(null);

  const { icd10_code, name } = formData;

  React.useEffect(() => {
    setFormData(initialData);

    removePageErrors();

    if (!selectedID) return;

    const selectedRow = List.data.find((row) => row._id === selectedID);
    setCurrentRecord((old) => ({ ...old, ...selectedRow }));
  }, [selectedID, modal]);

  React.useEffect(() => {
    if (!currentRecord) return;

    const { icd10_code, name } = currentRecord;

    const data = {
      icd10_code,
      name,
    };
    setFormData((form) => ({ ...form, ...data }));
  }, [currentRecord]);

  const onChange = (e) => {
    if (!e.target) return;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    removePageErrors();

    let validationRules = [
      {
        param: "icd10_code",
        msg: "The ICD code is required",
      },
      {
        param: "name",
        msg: "The Name is required",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};
    for (let i in formData) {
      if (
        formData[i] === "" ||
        formData[i] === null ||
        formData[i] === undefined
      )
        continue;
      submitData[i] = formData[i];
    }

    save(submitData, selectedID).then((res) => {
      if (res.status) {
        reset();
        getICDCodeList({});
      }
    });
  };

  const reset = () => {
    setModal(false);
    setSelectedID(null);
    setFormData(initialData);
  };

  return (
    <Modal
      show={modal}
      onHide={reset}
      aria-labelledby="example-custom-modal-styling-title"
      backdrop="static"
    >
      <Modal.Header toggle={toggle} closeButton>
        <h4>{selectedID ? "Edit" : "Create new"} ICD Code</h4>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={(e) => onSubmitHandler(e)} autoComplete="off">
          <Row>
            <Col xs="12">
              <Form.Group className="form-group">
                <Form.Label htmlFor="icd10_code">
                  ICD Code <span className="red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="icd10_code"
                  name="icd10_code"
                  value={icd10_code}
                  maxLength="100"
                  required
                  onChange={(e) => onChange(e)}
                />

                <Errors current_key="icd10_code" key="icd10_code" />
              </Form.Group>
            </Col>

            <Col xs="12">
              <Form.Group className="form-group">
                <Form.Label htmlFor="name">
                  Name <span className="red">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  required
                  onChange={(e) => onChange(e)}
                />

                <Errors current_key="name" key="name" />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="float-end">
                <Button
                  className="m-2"
                  type="submit"
                  size="sm"
                  variant="primary"
                  disabled={loadingRecord}
                >
                  {loadingRecord ? "Saving..." : "Save"}
                </Button>
                <Button
                  className="ml-2"
                  type="reset"
                  size="sm"
                  variant="danger"
                  onClick={reset}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loadingRecord: state.icd_code.loadingRecord,
  List: state.icd_code.List,
});

export default connect(mapStateToProps, {
  setErrors,
  removePageErrors,
  save,
  getICDCodeList,
})(ICDCodeModal);
