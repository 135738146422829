import { createSlice } from "@reduxjs/toolkit";
import * as Constants from "../constants/index";

const initialState = {
  appTokenList: {
    page: 1,
    data: [],
    count: 0,
  },
  currentAppToken: [],
  loadingAppTokenList: true,
  loadingAppToken: false,
  error: {},
  clientsList: [],
  sortingParams: {
    limit: Constants.DEFAULT_PAGE_SIZE,
    page: 1,
    orderBy: "createdAt",
    ascending: "desc",
    query: "",
    isAll: 1,
  },
};

const appTokenSlice = createSlice({
  name: "appTokens",
  initialState: initialState,
  reducers: {
    loadingAppTokensList(state) {
      return {
        ...state,
        loadingAppTokenList: true,
      };
    },
    appTokenSearchParametersUpdate(state, action) {
      return {
        ...state,
        sortingParams: { ...action.payload },
        loadingAppTokenList: false,
      };
    },
    appTokenListUpdated(state, action) {
      return {
        ...state,
        appTokenList: {
          data: action.payload.data,
          page: action.payload.metadata[0].current_page,
          count: action.payload.metadata[0].totalRecord,
        },
        loadingAppTokenList: false,
      };
    },
    resetAppToken(state) {
      return {
        ...initialState,
      };
    },
    appTokenStatusUpdated(state, action) {
      return {
        ...state,
        appTokenList: {
          ...state.appTokenList,
          data: state.appTokenList.data.map((appToken) =>
            appToken._id === action.payload.app_token_id
              ? { ...appToken, status: action.payload.status }
              : appToken
          ),
        },
      };
    },
    appTokenError(state, action) {
      return {
        ...state,
        error: action.payload,
        loadingAppToken: false,
        loadingAppTokenList: false,
      };
    },
    loadingOnAppTokenSubmit(state) {
      return {
        ...state,
        loadingAppToken: true,
      };
    },
    appTokenCreated(state) {
      return {
        ...state,
        loadingAppToken: false,
      };
    },
    appTokenUpdated(state, action) {
      return {
        ...state,
        appTokenList: {
          ...state.appTokenList,
          data: state.appTokenList.data.map((appToken) =>
            appToken._id === action.payload._id ? action.payload : appToken
          ),
        },
      };
    },
    clientsListUpdated(state, action) {
      return {
        ...state,
        clientsList: action.payload,
      };
    },
  },
});

export const {
  loadingAppTokensList,
  appTokenSearchParametersUpdate,
  appTokenListUpdated,
  resetAppToken,
  appTokenStatusUpdated,
  appTokenError,
  loadingOnAppTokenSubmit,
  appTokenCreated,
  appTokenUpdated,
  clientsListUpdated,
} = appTokenSlice.actions;
export default appTokenSlice.reducer;
