import axios from "axios";
import { setAlert } from "./alert";
import { setErrorsList } from "./errors";
import { logout } from "./auth";
import { removeErrors } from "reducers/errors";

import {
  loadingList,
  searchParametersUpdate,
  listUpdated,
  loadingOnSubmit,
  recordSaved,
  clientServiceError,
  recordDeleted,
  resetStore,
} from "reducers/clientServiceReducer";

export const getClientServicesList =
  (params, client_code) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      const query = params.query ? params.query : "";
      params.query = query;
      config.params = params;

      dispatch(loadingList());
      const res = await axios.get(
        `/api/admin/client-workspace/${client_code}/client-services`,
        config
      );

      dispatch(searchParametersUpdate(params));

      dispatch(listUpdated(res.data.response[0]));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            clientServiceError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.data.message, "danger"));
      }
    }
  };

export const save =
  (formData, client_code, client_service_id) => async (dispatch) => {
    try {
      const config = {
        "Content-Type": "application/json",
      };

      dispatch(loadingOnSubmit());

      let res = null;
      if (client_service_id) {
        res = await axios.put(
          `/api/admin/client-workspace/${client_code}/client-services/${client_service_id}`,
          formData,
          config
        );
      } else {
        res = await axios.post(
          `/api/admin/client-workspace/${client_code}/client-services`,
          formData,
          config
        );
      }

      if (res.data.status === true) {
        dispatch(recordSaved(res.data.response));

        dispatch(setAlert("Client service saved.", "success"));
      } else {
        const errors = res.data.errors;
        if (errors) {
          dispatch(clientServiceError());
          dispatch(setAlert(res.data.message, "danger"));

          errors.forEach((error) => {
            dispatch(setErrorsList(error.msg, error.param));
          });
        }
      }
      return res.data ? res.data : { status: false };
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            clientServiceError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.data.message, "danger"));
      }
    }
  };

export const deleteRecord =
  (client_code, client_service_id) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      await axios.delete(
        `/api/admin/client-workspace/${client_code}/client-services/${client_service_id}`,
        config
      );

      dispatch(recordDeleted(client_service_id));
      dispatch(setAlert("Client service deleted", "success"));
    } catch (err) {
      console.error(err);
      if (err.response.data && err.response.data.tokenStatus === 0) {
        dispatch(logout());
      } else {
        err.response &&
          dispatch(
            clientServiceError({
              msg: err.response.statusText,
              status: err.response.status,
            })
          );

        dispatch(setAlert(err.response.data.message, "danger"));
      }
    }
  };

export const isRecordExists = (client_code, formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axios.post(
      `/api/admin/client-workspace/${client_code}/client-services/is-exists`,
      formData,
      config
    );

    return res ? res.data : { status: false };
  } catch (err) {
    console.error(err);
    if (err.response.data && err.response.data.tokenStatus === 0) {
      dispatch(logout());
    } else {
      err.response &&
        dispatch(
          clientServiceError({
            msg: err.response.statusText,
            status: err.response.status,
          })
        );

      dispatch(setAlert(err.response.data.message, "danger"));
    }
  }
};

export const cancelSave = (navigate) => async (dispatch) => {
  dispatch(removeErrors());
  navigate("/admin/client-services");
};

// page not found
export const notFound = (navigate) => async (dispatch) => {
  navigate("/admin/page-not-found");
};

// reset errors
export const removePageErrors = () => async (dispatch) => {
  dispatch(removeErrors());
};

// Dispatch Reset store
export const resetComponentStore = () => async (dispatch) => {
  await dispatch(resetStore());
};

export const setErrors = (errors) => async (dispatch) => {
  if (errors) {
    dispatch(clientServiceError());
    dispatch(setAlert("Please correct the following errors", "danger"));
    errors.forEach((error) => {
      dispatch(setErrorsList(error.msg, error.param));
    });
  }
};

export const setAlertError = (msg) => async (dispatch) => {
  dispatch(setAlert(msg, "danger"));
};
