import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Row, Card } from "react-bootstrap";

import { MdEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";

import AppBreadcrumb from "views/Admin/Layout/AppBreadCrumb";
import Errors from "Notifications/Errors";
import Spinner from "views/Spinner";

import { validateForm } from "utils/validation";
import { isAdmin } from "utils/helper";

import {
  edit,
  getClientById,
  cancelSave,
  loadPage,
  setErrors,
  removeClientErrors,
  resetComponentStore,
} from "actions/client";

const EditClient = ({
  edit,
  errorList,
  currentClient,
  getClientById,
  cancelSave,
  loadingClient,
  loadPage,
  setErrors,
  removeClientErrors,
  resetComponentStore,
  loggedInUser,
}) => {
  const navigate = useNavigate();
  const { client_id } = useParams();

  //########################## set the initial form data ##############################
  const initialFormData = {
    name: "",
    code: "",
    notifyEmails: "",
    comments: "",
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [isDisabled, setDisabled] = React.useState(true);
  const toggleEdit = () => setDisabled(!isDisabled);

  React.useEffect(() => {
    if (currentClient) {
      const { name, code, notifyEmails = "", comments = "" } = currentClient;

      const data = {
        name,
        code,
        notifyEmails,
        comments,
      };

      setFormData((form) => ({ ...form, ...data }));
    }
  }, [currentClient]);

  const { name, code, notifyEmails, comments } = formData;

  const onChange = (e) => {
    if (!e.target) {
      return;
    }

    switch (e.target.name) {
      case "code":
        let value = e.target.value;
        setFormData({ ...formData, [e.target.name]: value.toUpperCase() });
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  //########################## submit form data ##############################
  const onSubmit = (e) => {
    e.preventDefault();

    removeClientErrors();

    let validationRules = [
      {
        param: "name",
        msg: "Please provide a name",
      },
    ];

    const errors = validateForm(formData, validationRules);

    if (errors.length) {
      setErrors(errors);
      return;
    }

    const submitData = {};
    for (let i in formData) {
      submitData[i] = formData[i];
    }

    // console.log("Edit Client Submit data", submitData);
    edit(submitData, navigate, client_id).then((res) => {});
  };

  const onClickHandel = (e) => {
    e.preventDefault();
    cancelSave(navigate);
  };

  React.useEffect(() => {
    resetComponentStore();
    loadPage();

    if (!loggedInUser) return;

    //Prevent API call when user is not a admin
    if (!isAdmin(loggedInUser)) {
      navigate("/admin/dashboard");
      return;
    }

    getClientById(client_id);
  }, [loggedInUser]);

  return (
    <React.Fragment>
      <AppBreadcrumb
        pageTitle="Edit Client"
        crumbs={[
          { name: "Clients", path: "/admin/clients" },
          { name: "Edit Client" },
        ]}
      />

      {loadingClient ? (
        <Spinner />
      ) : (
        <Row>
          <Col xs="12" sm="6">
            <Card className="card-body">
              <Form onSubmit={(e) => onSubmit(e)} autoComplete="off">
                <div className="card-heading">
                  <h4 className="header-title mb-3">Client Information</h4>
                  <span onClick={toggleEdit} title="View/Edit">
                    {isDisabled ? <MdEdit /> : <FaRegEye />}
                  </span>
                </div>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="code">
                    Code <span>*</span>
                  </Form.Label>
                  <br></br>
                  <Form.Control
                    className={errorList.code ? "invalid" : ""}
                    type="text"
                    id="code"
                    name="code"
                    value={code}
                    disabled
                  />

                  <Errors current_key="code" key="code" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="name">
                    Name <span>*</span>
                  </Form.Label>
                  <br></br>
                  <Form.Control
                    className={errorList.name ? "invalid" : ""}
                    type="text"
                    id="name"
                    name="name"
                    maxLength="100"
                    value={name}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="name" key="name" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="notifyEmails">
                    Notification Emails
                  </Form.Label>

                  <Form.Control
                    className={errorList.notifyEmails ? "invalid" : ""}
                    as="textarea"
                    rows={3}
                    id="notifyEmails"
                    name="notifyEmails"
                    value={notifyEmails}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <small className="float-end">
                    Note: Put multiple emails with Comma (,) separated.
                  </small>

                  <Errors current_key="notifyEmails" key="notifyEmails" />
                </Form.Group>

                <Form.Group className="form-group">
                  <Form.Label htmlFor="comments">Comments</Form.Label>
                  <br></br>
                  <Form.Control
                    className={errorList.comments ? "invalid" : ""}
                    as="textarea"
                    rows={5}
                    id="comments"
                    name="comments"
                    value={comments}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                  />
                  <Errors current_key="comments" key="comments" />
                </Form.Group>

                <div className="float-end">
                  <Button
                    className="m-2"
                    type="submit"
                    size="sm"
                    variant="primary"
                  >
                    Submit
                  </Button>
                  <Button
                    className="ml-2"
                    type="reset"
                    size="sm"
                    variant="danger"
                    onClick={onClickHandel}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

EditClient.propTypes = {
  edit: PropTypes.func.isRequired,
  loadPage: PropTypes.func.isRequired,
  errorList: PropTypes.object.isRequired,
  cancelSave: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loadingClient: state.client.loadingClient,
  currentClient: state.client.currentClient,
  loggedInUser: state.auth.user,
});

export default connect(mapStateToProps, {
  edit,
  getClientById,
  cancelSave,
  loadPage,
  setErrors,
  removeClientErrors,
  resetComponentStore,
})(EditClient);
