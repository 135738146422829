import React from "react";
import { Row, Col } from "react-bootstrap";

import PatientsWeeklyView from "./PatientsWeeklyView";
import PatientsWithFilterView from "./PatientsWithFilterView";

const PatientsOverview = ({ selectedClient }) => {
  return (
    <Row>
      <Col md="6">
        <PatientsWeeklyView selectedClient={selectedClient} />
      </Col>
      <Col md="6">
        <PatientsWithFilterView selectedClient={selectedClient} />
      </Col>
    </Row>
  );
};

export default PatientsOverview;
